import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Container,
  Checkbox,
  Chip,
  Tooltip,
  useMediaQuery,
  useTheme,
  TextField,
  InputAdornment,
  ClickAwayListener,
  Menu,
  MenuItem,
  Stack
} from "@mui/material";

import {
  Download as DownloadIcon,
  Upload as UploadIcon,
  ArrowBack as ArrowBackIcon,
  Search as SearchIcon,
  MoreVert as MoreVertIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
  Pending as PendingIcon,
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

import { motion } from "framer-motion";
import { UploadFrame } from "../../components/UploadFrame/UploadFrame";
import { DownloadFrame } from "../../components/DownloadFrame/DownloadFrame";
import { Navbar } from "../../components/Navbar/Navbar";
import { useNavigate } from 'react-router-dom';
import { DocumentContent } from "../../components/DocumentContent/DocumentContent";
import SideBar from "../../components/SideBar/SideBar";

// Helper functions for status-related operations
const getStatusIcon = (status) => {
  switch (status) {
    case "PENDING_REVIEW":
      return <PendingIcon />;
    case "IN_REVIEW":
      return <EditIcon />;
    case "APPROVED":
      return <CheckCircleIcon />;
    default:
      return <PendingIcon />;
  }
};

const getStatusText = (status) => {
  switch (status) {
    case "PENDING_REVIEW":
      return "Pending Review";
    case "IN_REVIEW":
      return "In Review";
    case "APPROVED":
      return "Approved";
    default:
      return "Unknown";
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case "PENDING_REVIEW":
      return "warning";
    case "IN_REVIEW":
      return "info";
    case "APPROVED":
      return "success";
    default:
      return "default";
  }
};


// Memoized FileRow component
const FileRow = React.memo(({ 
  invoiceId, 
  invoicePath, 
  avatarSrc, 
  fileName, 
  status, 
  date, 
  uploadedBy,
  isChecked,
  onCheckboxClick,
  onRowClick,
  url,
  onDocumentLoadSuccess
}) => {
  const filePath = `${url}${invoicePath}`;
  const statusIcon = getStatusIcon(status);
  const statusText = getStatusText(status);
  const statusColor = getStatusColor(status);
  const invoice = {id: invoiceId, 
                    file: invoicePath, 
                    status: status}
  return (
    <TableRow
      component={motion.tr}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon />}
          checkedIcon={<CheckBoxIcon />}
          checked={isChecked}
          onChange={() => onCheckboxClick(invoiceId)}
          sx={{
            color: isChecked ? '#1c4ed8' : 'default',
            '&.Mui-checked': {
              color: '#1c4ed8',
            },
          }}
        />
      </TableCell>
      <TableCell onClick={() => onRowClick([invoice])}>
        <Box display="flex" alignItems="center" sx={{cursor: 'pointer'}}>
          <DocumentContent 
            path={filePath}
            pageNumber={1}
            scale={0.08}
            onDocumentLoadSuccess={onDocumentLoadSuccess}
          />
          <Typography 
            color="primary" 
            ml={2} 
            sx={{ 
              width: '120px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'wrap',
              cursor: 'pointer',
              color: '#1c4ed8',
              fontSize: 'small'
            }}
          >
            {fileName}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Chip
          icon={statusIcon}
          label={statusText}
          color={statusColor}
          onClick={() => onRowClick([invoice])}
          sx={{ cursor: 'pointer' }}
        />
      </TableCell>
      <TableCell>{date}</TableCell>
      <TableCell>{uploadedBy}</TableCell>
    </TableRow>
  );
}, (prevProps, nextProps) => {
  return (
    prevProps.isChecked === nextProps.isChecked &&
    prevProps.status === nextProps.status &&
    prevProps.fileName === nextProps.fileName
  );
});

// Memoized TabButton component
const TabButton = React.memo(({ tab, activeTab, onClick }) => (
  <Button
    variant={activeTab === tab ? "contained" : "text"}
    onClick={() => onClick(tab)}
    sx={{
      m: 0.5,
      borderRadius: '50px',
      backgroundColor: activeTab === tab ? '#1c4ed8' : 'transparent',
      color: activeTab === tab ? 'white' : '#1c4ed8',
      '&:hover': {
        backgroundColor: '#1c4ed8',
        color: 'white',
      },
    }}
  >
    {tab}
  </Button>
));

export const Dashboard = () => {
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // State management
  const [invoices, setInvoices] = useState([]);
  const [, setNumPages] = useState(null);
  const [showUploadFrame, setShowUploadFrame] = useState(false);
  const [showDownloadFrame, setShowDownloadFrame] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [activeTab, setActiveTab] = useState("All Files");
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [checkedInvoiceIds, setCheckedInvoiceIds] = useState([]);

  const searchRef = useRef(null);
  const open = Boolean(anchorEl);

  // Memoized URL
  const url = useMemo(() => {
    return internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';
  }, [internalUrl]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  // Memoized filtered invoices
  const filteredInvoices = useMemo(() => {
    if (!searchQuery) return invoices;
    return invoices.filter(invoice => 
      invoice.file.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [invoices, searchQuery]);

  // Debounce utility function
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  // Memoized handlers
  const handleUploadClick = useCallback(() => setShowUploadFrame(true), []);
  const handleDownloadClick = useCallback(() => setShowDownloadFrame(true), []);
  const handleGoBack = useCallback(() => navigate('/'), [navigate]);

  const fetchInvoices = useCallback(async () => {
    try {
      const response = await fetch(`${url}/api/invoices/`);
      const data = await response.json();
      if (data.message === "Success") {
        setInvoices(data.data);
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  }, [url]);
  

  const handleCloseDownloadFrame = useCallback(() => {
    setShowDownloadFrame(false);
  }, []);

  const handleCheckboxClick = useCallback((invoiceId) => {
    setCheckedInvoiceIds(prevChecked => {
      if (prevChecked.includes(invoiceId)) {
        return prevChecked.filter(id => id !== invoiceId);
      } else {
        return [...prevChecked, invoiceId];
      }
    });
  }, []);

  const handleToggleAll = useCallback(() => {
    const newCheckedState = !allChecked;
    if (newCheckedState) {
      // Check all visible invoices
      const visibleInvoiceIds = filteredInvoices.map(invoice => invoice.id);
      setCheckedInvoiceIds(visibleInvoiceIds);
    } else {
      // Uncheck all
      setCheckedInvoiceIds([]);
    }
  }, [allChecked, filteredInvoices]);


  const updateStatus = useCallback(async (invoiceId) => {
    try {
      const response = await fetch(`${url}/api/invoices/${invoiceId}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ status: "IN_REVIEW" })
      });
      
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    } catch (error) {
      console.error('Update error:', error);
      throw error;
    }
  }, [url]);

  const handleGoInvoice = useCallback(async(invoices) => {
    const invoiceIds = invoices.map((invoice) => invoice.id).join(',');
    const filePaths = invoices.map((invoice) => `${url}${invoice.file}`).join(',');
    const statuses = invoices.map((invoice) => invoice.status);
    const invoiceId = invoiceIds.split(",")[0]
    const status = statuses[0]

    // If any invoice has "PENDING_REVIEW" status, update it
    if (status === "PENDING_REVIEW") {
        updateStatus(invoiceId)
        .then(() => {
        navigate(`/invoice-demo/${invoiceId}?invoiceIds=${encodeURIComponent(invoiceIds)}&filepaths=${encodeURIComponent(filePaths)}`);
      }).catch((error) => {
        console.error('Navigation error:', error);
      });
    } else {
      // Navigate directly if all statuses are "IN_REVIEW"
      navigate(`/invoice-demo/${invoiceId}?invoiceIds=${encodeURIComponent(invoiceIds)}&filepaths=${encodeURIComponent(filePaths)}`);
    }
  }, [navigate, updateStatus, url]);


  const handleCloseUploadFrame = useCallback(async (invoices) => {
    fetchInvoices();
    if (invoices.length === 0) {
      setShowUploadFrame(false);
      return;
    }
    else {
      if (invoices.length === 1) {
        // If only one invoice, navigate directly to it
        const invoice = invoices[0];
        await handleGoInvoice([invoice]); // Await to ensure completion before moving forward
      } else {
        // If multiple invoices, pass all to the function
        await handleGoInvoice(invoices); // Await for multiple invoices as well
      }
    }
    // Only hide the upload frame after the invoice navigation is completed
    setShowUploadFrame(false);

}, [fetchInvoices, handleGoInvoice]);

  const handleSetActiveTab = useCallback((newTab) => {
    setActiveTab(newTab);
    
    let status = null;
    if (newTab === 'In Review') {
      status = 'IN_REVIEW';
    } else if (newTab === 'Approved') {
      status = 'APPROVED';
    }
    
    const apiUrl = `${url}/api/invoices/${status ? `?status=${status}` : ''}`;
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        if (data.message === "Success") {
          setInvoices(data.data);
        }
      })
      .catch(error => {
        console.error("Error fetching invoices:", error);
      });
  }, [url]);

  const handleDelete = useCallback(async () => {
    try {
      await Promise.all(
        checkedInvoiceIds.map(invoiceId =>
          fetch(`${url}/api/invoices/${invoiceId}/`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json'
            },
          })
        )
      );
      await fetchInvoices();
      setCheckedInvoiceIds([]);
      setAnchorEl(null);
    } catch (error) {
      console.error('Delete error:', error);
    }
  }, [checkedInvoiceIds, url, fetchInvoices]);

  const handleSearchChange = useMemo(() => {
    return debounce((value) => {
      const apiUrl = `${url}/api/invoices/${value ? `?search=${value}` : ''}`;
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          if (data.message === "Success") {
            setInvoices(data.data);
          }
        })
        .catch(error => {
          console.error("Error fetching invoices:", error);
        });
    }, 300);
  }, [url]);

  // Effects
  useEffect(() => {
    if (filteredInvoices.length === 0) {
      setAllChecked(false);
    } else {
      setAllChecked(checkedInvoiceIds.length === filteredInvoices.length);
    }
  }, [checkedInvoiceIds, filteredInvoices]);

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  useEffect(() => {
    if (showSearch && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showSearch]);

  const [openSideBar, setOpenSideBar] = useState(true);
  const handleDrawerToggle = () => {
    setOpenSideBar(!openSideBar);
  };

  return (
    <Box width="100%" bgcolor="white" >
      <SideBar open={openSideBar} handleDrawerToggle={handleDrawerToggle} demo={true} />
      <Navbar sideBarOpen={openSideBar} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            ml: 50,
            mx: 3,
            pb: 5,
            mt: { xs: 8, md: 0 },
          }}
        >
          <Box sx={{ width: openSideBar ? '100%' : '70%', maxWidth: { sm: '100%', md: '1700px'} }}>
            <Container sx={{ pt: 1, mt: 10}}>
              <Box mt={4}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <Tooltip title="Go back">
                    <IconButton 
                      aria-label="Go back" 
                      onClick={handleGoBack} 
                      sx={{ borderColor: '#1c4ed8', color: '#1c4ed8'}}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Tooltip>
                  
                  <Box 
                    display="flex" 
                    justifyContent="center" 
                    width={isMobile ? "100%" : "686px"} 
                    flexWrap="wrap"
                  >
                    {["All Files", "In Review", "Approved"].map((tab) => (
                      <TabButton
                        key={tab}
                        tab={tab}
                        activeTab={activeTab}
                        onClick={handleSetActiveTab}
                      />
                    ))}
                  </Box>

                  <Box display="flex" alignItems="center">
                    <ClickAwayListener onClickAway={() => {
                      if (searchQuery.trim() === "") {
                        setShowSearch(false);
                      }
                    }}>
                      <Box>
                        {showSearch ? (
                          <TextField
                            size="small"
                            value={searchQuery}
                            onChange={(e) => {
                              setSearchQuery(e.target.value);
                              handleSearchChange(e.target.value);
                            }}
                            placeholder="Search..."
                            inputRef={searchRef}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            sx={{ mr: 1 }}
                          />
                        ) : (
                          <Tooltip title="Search">
                            <IconButton 
                              aria-label="Search" 
                              onClick={() => setShowSearch(true)}
                            >
                              <SearchIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </ClickAwayListener>

                    <Tooltip title="More options">
                      <IconButton
                        aria-label="More options"
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Tooltip>

                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => setAnchorEl(null)}
                    >
                      <MenuItem onClick={() => {
                        setAnchorEl(null);
                      }}>
                        <EditIcon sx={{ mr: 1 }} />
                        Edit
                      </MenuItem>
                      <MenuItem 
                        onClick={handleDelete} 
                        disabled={checkedInvoiceIds.length === 0}
                      >
                        <DeleteIcon sx={{ mr: 1 }} />
                        Delete
                      </MenuItem>
                    </Menu>
                  </Box>
                </Box>

                {showUploadFrame && <UploadFrame onClose={handleCloseUploadFrame} />}
                {showDownloadFrame && <DownloadFrame 
                                        onClose={handleCloseDownloadFrame} 
                                        checkedInvoiceIds={checkedInvoiceIds}
                                        url={url} />}

                <Box display="flex" flexDirection={isMobile ? "column" : "row"} mb={4}>
                  <TableContainer component={Paper} sx={{ 
                      mr: isMobile ? 0 : 0, 
                      mb: isMobile ? 0 : 0,
                      overflow: 'auto',
                      height: '65vh'}}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox">
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                              checked={allChecked}
                              onChange={handleToggleAll}
                              indeterminate={checkedInvoiceIds.length > 0 && checkedInvoiceIds.length < filteredInvoices.length}
                              sx={{
                                color: allChecked ? '#1c4ed8' : 'default',
                                '&.Mui-checked': {
                                  color: '#1c4ed8',
                                },
                                '&.MuiCheckbox-indeterminate': {
                                  color: '#1c4ed8',
                                },
                              }}
                            />
                          </TableCell>
                          <TableCell>File name</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Update Date</TableCell>
                          <TableCell>Updated By</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredInvoices.map((invoice) => (
                          <FileRow
                            key={invoice.id}
                            invoiceId={invoice.id}
                            invoicePath={invoice.file}
                            avatarSrc="https://c.animaapp.com/gerLUJMF/img/thumbnail@2x.png"
                            fileName={invoice.file.split('/').pop().split('.')[0]} // Extract file name from the file path
                            status={invoice.status}
                            date={new Date(invoice.updated_at).toLocaleDateString()} // Format date
                            uploadedBy={invoice.uploaded_by} // Replace with real user info if available
                            isChecked={checkedInvoiceIds.includes(invoice.id) || allChecked}
                            onCheckboxClick={handleCheckboxClick}
                            onRowClick={(invoice) => handleGoInvoice(invoice)}
                            url={url}
                            onDocumentLoadSuccess={onDocumentLoadSuccess}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Box mt={4} display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    className="button"
                    startIcon={<UploadIcon />}
                    onClick={handleUploadClick}
                    sx={{ mr: 2, borderRadius: '50px' }}
                  >
                    Upload file
                  </Button>
                  <Button
                    variant="contained"
                    // className="button"
                    color="primary"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadClick}
                    sx={{ mr: 2, borderRadius: '50px', backgroundColor: '#1c4ed8'}}
                    disabled={checkedInvoiceIds.length === 0}
                  >
                    Download files
                  </Button>
                </Box>
              </Box>
            </Container>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
