import React from 'react';
import { Box, Container, Typography} from '@mui/material';
import { Navbar } from '../../components/Navbar/Navbar';
import { useTranslation } from 'react-i18next';
export const Demo = () => {
    const { t } = useTranslation();
    return (
        <Box 
            width="100%" 
            sx={{
                backgroundImage: "url('https://flowbite.s3.amazonaws.com/blocks/e-commerce/girl-shopping-list.svg')", 
                backgroundSize: 'small', 
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat',
                bgcolor: "rgba(255, 255, 255, 0.85)", // Slight white overlay for readability
                backdropFilter: "blur(25px)", // Optional blur effect
                zIndex: -1,
            }}
        >  
            <Navbar sideBarOpen={true}/>
            <Container sx={{ pt: 3, textAlign: 'center'}} bgcolor="white">
                <Typography variant="h4" gutterBottom sx={{mt: 10 }}>
                    {t('Book a demo with our team!')}
                </Typography>
                <iframe
                src="https://cal.com/fuad-salimzade-dmyjgr/30min"
                width="100%"
                height="680"
                frameborder="0"
                scrolling="no"
                title="Booking a 30-minute demo meeting"
                ></iframe>
            </Container>
        </Box>
    );
};