import React from 'react';
import { Grid } from '@mui/material';
import HighlightedCard from './HighlightedCard';
import StatCard from './StatCard';

const InvoiceMetricsCards = ({ data, currency }) => {
  const totalInvoices = data.total_invoices;
  const totalAmount = parseFloat(data.total_amount);
  const totalTax = parseFloat(data.total_tax);

  // Create day-to-value mappings
  const countByDay = data.daily_trends.reduce((acc, day) => {
    acc[day.day] = day.count;
    return acc;
  }, {});

  const amountByDay = data.daily_trends.reduce((acc, day) => {
    acc[day.day] = day.amount;
    return acc;
  }, {});

  // Get sorted array of days
  const sortedDays = data.daily_trends
    .map(day => day.day)
    .sort((a, b) => new Date(a) - new Date(b));

  // Create trend data arrays in chronological order
  const countTrendData = sortedDays.map(day => countByDay[day]);
  const amountTrendData = sortedDays.map(day => amountByDay[day]);
  
  return (
    <Grid container spacing={2} justifyContent="center">
      {/* Total Invoices */}
      <Grid item xs={12} sm={6} md={3}>
        <StatCard
          title="Total Invoices"
          value={totalInvoices.toLocaleString()}
          interval="This Month"
          trend="neutral"
          data={countTrendData}
          dates={sortedDays}
        />
      </Grid>

      {/* Total Amount */}
      <Grid item xs={12} sm={6} md={3}>
        <StatCard
          title="Total Amount"
          value={currency === 'zł' ? `${totalAmount.toLocaleString()} zł` : `${currency}${totalAmount.toLocaleString()}`}
          interval="This Month"
          trend="up"
          data={amountTrendData}
          dates={sortedDays}
        />
      </Grid>
    
      {/* Total Tax */}
      <Grid item xs={12} sm={6} md={3}>
        <StatCard
          title="Total Tax"
          value={currency === 'zł' ? `${totalTax.toLocaleString()} zł` : `${currency}${totalTax.toLocaleString()}`}
          interval="This Month"
          trend="neutral"
          data={amountTrendData}
          dates={sortedDays}
        />
      </Grid>

      {/* Highlighted Card */}
      <Grid item xs={12} sm={6} md={3}>
        <HighlightedCard />
      </Grid>
    </Grid>
  );
};

export default InvoiceMetricsCards;



