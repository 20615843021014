import React from 'react';
import { Box, Typography, Paper} from '@mui/material';

export const FeatureCard = ({ icon: Icon, title, description }) => {
  return (
    <>
    <Paper
      elevation={4}
      sx={{
        p: 4,
        height: '70%',
        width: '120%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        borderRadius: 3,
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
        },
      }}
    >
      <Box
        sx={{
          width: 64,
          height: 64,
          backgroundColor: '#e0f2ff',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Icon size={32} color="#1c4ed8" />
      </Box>
      <Typography variant="h6" sx={{ mb: 1.5, fontWeight: 600 }}>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.6 }}>
        {description}
      </Typography>
    </Paper>
  </>
  );
};
