import React from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuContent from './MenuContent';
import OptionsMenu from './OptionsMenu';

const drawerWidth = 60;

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: open ? drawerWidth : drawerWidth * 4,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.standard,
  }),
  overflowX: 'hidden',
  '& .MuiDrawer-paper': {
    width: open ? drawerWidth : drawerWidth * 4,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    overflowX: 'hidden',
  },
}));

export default function SideBar({open, handleDrawerToggle, demo}) {

  const username = localStorage.getItem('username') || 'DemoUser';
  const email = localStorage.getItem('email') || 'DemoUser@email.com';
  return (
    <Drawer variant="permanent" open={open}>
      <MenuContent demo={demo}/>
      <Divider />
      <Stack direction="row" sx={{ p: 2, gap: 0, alignItems: 'center' }}>
        <Avatar
          sizes="small"
          alt="Riley Carter"
          src="/static/images/avatar/7.jpg"
          sx={{ ml: -1, mr: 1, width: 36, height: 36, color: 'white', backgroundColor: '#1c4ed8' }}
        />
        {open ? (
          <> {/* Render nothing if open is true */}
          </>
        ) : (
          <Box sx={{ mr: 'auto' }}>
            <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }}>
              {username}
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {email}
            </Typography>
          </Box>
        )}
        <OptionsMenu />
      </Stack>
      <Divider />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: 1 }}>
        <IconButton onClick={handleDrawerToggle} sx={{color:'#1c4ed8'}}>
          {open ? <ChevronRightIcon /> : <ChevronLeftIcon /> }
        </IconButton>
      </Box>
    </Drawer>
  );
}
