import React, { useState } from "react";
import { Box, Button, Container, Typography, IconButton, Drawer, MenuItem, Divider } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import AppBar from '@mui/material/AppBar';
import OpenInNewIcon from '@mui/icons-material/OpenInNewRounded';
import MenuIcon from "@mui/icons-material/Menu";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { keyframes } from '@emotion/react';

const StyledToolbar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  padding: '8px 15px',
  borderRadius: `calc(${theme.shape.borderRadius}px + 28px)`,
}));

export const Navbar = ({ sideBarOpen }): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const handleGoDashboard = () => navigate('/dashboard');
  const handleGoHome = () => navigate('/');
  const handleGoContact = () => navigate('/contact');
  const handleGoDemo = () => navigate('/demo');
  const handlePricing = () => navigate('/pricing');
  
  const isHomePage = location.pathname === '/' || location.pathname === '/dashboard-demo' || location.pathname === '/analytics-demo' || location.pathname.startsWith('/invoice-demo/') || location.pathname === '/dashboard-demo';

  const borderAnimation = keyframes`
    0% { 
      background-size: 800% 800%;
      background-image: linear-gradient(to right, #00ffff, #6b00ff, #ff6b6b);
    }
    100% {
      background-size: 800% 800%;
      background-image: linear-gradient(to right, #00ffff, #6b00ff, #ff6b6b);
      background-position: 100% 0;
    }
  `;


  return (
    <AppBar sx={{ boxShadow: 0, mt: '10px', bgcolor: isHomePage ? 'transparent' : 'transparent', backgroundImage: 'none', zIndex: 2 }}>
      <Container sx={{width: sideBarOpen ? '100%' : "70%" }} >
        <StyledToolbar>
          <Box display="flex" alignItems="center" onClick={handleGoHome} sx={{ cursor: 'pointer' }}>
            <img
              src="https://c.animaapp.com/kAl1n2qv/img/yourdraftlogo-v1-2@2x.png"
              alt="Company Logo"
              style={{ width: "52.1px", height: "51px" }}
            />
            <Typography variant="h4" sx={{ ml: 2, color: "#004080" }}>
              YourDraft
            </Typography>
          </Box>

          {/* Desktop Menu */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 1, alignItems: 'center' }}>
            {isHomePage && (
              <Box
                sx={{
                  flexGrow: 1,              // Takes up remaining space
                  display: 'flex',          // Flex container
                  justifyContent: 'center', // Centers horizontally
                }}
              >
                <Button
                  fullWidth variant="outlined" startIcon={<StarIcon />} endIcon={<OpenInNewIcon />}
                  onClick={handleGoDemo} color='white'
                  sx={{
                    mr: 40,
                    position: 'relative',
                    color:'white',
                    overflow: 'hidden',
                    background: 'linear-gradient(to right, #00ffff, #6b00ff, #ff6b6b)', 
                    backgroundSize: '800% 800%',
                    animation: `${borderAnimation} 8s ease infinite`,
                    '& .button-content': {
                      position: 'relative', zIndex: 1,
                    },
                    '&:hover': {
                        backgroundColor: 'white',
                        color: '#1c4ed8',
                        borderColor: '#1c4ed8',
                      },
                      borderRadius: '50px',
                  }}
                >
                  <span className="button-content">Book A Demo!</span>
                </Button>
              </Box>
            )}
            {!isHomePage && (
              <Button
                fullWidth variant="outlined" startIcon={<StarIcon />} endIcon={<OpenInNewIcon />}
                onClick={handleGoDemo} color='white'
                sx={{
                  
                  position: 'relative',
                  color:'white',
                  overflow: 'hidden',
                  background: 'linear-gradient(to right, #00ffff, #6b00ff, #ff6b6b)', 
                  backgroundSize: '800% 800%',
                  animation: `${borderAnimation} 8s ease infinite`,
                  '& .button-content': {
                    position: 'relative', zIndex: 1,
                  },
                  '&:hover': {
                      backgroundColor: 'white',
                      color: '#1c4ed8',
                      borderColor: '#1c4ed8',
                    },
                    borderRadius: '50px',
                }}
              >
                <span className="button-content">Book A Demo!</span>
              </Button>
            )}
            {isHomePage && (
              <Button
                variant="outlined"
                onClick={handlePricing}
                sx={buttonStyles}
              >
                Start Now!
              </Button>
            )}
          </Box>

          {/* Mobile Menu */}
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton onClick={toggleDrawer(true)}><MenuIcon /></IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton onClick={toggleDrawer(false)}><CloseRoundedIcon /></IconButton>
                </Box>
                <MenuItem onClick={handleGoDashboard} sx={buttonStyles}>My Dashboard</MenuItem>
                <MenuItem onClick={handleGoContact} sx={buttonStyles}>Contact Us</MenuItem>
                {isHomePage && (
                  <MenuItem onClick={handleGoDemo}sx={demoButtonStyles}>Book A Demo!</MenuItem>
                )}
                <Divider sx={{ my: 3 }} />
              </Box>
            </Drawer>
          </Box>
        </StyledToolbar>
      </Container>
    </AppBar>
  );
};

// Button styles
const buttonStyles = {
  borderColor: '#1c4ed8', 
  color: '#1c4ed8',
  '&:hover': {
    backgroundColor: '#1c4ed8',
    color: 'white',
  },
  borderRadius: '50px',
  animation: 'pulse 1.5s infinite',
  '@keyframes pulse': {
    '0%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(1.05)' },
    '100%': { transform: 'scale(1)' },
  },
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
  transform: 'scale(1.05)',

};

const demoButtonStyles = {
  color: 'white',
  backgroundColor: '#1c4ed8',
  '&:hover': {
    backgroundColor: 'white',
    color: '#1c4ed8',
    borderColor: '#1c4ed8',
  },
  borderRadius: '50px',
};


