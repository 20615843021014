import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Button, TextField, InputAdornment, Box } from '@mui/material';
import "./style.css";

export const SignupForm = (): JSX.Element => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Handle form submission here, e.g., send email to server
  };

  return (
    <form className="sign-up-form" onSubmit={handleSubmit}>
      <Box display="flex"  gap={2}> {/* Flex container for side-by-side layout */}
        <TextField
          type="email"
          id="email"
          size='small'
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          variant="outlined" // or "filled" for a different style
          fullWidth // Makes the TextField take the full width of the form
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faEnvelope} />
              </InputAdornment>
            ),
          }}
          className='text'
        />
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          type="submit" // Added type for button
          className='btn'
          sx={{
            '&:hover': {
              backgroundColor: '#1c4ed8', // Example hover color
              color: 'white',
            }, borderRadius: '50px',
          }}
        >
          Sign Up For Updates
        </Button>
      </Box>
    </form>
  );
};

export default SignupForm;

