import * as React from 'react';
import { useContext, useEffect} from "react";
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import SignInCard from './SignInCard';
import Content from './Content';
import AuthContext from "../../utils/AuthContext";
import { useNavigate, useLocation } from 'react-router-dom';


export default function SignInSide() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Get the 'next' parameter from the query string, or default to '/dashboard'
  const next = new URLSearchParams(location.search).get('next') || '/dashboard';

  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user, navigate]);

  return (
    <Box>
      <Stack
        direction="column"
        component="main"
        sx={[
          {
            justifyContent: 'center',
            height: 'calc((1 - var(--template-frame-height, 0)) * 100%)',
            marginTop: 'max(40px - var(--template-frame-height, 0px), 0px)',
            minHeight: '100%',
          },
          (theme) => ({
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              inset: 0,
              backgroundImage:
                'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
              backgroundRepeat: 'no-repeat',
            },
          }),
        ]}
      >
        <Stack
          direction={{ xs: 'column-reverse', md: 'row' }}
          sx={{
            justifyContent: 'center',
            gap: { xs: 6, sm: 12 },
            p: 2,
            mx: 'auto',
            backgroundImage: "url('https://flowbite.s3.amazonaws.com/blocks/e-commerce/girl-shopping-list.svg')",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'large', 
            backdropFilter: "blur(75px)", // Optional blur effect
          }}
        >
          <Stack
            direction={{ xs: 'column-reverse', md: 'row' }}
            sx={{
              justifyContent: 'center',
              gap: { xs: 6, sm: 12 },
              p: { xs: 2, sm: 4 },
              m: 'auto',
            }}
          >
            <Content />
            <SignInCard next={next}/>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}