import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Card, CardContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { CartesianGrid } from 'recharts';

export default function InvoiceItemAnalysis({ data, title, color, currency }) {
    // Format data for the chart and filter out unnamed items
    const chartData = data?.filter(item => item.name !== 'Unnamed Item')
      .slice(0, 5)
      .map(item => ({
        name: item.name.length > 20 ? item.name.substring(0, 20) + '...' : item.name,
        value: item.value,
        fullName: item.name
      }));
  
    const totalValue = chartData?.reduce((sum, item) => sum + item.value, 0);
  
    return (
      <Card variant="outlined" sx={{ width: '615px', borderRadius: '20px' }}>
        <CardContent>
          <Typography component="h4" variant="subtitle2 bold" gutterBottom>
            {title}
          </Typography>
          <Stack sx={{ justifyContent: 'space-between', mb: 2 }}>
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography variant="h4" component="p">
                {currency === 'zł' ? `${totalValue.toLocaleString()} zł` : `${currency}${totalValue.toLocaleString()}`} Total
              </Typography>
              <Chip size="small" color="success" label="+X% (Last Month)" />
            </Stack>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              Summary of top items by value
            </Typography>
          </Stack>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={chartData}
              layout="vertical"
              margin={{ top: 20, right: 20, left: 40, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis 
                type="category" 
                dataKey="name" 
                width={100}
                style={{ fontSize: '12px' }}
              />
              <Tooltip
                formatter={(value, name, props) => [
                  value,
                  props.payload.fullName
                ]}
              />
              <Bar 
                dataKey="value"
                name="Item Value"
                fill={color}
                barSize={20}
                radius={[0, 4, 4, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>
    );
  }
  
  InvoiceItemAnalysis.propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      })
    ).isRequired,
    title: PropTypes.string.isRequired,
  };