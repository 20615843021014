import { useLocation } from 'react-router-dom';
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Box, Button, IconButton, Typography, Grid, Container, List, ListItem, Tabs, Tab } from "@mui/material";
import { CircularProgress, ListItemIcon, ListItemText } from "@mui/material";
import {Download as DownloadIcon} from "@mui/icons-material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { Navbar } from "../../components/Navbar/Navbar";
import { DocumentContent } from "../../components/DocumentContent/DocumentContent";
import { UploadFrame } from "../../components/UploadFrame/UploadFrame";
import "./style.css";
import { ItemList } from "../../components/ItemList/Itemlist";
import { QueryFrame } from "../../components/QueryFrame/QueryFrame";
import { DownloadFrame } from "../../components/DownloadFrame/DownloadFrame";
import { SaveFrame } from "../../components/SaveFrame/SaveFrame";
import { motion } from "framer-motion";
import SideBar from "../../components/SideBar/SideBar";

const transformToFields = (data) => {
  return Object.keys(data).map(key => {
    const value = data[key];

    // Check if the value is an object
    if (typeof value === 'object' && value !== null) {
      // Convert the object to a string representation or handle it as needed
      return {
        key: key,
        value: JSON.stringify(value) // You can customize this part based on your needs
      };
    }

    // Otherwise return the key-value pair as is
    return {
      key: key,
      value: value
    };
  });
};


function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

export const InvoiceDetailView = () => {
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  // Get the list of invoice IDs and file paths from the query parameters
  const invoiceIds = useMemo(() => {
      return queryParams.get('invoiceIds')?.split(',') || [];
  }, [queryParams]); // Dependency array includes queryParams

  const filePaths = useMemo(() => {
      return queryParams.get('filepaths')?.split(',') || [];
  }, [queryParams]); // Dependency array includes queryParams
  
  const handleDownloadClick = useCallback(() => setShowDownloadFrame(true), []);
  // Create state for current invoiceId
  const [currentInvoiceId, setCurrentInvoiceId] = useState(invoiceIds[0]);
  
  const fileInvoiceMapping = useMemo(() => filePaths.map((filePath, index) => ({
    filePath,
    invoiceId: invoiceIds[index]
  })), [filePaths, invoiceIds]);

  const [loading, setLoading] = useState(false);
  const [approve, setApprove] = useState(false);
  const navigate = useNavigate();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedDocument, setSelectedDocument] = useState(filePaths[0]);
  const [showUploadFrame, setShowUploadFrame] = useState(false);
  const [showQueryFrame, setShowQueryFrame] = useState(false);
  const [fields, setFields] = useState([]);
  const [initFields, setInitFields] = useState();
  const [modifiedFields, setModifiedFields] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [showDownloadFrame, setShowDownloadFrame] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [showSaveFrame, setShowSaveFrame] = useState(false);
  const handleUploadClick = () => setShowUploadFrame(true);
  const handleCloseUploadFrame = () => setShowUploadFrame(false);
  const handleQueryFrame = () => setShowQueryFrame(true);

  const url = useMemo(() => {
    return internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';
  }, [internalUrl]);

  const handleCloseQueryFrame = async() => {
    await fetchItems();
    setShowQueryFrame(false);
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseDownloadFrame = useCallback(() => {
    setShowDownloadFrame(false);
  }, []);

  const fetchItems = useCallback(async () => {
    if (!currentInvoiceId) return;
    try {
      setLoading(true);
      const response = await fetch(`${url}/api/invoices/${currentInvoiceId}/items`);
      
      if (!response.ok) {
        throw new Error(`Failed to fetch. Status: ${response.status}`);
      }
  
      const data = await response.json();
      
      if (data.message === "Success") {
        setInitFields(data.data)
        const transformedFields = transformToFields(data.data);
        setFields(transformedFields);
      } else {
        console.error("Unexpected response message:", data.message);
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
      setFields([]); // Reset fields on error
    } finally {
      setLoading(false);
    }
  }, [url, currentInvoiceId]);


  const handleChanges = () => {
    if (changesMade) {
      setShowSaveFrame(true); // Show SaveFrame if there are changes
    } else {
      navigate('/dashboard-demo'); // If no changes, go directly to the dashboard
    }
  };
  
  // Triggered when user wants to navigate away (e.g., to dashboard)
  const handleGoDashboard = () => {
    handleChanges();
  };

  const handleClose = () => {
    setShowSaveFrame(false);
  };
  
  // When SaveFrame is closed (without saving), discard changes and navigate
  const handleCloseSaveFrame = () => {
    setShowSaveFrame(false);
    navigate('/dashboard-demo');
  };
  
  // When SaveFrame is confirmed and changes are saved, proceed to update and navigate
  const handleSaveAndApprove = useCallback(() => {

    setShowSaveFrame(false);
    if (approve) {
      updateStatus(currentInvoiceId)
        .then(() => {
          navigate('/dashboard-demo'); // Navigate to dashboard after successful update
        })
        .catch(error => {
          console.error('Update error:', error);
        });
    } else {
      navigate('/dashboard-demo');
    }
    // eslint-disable-next-line
  }, []);
  
  const handleApprove = () => {
    setApprove(true);
    if (changesMade) {
      setShowSaveFrame(true); // If changes are made, show SaveFrame first
    } else {
      updateStatus(currentInvoiceId) // If no changes, proceed with approval directly
        .then(() => {
          navigate('/dashboard-demo');
        })
        .catch(error => {
          console.error('Navigation error:', error);
        });
    }
  };
  
  useEffect(() => {
    fetchItems();
  }, [fetchItems, currentInvoiceId, handleSaveAndApprove]);

  const updateStatus = async (invoiceId) => {
    const status = { "status": "APPROVED" };
    try {
      const response = await fetch(`${url}/api/invoices/${invoiceId}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(status)
      });
  
      if (!response.ok) {
        throw new Error('Failed to update status:', response.statusText);
      }
    } catch (error) {
      console.error('Update error:', error);
    }
  };

  const handleRerun = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${url}/api/invoices/${currentInvoiceId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ invoice_id: currentInvoiceId }),
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
      
      await fetchItems();
    } catch (error) {
      console.error("Error rerunning invoice:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDocumentChange = (newFilePath) => {
    if (selectedDocument === newFilePath) return;
    else {
    const mapping = fileInvoiceMapping.find(item => item.filePath === newFilePath);
    if (mapping) {
      setFields([]);
      setCurrentInvoiceId(mapping.invoiceId);
      setSelectedDocument(newFilePath);
      setPageNumber(1);
    }
    }
  };

  const goToPreviousPage = () => {
    setPageNumber(prevPage => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber(prevPage => Math.min(prevPage + 1, numPages));
  };

  const [openSideBar, setOpenSideBar] = useState(true);
  const handleDrawerToggle = () => {
    setOpenSideBar(!openSideBar);
  };


  return (
    <Box width="100%" bgcolor="white">
      <>
        <SideBar open={openSideBar} handleDrawerToggle={handleDrawerToggle} demo={true} />
        <Navbar sideBarOpen={openSideBar} />
        {showUploadFrame && <UploadFrame onClose={handleCloseUploadFrame} />}
        {showSaveFrame && <SaveFrame 
                            onDiscard={handleCloseSaveFrame}
                            onSave={handleSaveAndApprove}
                            onClose={handleClose}
                            fields={initFields}
                            modifiedFields={modifiedFields}
                            url={url}
                            invoiceId={currentInvoiceId} />}
        {showQueryFrame && (
          <QueryFrame 
            onClose={handleCloseQueryFrame}
            url={url}
            invoiceId={currentInvoiceId}
          />
        )}

        <Container maxWidth="500px" sx={{mt: 12 }}>
          <Grid container display="flex" justifyContent="center">
            <Grid item xs={1} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <IconButton 
                color="primary" 
                onClick={handleGoDashboard}
                sx={{ color: '#1c4ed8', border: '1px solid #f5f5f5' }}
              >
                <ArrowBackIcon />
              </IconButton>
              <List sx={{
                overflow: 'auto',
                height: '600px'
              }}>
                {filePaths.map((doc, index) => (
                  <ListItem 
                    button 
                    selected={selectedDocument === doc}
                    key={index} 
                    onClick={() => handleDocumentChange(doc)}
                    sx={{
                      backgroundColor: selectedDocument === doc ? '#f5f5f5' : 'transparent',
                      color: '#1c4ed8',
                      justifyContent: 'center', 
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ flexDirection: 'column', alignItems: 'center', display: 'flex', cursor: 'pointer' }}>
                      <Box 
                        sx={{
                          width: '9vh', 
                          height: '13vh', 
                          display: 'flex', 
                          backgroundColor: '#EDEDED',
                          alignItems: 'center', 
                        }}
                      >
                        <DocumentContent 
                          path={doc}
                          pageNumber={1}
                          scale={0.13}
                          onDocumentLoadSuccess={onDocumentLoadSuccess}
                        />
                      </Box> 
                      <Typography sx={{
                        fontSize: '10px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '90px', // adjust the width as needed
                      }}>{doc.split('/').pop()}</Typography>
                    </Box>
                  </ListItem>
                ))}
              </List>
              <IconButton onClick={handleUploadClick}>
                <AddIcon />
              </IconButton>
            </Grid>

            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Box
                sx={{
                  width: '98%',
                  height: '90vh',
                  flexDirection: 'column', // Stack PDF and buttons vertically
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden', // Prevents overflow
                }}
              >
                {/* Header field */}
                <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center',
                    backgroundColor: 'white', gap: 1, marginBottom:1,  border: '1px solid #f5f5f5',
                 }}>
                  <Button
                    variant="outlined" 
                    size="small" 
                    sx={{ marginLeft: 1, borderColor: '#1c4ed8', color: '#1c4ed8',
                      '&:hover': {
                        backgroundColor: '#1c4ed8',
                        color: 'white',
                      },
                    }}
                    onClick={handleQueryFrame}
                  >Add new field
                  </Button>
                  <Button
                    variant="outlined" 
                    size="small" 
                    sx={{ borderColor: '#1c4ed8', color: '#1c4ed8',
                      '&:hover': {
                        backgroundColor: '#1c4ed8',
                        color: 'white',
                      },
                    }}
                    onClick={() => handleRerun()}
                  >Rerun
                  </Button>
                  <IconButton
                    variant="contained"
                    onClick={goToPreviousPage}
                    disabled={pageNumber <= 1}
                    sx={{ borderColor: '#1c4ed8', color: '#1c4ed8',
                      '&:hover': {
                        backgroundColor: '#1c4ed8',
                        color: 'white',
                      },
                    }}
                  >
                    <ChevronLeftIcon/>
                  </IconButton>
                  <Typography variant="body1" sx={{ justifyContent: 'center' }}>
                    Page {pageNumber} of {numPages}
                  </Typography>
                  <IconButton
                    variant="contained"
                    onClick={goToNextPage}
                    disabled={pageNumber >= numPages}
                    sx={{ borderColor: '#1c4ed8', color: '#1c4ed8',
                      '&:hover': {
                        backgroundColor: '#1c4ed8',
                        color: 'white',
                      },
                    }}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                  <Button variant="contained" color="success" size="small" sx={{marginLeft: 18}} 
                    onClick={handleApprove}>Approve</Button>
                  <Button
                    variant="outlined" 
                    onClick={handleDownloadClick}
                    size="small" 
                    sx={{ 
                      borderColor: '#1c4ed8', 
                      color: '#1c4ed8',
                      width: '32px',
                      minWidth: '32px',
                      padding: '4px',
                      '& .MuiButton-startIcon': {  // Remove default icon spacing
                        margin: 0,
                        padding: 0,
                      },
                      '&:hover': {
                        backgroundColor: '#1c4ed8',
                        color: 'white',
                      },
                    }}
                  >
                    <DownloadIcon sx={{ fontSize: 20 }} />
                  </Button>
                </Box>
                <Box 
                  sx={{
                    width: '100%', 
                    height: '80vh',
                    display: 'flex', 
                    backgroundColor: '#EDEDED',
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    overflow: 'auto',
                  }}
                >
                  <DocumentContent 
                    path={selectedDocument} // Pass the document path
                    pageNumber={pageNumber} 
                    scale={0.77} 
                    onDocumentLoadSuccess={onDocumentLoadSuccess} />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={openSideBar ? 4 : 3} sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Typography variant="h6">
                  {fields.find(field => field.key === 'invoice_name')?.value.split('/').pop() || 'No Invoice Name'}
                </Typography>
              </Box>

              {/* Tabs */}
              <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 'auto' }} width='100%'>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="invoice tabs" variant="fullWidth" 
                  sx={{ justifyContent: 'center', alignItems: 'center' }}>
                  <Tab label="Fields" />
                  <Tab label="Ask Questions" />
                </Tabs>
              </Box>

              {/* Fields Tab Content */}
              <TabPanel value={tabValue} index={0}>
                <Box 
                  sx={{ 
                    overflowY: 'auto',
                    maxHeight: '620px',
                    width: '100%',
                    ml: '5px',
                    borderRadius: '4px',
                  }}
                >
                  {loading ? (
                    <motion.div
                      initial={{ opacity: 1, scale: 1 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.9 }}
                      transition={{ duration: 0.5 }}
                      style={{
                        position: "fixed",
                        top: "9%",
                        left: "62%",
                        width: "450px",
                        height: "630px",
                        backgroundColor: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000,
                      }}
                    >
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <List style={{ marginTop: "16px" }}>
                          <ListItem>
                            <ListItemIcon>
                              <CircularProgress size={20} style={{ color: "#1c4ed8" }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary={"Loading items.."} 
                              style={{ fontWeight: "bold", color: "#1c4ed8", marginTop: "16px" }}
                            />
                          </ListItem>
                        </List>
                      </Box>
                    </motion.div>
                  ) : (
                    <ItemList 
                      fields={fields} 
                      setChangesMade={setChangesMade}
                      setModifiedFields={setModifiedFields}
                    />
                  )}
                </Box>
              </TabPanel>

              {/* AI Assistant Tab Content */}
              <TabPanel value={tabValue} index={1}>
                <Box 
                  sx={{ 
                    maxHeight: '630px',
                    width: '100%',
                    ml: '5px',
                    borderRadius: '4px',
                  }}
                >
                  <iframe
                    src="https://viewer-invoice-processor.up.railway.app/customer-support-rtz1916"
                    style={{
                      border: 'none',
                      width: '100%',
                      height: '600px'
                    }}
                    title='AI Assistant'
                  />
                </Box>
              </TabPanel>
            </Grid>
          </Grid>
        </Container>
        {showDownloadFrame && <DownloadFrame 
                                  onClose={handleCloseDownloadFrame} 
                                  checkedInvoiceIds={[currentInvoiceId]}
                                  url={url} />}
      </>
    </Box>
  );
};



