import * as React from 'react';
import { useContext, useEffect} from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import StarIcon from "@mui/icons-material/Star";
import { styled } from '@mui/material/styles';
import ForgotPassword from './ForgotPassword';
import { GoogleIcon, FacebookIcon } from './CustomIcons';
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from 'lucide-react';
import { keyframes } from '@emotion/react';
import CircularProgress from '@mui/material/CircularProgress';
import AuthContext from '../../utils/AuthContext';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  boxShadow: 'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
}));

export default function SignInCard({next}) {
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [usernameError, setUsernameError] = React.useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [loginError, setLoginError] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [registerMessage, setRegisterMessage] = React.useState('');
  
  const navigate = useNavigate();
  const { loginUser } = useContext(AuthContext);

  const handleGoDashboard = () => navigate('/dashboard-demo');
  const handleNext = (next) => {
    navigate(next);
  }

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    // Retrieve and show the registration success message, then clear it
    const message = localStorage.getItem("registerMessage");
    if (message) {
      setRegisterMessage(message);
      localStorage.removeItem("registerMessage");
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate inputs
    if (!validateInputs()) {
      return;
    }
    setLoading(true);
    const success = await loginUser(username, password, setLoginError);
    setLoading(false);
    if (success) {
      handleNext(next);
    }
  };

  const validateInputs = () => {
    let isValid = true;

    if (!username || username.length < 6) {
      setUsernameError(true);
      setUsernameErrorMessage('Username must be at least 6 characters long.');
      isValid = false;
    } else {
      setUsernameError(false);
      setUsernameErrorMessage('');
    }

    if (!password || password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    return isValid;
  };

  const borderAnimation = keyframes`
    0% { background-size: 800% 800%; background-image: linear-gradient(to right, #00ffff, #6b00ff, #ff6b6b); }
    100% { background-size: 800% 800%; background-position: 100% 0; }
  `;

  const buttonAnimation = keyframes`
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  `;

  return (
    <Card variant="outlined">
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Button
          fullWidth variant="outlined" startIcon={<StarIcon />} endIcon={<ArrowRightIcon />}
          onClick={handleGoDashboard} color='white'
          sx={{
            position: 'relative',
            color: 'white',
            overflow: 'hidden',
            background: 'linear-gradient(to right, #00ffff, #6b00ff, #ff6b6b)',
            backgroundSize: '800% 800%',
            animation: `${borderAnimation} 8s ease infinite`,
            '& .button-content': {
              position: 'relative', zIndex: 1,
              animation: `${buttonAnimation} 1s ease-in-out infinite`,
              color: 'white'
            }
          }}
        >
          <span className="button-content">Try Demo For Free</span>
        </Button>
      </Box>
      <Divider>or</Divider>
      <Typography
        component="h1"
        variant="h4"
        sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
      >
        Sign in
      </Typography>
      {registerMessage && (
        <Typography color="success.main" sx={{ textAlign: "center" }}>
          {registerMessage}
        </Typography>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
      >
        <FormControl fullWidth error={usernameError}>
          <FormLabel htmlFor="username">Username</FormLabel>
          <TextField
            id="username"
            type="text"
            name="username"
            placeholder="Your Username"
            autoComplete="username"
            autoFocus
            required
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            helperText={usernameError ? usernameErrorMessage : ''}
          />
        </FormControl>
        <FormControl fullWidth error={passwordError}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Link
              component="button"
              type="button"
              onClick={handleClickOpen}
              variant="body2"
              sx={{ alignSelf: 'baseline' }}
            >
              Forgot your password?
            </Link>
          </Box>
          <TextField
            name="password"
            placeholder="••••••"
            type="password"
            id="password"
            autoComplete="current-password"
            autoFocus
            required
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            helperText={passwordError ? passwordErrorMessage : ''}
          />
        </FormControl>
        {loginError && <Typography color="error">{loginError}</Typography>} {/* Display login error */}
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        />
        <ForgotPassword open={open} handleClose={handleClose} />
        <Button type="submit" fullWidth variant="contained" >
          {loading ? <CircularProgress size={24} color="white" /> : 'Sign in'}
        </Button>
        <Typography sx={{ textAlign: 'center' }}>
          Don&apos;t have an account?{' '}
          <Link href="/register/" variant="body2">
            Sign up
          </Link>
        </Typography>
      </Box>
      <Divider>or</Divider>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => alert('Sign in with Google')}
          startIcon={<GoogleIcon />}
        >
          Sign in with Google
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => alert('Sign in with Facebook')}
          startIcon={<FacebookIcon />}
        >
          Sign in with Facebook
        </Button>
      </Box>
    </Card>
  );
}
