import React, { useState } from 'react';
import { Button, IconButton, Typography, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useAxios from "../../utils/useAxios";
import "./style.css";


export const SaveFrame = ({ onClose, onSave, onDiscard, fields, modifiedFields, url, invoiceId, isApproveAction, status }) => {
  const [loading, setLoading] = useState(false);
  const axios = useAxios();

  function updateInitialFields(initialFields, updatedFields) {
    // Create a shallow copy of initial fields to modify
    let result = { ...initialFields };
  
    // Create a set of fields that should be kept (not nullified).
    const fieldsToKeep = new Set();
    // Always keep these fields so they are preserved.
    fieldsToKeep.add('invoice_name');
    fieldsToKeep.add('bounding_regions');
    fieldsToKeep.add('items_bounding_regions');
  
    // Process invoice fields.
    if (updatedFields.invoice) {
      updatedFields.invoice.forEach(item => {
        result[item.key] = item.value;
        fieldsToKeep.add(item.key);
      });
    }
  
    // Handle address updates.
    const addressTypes = [
      'customerAddress',
      'vendorAddress',
      'billingAddress',
      'shippingAddress',
      'serviceAddress',
      'remittanceAddress'
    ];
    addressTypes.forEach(addressType => {
      if (updatedFields[addressType]) {
        // Convert camelCase to snake_case for the field name.
        const fieldName = addressType.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
        // Create an object to store address fields.
        const addressObj = {
          house_number: '',
          po_box: '',
          road: '',
          city: '',
          state: '',
          postal_code: '',
          country_region: '',
          street_address: '',
          unit: '',
          city_district: '',
          state_district: '',
          suburb: '',
          house: '',
          level: ''
        };
        // Update values from the updated fields.
        updatedFields[addressType].forEach(item => {
          addressObj[item.key] = item.value;
        });
        // Convert to string format.
        result[fieldName] = JSON.stringify(addressObj).replace(/"/g, "'");
        fieldsToKeep.add(fieldName);
      }
    });
  
    // Process custom fields.
    if (updatedFields.customFields) {
      fieldsToKeep.add('custom_fields');
      // Create a set of keys that are present in updatedFields.customFields.
      const newKeys = new Set(updatedFields.customFields.map(item => item.key));
      // Update result.custom_fields with new key-value pairs.
      updatedFields.customFields.forEach(item => {
        if (item) {
          result.custom_fields[item.key] = item.value;
        }
      });
      // Remove keys from result.custom_fields that are not present in updatedFields.
      Object.keys(result.custom_fields).forEach(key => {
        if (!newKeys.has(key)) {
          delete result.custom_fields[key];
        }
      });
      // (Optional) If you want to clear custom_fields, remove the following line:
      // result.custom_fields = null;
    }
  
    // Process invoice items.
    if (updatedFields.invoiceItems) {
      fieldsToKeep.add('invoice_items');
      if (result.invoice_items) {
        result.invoice_items.forEach((item, idx) => {
          if (item) {
            Object.keys(item).forEach(key => {
              result.invoice_items[idx][key] = null;
            });
          }
        });
      }
      updatedFields.invoiceItems.forEach((itemArray, index) => {
        if (!result.invoice_items) {
          result.invoice_items = [];
        }
        if (!result.invoice_items[index]) {
          result.invoice_items[index] = {};
        }
        itemArray.forEach(update => {
          const { key, value } = update;
          result.invoice_items[index][key] = value;
        });
      });
    }
  
    // Define protected fields.
    const protectedFields = [
      'amount_due',
      'customer_id',
      'customer_name',
      'due_date',
      'invoice_date',
      'invoice_id_number',
      'invoice_name',
      'previous_unpaid_balance',
      'purchase_order',
      'service_start_date',
      'service_end_date',
      'subtotal',
      'total_tax',
      'vendor_name',
      'custom_fields',
      'bounding_regions',         // also protect bounding regions here if desired
      'items_bounding_regions'
    ];
  
    // Nullify fields based on conditions.
    Object.keys(result).forEach(key => {
      if (!fieldsToKeep.has(key) && key !== 'invoice_items') {
        if (!updatedFields.invoice) {
          if (
            !protectedFields.includes(key) &&
            !key.endsWith('address') &&
            !key.endsWith('recipient')
          ) {
            result[key] = null;
          }
        } else {
          if (!key.endsWith('address') && !key.endsWith('recipient')) {
            result[key] = null;
          }
        }
      }
    });
  
    return result;
  }



  const handleSave = async () => {
    setLoading(true);

    const changes = updateInitialFields(fields, modifiedFields);

    try {
      // Save the changes first
      await axios.put(`${url}/api/invoices/${invoiceId}/items/`, 
        changes,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      // Only update status if this is an approve action
      if (isApproveAction) {
        try {
          await axios.post(`${url}/api/invoices/${invoiceId}/`, 
            { status: "APPROVED" },
            {
              headers: { 'Content-Type': 'application/json' },
            }
          );
        } catch (approveError) {
          console.error('Error updating approval status:', approveError);
        }
      } else {
        // Update status if it's not an approve action
        try {
          await axios.post(`${url}/api/invoices/${invoiceId}/`,
            { status: "IN_REVIEW" },
            {
              headers: { 'Content-Type': 'application/json' },
            }
          );
        } catch (statusError) {
          console.error('Error updating status:', statusError);
        }
      }
      onSave();
    } catch (error) {
      console.error('Error updating fields:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };


  const handleDiscard = () => {
    onDiscard();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {/* Backdrop to darken the background */}
      <div className="save-backdrop" ></div>

      {/* Loading Screen */}
      {loading && (
        <div className="loading-overlay">
            <CircularProgress size={22} style={{ color: "#1c4ed8" }} />
            <Typography sx={{fontWeight: "bold", color: "#1c4ed8", fontSize: '1.3rem', marginLeft: "12px" }}>Saving...</Typography>
        </div>
      )}

      {/* Save Frame */}
      <div className="save-frame">
        <div className="content-wrapper">
          {/* Close Button */}
          <IconButton
            size="medium"
            sx={{
              position: "absolute",
              top: 18,
              right: 18,
              border: 1,
              borderColor: "#1c4ed8",
              color: "#1c4ed8",
              backgroundColor: "white",
              "&:hover": { backgroundColor: "#f0f4ff" },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          {/* Confirmation Section */}
          <div className="confirmation-content">
            <h2 className="confirmation-title">Save Changes</h2>
            <p className="confirmation-subtitle">
              You have made changes. Would you like to save them before closing?
            </p>

            {/* Save/Discard Buttons */}
            <div className="button-container">
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "#1c4ed8",
                  padding: "10px 20px",
                  borderRadius: '50px',
                  "&:hover": { backgroundColor: "#163bb7" },
                  marginRight: "10px",
                }}
                onClick={handleSave}
                variant="contained"
                disabled={loading} // Disable button if loading
              >
                Save
              </Button>
              <Button
                sx={{
                  color: "#ff1744",
                  borderColor: "#ff1744",
                  padding: "10px 20px",
                  borderRadius: '50px',
                  "&:hover": { backgroundColor: "#ffe7e9" },
                }}
                onClick={handleDiscard}
                variant="outlined"
              >
                Discard
              </Button>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .save-backdrop {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          z-index: 1000;
        }
        .save-frame {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 400px;
          padding: 20px;
          background-color: #fff;
          z-index: 1100;
          border-radius: 8px;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }
        .loading-overlay {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 400px;
          height: 210px;
          padding: 20px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1200;
          border-radius: 8px;
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }
      `}</style>
    </>
  );
};
