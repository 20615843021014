import React from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import { LayoutDashboard, BarChart2, Shield, BadgeCheckIcon } from 'lucide-react';
import { Navbar } from '../../components/Navbar/Navbar';
import { HeroSection } from '../../components/HeroSection/HeroSection';
import { FeatureCard } from '../../components/FeatureCard/FeatureCard';
import { SignupForm } from '../../components/SignupForm/SignupForm';
import Divider from '@mui/material/Divider';
import "./style.css";

export const Home = () => {

  const featureCards = [
    {
      icon: LayoutDashboard,
      title: "Smart Dashboard",
      description: "Get a bird's-eye view of your invoice processing with our intuitive dashboard."
    },
    {
      icon: BarChart2,
      title: "Advanced Analytics",
      description: "Gain deep insights into your financial data with AI-powered analytics."
    },
    {
      icon: Shield,
      title: "Secure Processing",
      description: "Rest easy knowing your data is protected with enterprise-grade security."
    },
    {
      icon: BadgeCheckIcon,
      title: "Business Efficiency",
      description: "Boost efficiency with scalable automated invoice processing."
    }
  ];
  // Create duplicates for seamless scrolling
  const duplicatedFeatureCards = [...featureCards, ...featureCards];


  return (
    <Box 
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container maxWidth="lg" sx={{ flex: 1 }} className="home">
      <Navbar sideBarOpen={true}/>
        <HeroSection />
        <Box sx={{ my: 10, textAlign: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Key Features
          </Typography>
          <Typography variant="h6" color="textSecondary" sx={{ mb: 4 }}>
            Here are a few reasons why you should choose our platform
          </Typography>
          
          <Box className="carousel-container">
            <Box className="carousel-content">
              {duplicatedFeatureCards.map((feature, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <FeatureCard
                    icon={feature.icon}
                    title={feature.title}
                    description={feature.description}
                  />
                </Grid>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
      <Divider />
      <Box>
        <Container maxWidth="sm">
          <Typography variant="h5" textAlign="center" gutterBottom sx={{ mt: 5, mb: -5 }}>
            Ready to transform your invoice processing?
          </Typography>
        </Container>
        <SignupForm />
      </Box>
    </Box>
  );
};

