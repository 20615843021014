import React, { useState } from "react";
import { Button, IconButton, TextField, Typography, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useAxios from "../../utils/useAxios";
import { useTranslation } from 'react-i18next';
import "./style.css"; // Your CSS for consistent styling

export const QueryFrame = ({ onClose, url, invoiceId }) => {
  const [fieldName, setFieldName] = useState("");
  const [loading, setLoading] = useState("");
  const [fieldDescription, setFieldDescription] = useState("");
  const { t } = useTranslation();
  const axios = useAxios();

  // Function to check if both fields are non-empty
  const isSaveDisabled = !fieldName || !fieldDescription;


  const handleSave = async () => {
    setLoading(true); // Show loading while saving

    try {
      // Prepare and send the PUT request
      const response = await axios.post(`${url}/api/gen_custom_field/${invoiceId}/`, 
        {
          "fieldName" : fieldName,
          "fieldDescription" : fieldDescription
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      console.log('Post successful:', response.data);
      onClose();
    } catch (error) {
        console.error('Error updating fields:', error);
        throw error;
    } finally {
        setLoading(false); // Ensure loading is hidden after the operation
    }
  };


  return (
    <>
      {/* Backdrop */}
      <div className="backdrop" onClick={onClose}></div>

      {/* Add Field Frame */}
      <div className="frame">
        <div className="content-wrapper">
          {/* Close Button */}
          <IconButton
            size="medium"
            sx={{
              position: "absolute",
              top: 18,
              right: 18,
              border: 1,
              borderColor: "#1c4ed8",
              color: "#1c4ed8",
              backgroundColor: "white",
              "&:hover": { backgroundColor: "#f0f4ff" },
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>

          {/* Add Field Content */}
          <div className="content">
            <h2 className="title">{t("Add New Field")}</h2>
            <p className="subtitle">
              {t("Add field you want to extract to the list and to be used in Analyze operations.")}
            </p>

            {/* Input for Field Name */}
            <TextField
              label={t("Field Name")}
              variant="outlined"
              fullWidth
              value={fieldName}
              onChange={(e) => setFieldName(e.target.value)}
              sx={{ marginBottom: "16px" }}
            />

            {/* Input for Field Description */}
            <TextField
              label={t("Field Description")}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={fieldDescription}
              onChange={(e) => setFieldDescription(e.target.value)}
              sx={{ marginBottom: "24px", backgroundColor: '#f5f5f5' }}
            />

            {/* Buttons */}
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "24px" }}>
              {loading ? (
                <>
                  <CircularProgress size={22} style={{ color: "#1c4ed8" }} />
                  <Typography sx={{ fontWeight: "bold", color: "#1c4ed8", fontSize: '1.2rem', marginLeft: "12px" }}>
                    {t("Analyzing...")}
                  </Typography>
                </>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={onClose}
                    sx={{ margin: 1, borderRadius: '50px' }}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleSave}
                    disabled={isSaveDisabled}
                    sx={{
                      margin: 1,
                      padding: "10px 20px",
                      borderRadius: '50px',
                      "&:hover": { backgroundColor: "#163bb7", color: 'white' },
                    }}
                  >
                    {t("Save")}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


