import * as React from 'react';
import { useContext } from "react";
import { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import AuthContext from '../../utils/AuthContext';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(3),
  gap: theme.spacing(2),
  margin: 'auto',
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

export default function Verify() {
  // URL configuration
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const url = useMemo(() => 
    internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000',
    [internalUrl]
  );
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [codeErrorMessage, setCodeErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(120); // 2 minutes in seconds
  const [canResend, setCanResend] = useState(false);
  const { verifyUser } = useContext(AuthContext);
  const { t } = useTranslation();

  useEffect(() => {
    // Start the timer when component mounts
    startResendTimer();
  }, []);

  const startResendTimer = () => {
    setCanResend(false);
    setResendTimer(120);

    const timer = setInterval(() => {
      setResendTimer((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setCanResend(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup timer on component unmount
    return () => clearInterval(timer);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleResend = async (event) => {
    // Add event parameter and prevent form submission
    event.preventDefault();
    
    if (!canResend) return;

    const email = localStorage.getItem('email');
    setLoading(true);
    try {
      const response = await fetch(`${url}/api/resend-code/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email
        })
      });
      if (response.status === 200) {
        setMessage(t('Verification code has been resent to your email'));
        startResendTimer(); // Restart the timer
      }

    } catch (error) {
      setMessage(error.response?.data?.error || t('Failed to resend verification code'));
    } finally {
      setLoading(false);
    }
  };

  const validateCode = () => {
    let isValid = true;

    if (!code || code.length < 6) {
      setCodeError(true);
      setCodeErrorMessage(t('Please enter a valid verification code.'));
      isValid = false;
    } else {
      setCodeError(false);
      setCodeErrorMessage('');
    }

    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateCode()) {
      return;
    }
    setLoading(true);
    await verifyUser(code, setCodeErrorMessage);
    setLoading(false);

  };

  return (
    <Card variant="outlined">
      <Typography
        component="h1"
        fontStyle="bold"
        variant="h6"
        sx={{ width: '100%', fontSize: '2.25rem', fontFamily: '"Funnel Display"' }}
      >
        {t("Verify Your Account")}
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, fontFamily: '"Funnel Display"' }}
      >
        <FormControl fullWidth error={codeError}>
          <TextField
            required
            autoFocus
            id="code"
            name="code"
            type="text"
            placeholder={t("Enter your verification code")}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            helperText={codeError ? codeErrorMessage : ''}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '50px',
                borderColor: 'black',
                border: 1,
                padding: '0 20px',
              },
              fontFamily: '"Funnel Display"'
            }}
          />
        </FormControl>
        
        {message && (
          <Typography 
            color={message.includes('error') ? 'error' : 'success.main'}
            sx={{ textAlign: 'center', fontFamily: '"Funnel Display"' }}
          >
            {message}
          </Typography>
        )}

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ 
            borderRadius: '50px', 
            backgroundColor: '#1c4ed8',
            mt: 2,
            fontFamily: '"Funnel Display"'
          }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : t('Verify')}
        </Button>

        <Typography sx={{ textAlign: 'center', fontFamily: '"Funnel Display"' }}>
          {t("Didn't receive a code?")}{' '}
          {canResend ? (
            <Link
              component="button"
              type="button"
              variant="body2"
              onClick={handleResend}
              sx={{ cursor: 'pointer', fontFamily: '"Funnel Display"' }}
            >
              {t("Resend")}
            </Link>
          ) : (
            <Typography
              component="span"
              variant="body2"
              color="text.secondary"
            >
              {t("Resend available in")} {formatTime(resendTimer)}
            </Typography>
          )}
        </Typography>

        <Divider>{t("or")}</Divider>

        <Typography sx={{ textAlign: 'center', fontFamily: '"Funnel Display"' }}>
          {t("Back to")}{' '}
          <Link
            href="/login"
            variant="body2"
            sx={{ fontFamily: '"Funnel Display"' }}
          >
            {t("Sign in")}
          </Link>
        </Typography>
      </Box>
    </Card>
  );
}