import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Home } from './pages/Home/Home';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { InvoiceDetailView } from './pages/InvoiceDetailView/InvoiceDetailView';
import { ContactUs } from './pages/ContactUs/ContactUs';
import { Demo } from './pages/DemoFrame/DemoFrame';
import SignInSide from './pages/SignIn/SignInSide';
import SignUpSide from './pages/SignUp/SignUpSide';
import VerifySide from './pages/SignUp/VerifySide';
import { AnalyticsView } from './pages/Analytics/AnalyticsView';
import { Footer } from './components/Footer/Footer';
import PrivateRoute from './utils/PrivateRoute';
import { AuthProvider } from './utils/AuthContext';
import { BusinessProvider } from './utils/BusinessContext';
import { startKeepAlive } from './utils/keepAlive';
import { PricingView } from './pages/Pricing/PricingView';
import { MembershipView } from './pages/Membership/MembershipView';
import { ReceiptConverter } from './pages/ReceiptConverter/ReceiptConverter';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import './App.css';

// Custom hook to track page views on route changes
function PageTracker() {
  const location = useLocation();
  const gaId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

  useEffect(() => {
    if (gaId) {
      window.gtag('config', gaId, {
        page_path: location.pathname,
      });
    }
  }, [location, gaId]);

  return null;
}

function AppContent() {
  useEffect(() => {
    startKeepAlive();
  }, []);

  return (
    <div className="App">
      <AuthProvider>
        <BusinessProvider>
          <PageTracker />
          <Routes>
            {/* Login Required Pages */}
            <Route element={<PrivateRoute />}>
              {/* <Route path="/dashboard" element={<Dashboard />} /> */}
              {/* <Route path="/analytics" element={<AnalyticsViewAuth />} /> */}
              {/* <Route path="/invoice/:invoiceId" element={<InvoiceDetailViewAuth />} /> */}
              <Route path="/membership" element={<MembershipView />} />
            </Route>

            {/* Login Not Required Pages - For Demo Purposes */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/analytics" element={<AnalyticsView />} />
            <Route path="/invoice/:invoiceId" element={<InvoiceDetailView />} />
            <Route path="/receipt-converter" element={<ReceiptConverter />} />

            {/* Login Not Required Pages */}
            <Route path="/" element={<Home />} />
            <Route path="/pricing" element={<PricingView />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/login" element={<SignInSide />} />
            <Route path="/register" element={<SignUpSide />} />
            <Route path="/verify" element={<VerifySide />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
        </BusinessProvider>
      </AuthProvider>
      <Footer />
    </div>
  );
}

function App() {
  useEffect(() => {
    const gaId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
    if (gaId) {
      const script = document.createElement("script");
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      // Attach the gtag function to the window object, making it accessible globally
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };

      window.gtag("js", new Date());
      window.gtag("config", gaId);
    }
  }, []);

  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
