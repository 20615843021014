import React, { useState, useMemo, useEffect } from 'react';
import axios from "axios";
import { Check } from 'lucide-react';
import { Navbar } from "../../components/Navbar/Navbar";
import useAxios from "../../utils/useAxios";

const styles = {
  container: {
    maxWidth: '1000px',
    margin: '0 auto',
    fontFamily: 'system-ui, sans-serif',
  },
  header: {
    textAlign: 'center',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#1c4ed8',
    marginTop: '120px'
  },
  subtitle: {
    fontSize: '1.25rem',
    color: '#6B7280',
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  tabGroup: {
    borderRadius: '9999px',
    position: 'relative',
    display: 'inline-flex',
    padding: '4px',
    backgroundColor: 'white',
  },
  tabWrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  tab: {
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '12px 40px',
    borderRadius: '9999px',
    border: 'solid #E5E7EB',
    cursor: 'pointer',
    fontSize: '0.875rem',
    fontWeight: '500',
    backgroundColor: 'transparent',
    color: '#374151',
    transition: 'all 0.2s',
    position: 'relative',
    zIndex: 1,
  },
  activeTab: {
    color: '#1c4ed8',
    border: 'solid #1c4ed8',
    backgroundColor: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
  },
  popularBadge: {
    position: 'absolute',
    top: '-2.5px',
    left: '-45px',
    padding: '4px 12px',
    backgroundColor: '#1c4ed8',
    color: '#FFFFFF',
    borderRadius: '9999px',
    fontSize: '0.75rem',
    fontWeight: '600',
    zIndex: 2,
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '50px',
    padding: '20px 0px',
  },
  card: {
    position: 'relative',
    padding: '24px',
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
    border: '2px solid #E5E7EB',
    transition: 'all 0.3s ease',
  },
  cardHover: {
    border: '2px solid #1c4ed8',
    transform: 'translateY(-4px)',
    boxShadow: '0 12px 20px -8px rgba(28, 78, 216, 0.2)',
  },
  cardTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '8px',
    color: '#111827',
  },
  cardDescription: {
    color: '#6B7280',
  },
  price: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#111827',
    marginBottom: '20px',
  },
  priceMonth: {
    fontSize: '1rem',
    color: '#6B7280',
  },
  priceDetail: {
    fontSize: '0.875rem',
    color: '#6B7280',
    marginTop: '4px',
  },
  featuresList: {
    listStyle: 'none',
    padding: 0,
    margin: '12px 0',
  },
  featureItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '12px',
    color: '#374151',
  },
  button: {
    width: '100%',
    padding: '12px 24px',
    borderRadius: '8px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'all 0.2s',
    backgroundColor: '#FFFFFF',
    color: '#1c4ed8',
    border: '2px solid #1c4ed8',
  },
  buttonHover: {
    backgroundColor: '#1c4ed8',
    color: '#FFFFFF',
    transform: 'translateY(-1px)',
    boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
  },
  footer: {
    textAlign: 'center',
    color: '#6B7280',
  },
  footerLink: {
    color: '#1c4ed8',
    textDecoration: 'none',
    fontWeight: '500',
    cursor: 'pointer',
  }
};

const PricingCard = ({ 
  title, 
  price, 
  description, 
  features,
  billingType,
  batchSize,
  priceId
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isCardHovered, setIsCardHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const axios = useAxios();

  const url = useMemo(() => {
    return internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';
  }, [internalUrl]);

  const getCheckoutUrl = async (id) => {
    return axios.post(`${url}/api/checkout/checkout_redirect/${id}/`);
  }

  const handleCheckout = async (id) => {
    setLoading(true);
    try {
        // Store price ID in the session and fetch checkout URL and redirect
        const response = await getCheckoutUrl(id);
        window.location.href = response.data.url;
    } catch (error) {
        if (error.response && error.response.status === 401) {
          // Redirect to login page if unauthorized
          window.location.href = `/login?next=/pricing`;
      } else {
          console.error("Error starting checkout:", error);
      }
    } finally {
        setLoading(false);
    }
  };

  return (
    <div 
      style={{
        ...styles.card,
        ...(isCardHovered && styles.cardHover)
      }}
      onMouseEnter={() => setIsCardHovered(true)}
      onMouseLeave={() => setIsCardHovered(false)}
    >
      <h3 style={styles.cardTitle}>{title}</h3>
      <p style={styles.cardDescription}>{description}</p>
      <div style={styles.price}>
        {price}$ 
        <span style={styles.priceMonth}>
          {billingType === 'membership' ? '/month' : `/per ${batchSize} invoice`}
        </span>
      </div>
      <ul style={styles.featuresList}>
        {features.map((feature, index) => (
          <li key={index} style={styles.featureItem}>
            <Check size={20} color="#1c4ed8" />
            {feature}
          </li>
        ))}
      </ul>
      <button 
        style={{
          ...styles.button,
          ...isHovered && styles.buttonHover
        }}
        onClick={() => handleCheckout(priceId)}
        disabled={loading}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {loading ? "Redirecting..." : "Get Started"}
      </button>
    </div>
  );
};

const CACHE_KEY = 'pricingCache';
const CACHE_DURATION = 3600000 * 24 * 7; // 1 hour in milliseconds

export const PricingView = () => {
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const [billingType, setBillingType] = useState("membership");
  const [pricingPlans, setPricingPlans] = useState([]);
  const url = useMemo(() => (internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000'), [internalUrl]);

  const fetchPricingData = async () => {
    try {
      const response = await axios.get(`${url}/api/subscription/pricing/`);
      const data = response.data.map(plan => {
        const membershipPlan = plan.prices.find(p => p.interval === "month");
        const payAsYouGoPlan = plan.prices.find(p => p.interval === "pay-as-you-go");

        return {
          title: plan.name,
          description: plan.description,
          features: plan.benefits.split('\n'),
          membershipPrice: membershipPlan?.price || 0,
          membershipPriceId: membershipPlan?.id || null,
          payAsYouGoPrice: payAsYouGoPlan?.price || 0,
          payAsYouGoPriceId: payAsYouGoPlan?.id || null,
          batchSize: payAsYouGoPlan?.invoice_batch_size > 1 ? payAsYouGoPlan.invoice_batch_size : "",
        };
      });

      // Update state with fresh data and cache it
      setPricingPlans(data);
      localStorage.setItem(CACHE_KEY, JSON.stringify({ data, timestamp: Date.now() }));
    } catch (error) {
      console.error("Error fetching pricing data:", error);
    }
  };

  useEffect(() => {
    // Load cached data immediately if available and not expired
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);

      if (Date.now() - timestamp < CACHE_DURATION) {
        setPricingPlans(data);
      }
    }

    // Fetch fresh data from API and update state
    fetchPricingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={styles.container}>
      <Navbar sideBarOpen={true} />
      <header style={styles.header}>
        <h1 style={styles.title}>Automate, Analyze, and Unlock Your Invoice Insights</h1>
        <p style={styles.subtitle}>Choose the perfect plan for your business needs</p>
      </header>

      <div style={styles.tabContainer}>
        <div style={styles.tabGroup}>
          <div style={styles.tabWrapper}>
            <button 
              style={{
                ...styles.tab,
                ...(billingType === "membership" && styles.activeTab)
              }}
              onClick={() => setBillingType("membership")}
            >
              Membership
            </button>
            <div style={styles.popularBadge}>Most Popular</div>
          </div>
          <button 
            style={{
              ...styles.tab,
              ...(billingType === "pay" && styles.activeTab)
            }}
            onClick={() => setBillingType("pay")}
          >
            Pay-as-you-go
          </button>
        </div>
      </div>

      <div style={styles.cardsContainer}>
        {pricingPlans.map((plan, index) => (
          <PricingCard
            key={index}
            {...plan}
            price={billingType === "membership" ? plan.membershipPrice : plan.payAsYouGoPrice}
            priceId={billingType === "membership" ? plan.membershipPriceId : plan.payAsYouGoPriceId}
            billingType={billingType}
          />
        ))}
      </div>

      <div style={styles.footer}>
        <p>
          Need help choosing?{' '}
          <span style={styles.footerLink}>Contact our sales team</span>
        </p>
      </div>
    </div>
  );
};