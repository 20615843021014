import React, { useState, useMemo, useEffect } from 'react';
import axios from "axios";
import { Check } from 'lucide-react';
import { Navbar } from "../../components/Navbar/Navbar";
import useAxios from "../../utils/useAxios";
import { useTranslation } from 'react-i18next';

const styles = {
  container: {
    maxWidth: '1000px',
    margin: '0 auto',
    fontFamily: 'system-ui, sans-serif',
  },
  header: {
    textAlign: 'center',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#1c4ed8',
    marginTop: '120px'
  },
  subtitle: {
    fontSize: '1.25rem',
    color: '#6B7280',
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  tabGroup: {
    borderRadius: '9999px',
    position: 'relative',
    display: 'inline-flex',
    padding: '4px',
    backgroundColor: 'white',
  },
  tabWrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  tab: {
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '12px 40px',
    borderRadius: '9999px',
    border: 'solid #E5E7EB',
    cursor: 'pointer',
    fontSize: '0.875rem',
    fontWeight: '500',
    backgroundColor: 'transparent',
    color: '#374151',
    transition: 'all 0.2s',
    position: 'relative',
    zIndex: 1,
  },
  activeTab: {
    color: '#1c4ed8',
    border: 'solid #1c4ed8',
    backgroundColor: 'white',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
  },
  popularBadge: {
    position: 'absolute',
    top: '-2.5px',
    right: '-45px',
    padding: '4px 12px',
    backgroundColor: '#1c4ed8',
    color: '#FFFFFF',
    borderRadius: '9999px',
    fontSize: '0.75rem',
    fontWeight: '600',
    zIndex: 2,
  },
  cardsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '50px',
    padding: '20px 0px',
  },
  card: {
    position: 'relative',
    padding: '24px',
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
    border: '2px solid #E5E7EB',
    transition: 'all 0.3s ease',
  },
  cardHover: {
    border: '2px solid #1c4ed8',
    transform: 'translateY(-4px)',
    boxShadow: '0 12px 20px -8px rgba(28, 78, 216, 0.2)',
  },
  cardTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '8px',
    color: '#111827',
  },
  cardDescription: {
    color: '#6B7280',
  },
  price: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#111827',
    marginBottom: '20px',
  },
  priceMonth: {
    fontSize: '1.25rem',
    color: '#6B7280',
  },
  priceDetail: {
    fontSize: '0.875rem',
    color: '#6B7280',
    marginTop: '4px',
  },
  featuresList: {
    listStyle: 'none',
    padding: 0,
    margin: '12px 0',
  },
  featureItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '12px',
    color: '#374151',
  },
  button: {
    width: '100%',
    padding: '12px 24px',
    borderRadius: '8px',
    fontWeight: '500',
    cursor: 'pointer',
    transition: 'all 0.2s',
    backgroundColor: '#FFFFFF',
    color: '#1c4ed8',
    border: '2px solid #1c4ed8',
  },
  buttonHover: {
    backgroundColor: '#1c4ed8',
    color: '#FFFFFF',
    transform: 'translateY(-1px)',
    boxShadow: '0 4px 6px -1px rgba(0,0,0,0.1)',
  },
  footer: {
    textAlign: 'center',
    color: '#6B7280',
  },
  footerLink: {
    color: '#1c4ed8',
    textDecoration: 'none',
    fontWeight: '500',
    cursor: 'pointer',
  },
  savingsBadge: {
    position: 'absolute',
    top: '-10px',
    right: '73%',
    transform: 'translateX(50%)',
    padding: '4px 12px',
    backgroundColor: '#059669',
    color: '#FFFFFF',
    borderRadius: '9999px',
    fontSize: '0.75rem',
    fontWeight: '600',
    zIndex: 2,
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  freeTrialBadge: {
    position: 'absolute',
    top: '-10px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    padding: '6px 16px',
    background: 'linear-gradient(90deg, #2563eb, #1c4ed8)',
    color: '#FFFFFF',
    borderRadius: '12px',
    fontSize: '0.85rem',
    fontWeight: '600',
    textTransform: 'None',
    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
    zIndex: 2,
  },
  priceSection: {
    position: 'relative',
    marginBottom: '20px',
  },
  originalPrice: {
    textDecoration: 'line-through',
    color: '#6B7280',
    fontSize: '1rem',
    marginBottom: '4px',
  },
};

const PricingCard = ({ 
  title, 
  price, 
  description, 
  features,
  billingType,
  priceId,
  currency
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isCardHovered, setIsCardHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const axios = useAxios();
  const { t, i18n } = useTranslation();

  const url = useMemo(() => {
    return internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';
  }, [internalUrl]);

  const getCheckoutUrl = async (id) => {
    const locale = i18n.language === 'en' ? 'en' : 'pl';
    return axios.post(`${url}/api/checkout/checkout_redirect/${id}/?locale=${locale}`);
  };

  const handleCheckout = async (id) => {
    setLoading(true);
    try {
      // First, check if the user already has an active (or trial) subscription.
      const membershipResponse = await axios.get(`${url}/api/subscription/membership/`);
      const subscriptionStatus = membershipResponse.data?.subscription?.status;
      if (subscriptionStatus === 'active' || subscriptionStatus === 'trial') {
        const customer_stripe_id = membershipResponse.data?.subscription?.customer?.customer_stripe_id;
        if (customer_stripe_id) {
          const locale = i18n.language === 'en' ? 'en' : 'pl';
          // Redirect to customer portal (prevent multiple subscriptions)
          const portalResponse = await axios.post(`${url}/api/checkout/create_portal_session/${customer_stripe_id}/?locale=${locale}`);
          if (portalResponse.data && portalResponse.data.url) {
            window.location.href = portalResponse.data.url;
            return; // Stop further execution since we already redirected
          } else {
            throw new Error('No customer portal URL provided');
          }
        }
      }

      // If no active subscription exists, continue with the checkout to create a new subscription.
      const response = await getCheckoutUrl(id);
      window.location.href = response.data.url;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to the login page if unauthorized
        window.location.href = `/login?next=/pricing`;
      } else {
        console.error("Error starting checkout:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const yearlyDiscount = 0.2; // 20% discount
  const originalYearlyPrice = parseFloat(price);
  const discountAmount = Math.round(originalYearlyPrice * yearlyDiscount);
  const advertisedPrice = (originalYearlyPrice + discountAmount).toFixed(2);
  const currency_sign = currency === 'USD' ? '$' : currency === 'EUR' ? '€' : currency === 'PLN' ? 'zł' : currency === 'GBP' ? '£' : currency;

  return (
    <div 
      style={{
        ...styles.card,
        ...(isCardHovered && styles.cardHover)
      }}
      onMouseEnter={() => setIsCardHovered(true)}
      onMouseLeave={() => setIsCardHovered(false)}
    >
      <h3 style={styles.cardTitle}>{t(title)}</h3>
      <p style={styles.cardDescription}>{t(description)}</p>
      {billingType === "monthly" && title.toLowerCase() === "enterprise" && (
        <div style={styles.freeTrialBadge} className="badgePulse">
          {t("30 Days Free Trial")}
        </div>
      )}
      <div style={styles.priceSection}>
        {billingType === "yearly" && (
          <div style={styles.savingsBadge}>
            {t("Save")} ${discountAmount}
          </div>
        )}
        {billingType === "yearly" && (
          <div style={styles.originalPrice}>
            ${advertisedPrice}
          </div>
        )}
        <div style={styles.price}>
          {billingType === "yearly" 
            ? currency === 'PLN' 
              ? `${originalYearlyPrice} ${currency_sign}`
              : `${currency_sign}${originalYearlyPrice}`
            : currency === 'PLN'
              ? `${price} ${currency_sign}`
              : `${currency_sign}${price}`
          }
          <span style={styles.priceMonth}>
            {billingType === "monthly" ? ' /' + t("month") : ' /' + t("year")}
          </span>
          <span style={styles.priceMonth}>
            {title.toLowerCase() === "enterprise" ? '' : '/' + t("business")}
          </span>
        </div>
      </div>
      <ul style={styles.featuresList}>
        {features.map((feature, index) => (
          <li key={index} style={styles.featureItem}>
            <Check size={20} color="#1c4ed8" />
            {t(feature.trim())}
          </li>
        ))}
      </ul>
      <button 
        style={{
          ...styles.button,
          ...isHovered && styles.buttonHover
        }}
        onClick={() => handleCheckout(priceId)}
        disabled={loading}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {loading ? t("Redirecting...") : t("Get Started")}
      </button>
    </div>
  );
};

const CACHE_KEY = 'pricingCache';
const CACHE_DURATION = 3600000 * 24 * 7; // 1 hour in milliseconds

export const PricingView = () => {
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [billingType, setBillingType] = useState("monthly");
  const [pricingPlans, setPricingPlans] = useState([]);
  const url = useMemo(() => (internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000'), [internalUrl]);

  const fetchPricingData = async () => {
    try {
      const response = await axios.get(`${url}/api/subscription/pricing/`);
      const data = response.data.map(plan => {
        const monthlyPlan = plan.prices.find(p => p.interval === "month");
        const yearlyPlan = plan.prices.find(p => p.interval === "year");

        return {
          title: plan.name,
          description: plan.description,
          features: plan.benefits.split('\n'),
          monthlyPrice: monthlyPlan?.price || 0,
          monthlyPriceId: monthlyPlan?.id || null,
          yearlyPrice: yearlyPlan?.price || 0,
          yearlyPriceId: yearlyPlan?.id || null,
          currency: plan.prices[0].currency.toUpperCase() || 'USD',
        };
      });

      setPricingPlans(data);
      localStorage.setItem(CACHE_KEY, JSON.stringify({ data, timestamp: Date.now() }));
    } catch (error) {
      console.error("Error fetching pricing data:", error);
    }
  };

  useEffect(() => {
    // Load cached data immediately if available and not expired
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);

      if (Date.now() - timestamp < CACHE_DURATION) {
        setPricingPlans(data);
      }
    }

    // Fetch fresh data from API and update state
    fetchPricingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={styles.container}>
      <Navbar sideBarOpen={true} />
      <header style={styles.header}>
        <h1 style={styles.title}>{t("Automate, Analyze, and Unlock Your Invoice Insights")}</h1>
        <p style={styles.subtitle}>{t("Choose the perfect plan for your business needs")}</p>
      </header>

      <div style={styles.tabContainer}>
        <div style={styles.tabGroup}>
          <button 
            style={{
              ...styles.tab,
              ...(billingType === "monthly" && styles.activeTab)
            }}
            onClick={() => setBillingType("monthly")}
          >
            {t("Monthly")}
          </button>
          <div style={styles.tabWrapper}>
            <button 
              style={{
                ...styles.tab,
                ...(billingType === "yearly" && styles.activeTab)
              }}
              onClick={() => setBillingType("yearly")}
            >
              {t("Yearly")}
            </button>
            <div style={styles.popularBadge}>{t("Most Popular")}</div>
          </div>
        </div>
      </div>

      <div style={styles.cardsContainer}>
        {pricingPlans.map((plan, index) => (
          <PricingCard
            key={index}
            {...plan}
            price={billingType === "monthly" ? plan.monthlyPrice : plan.yearlyPrice}
            priceId={billingType === "monthly" ? plan.monthlyPriceId : plan.yearlyPriceId}
            billingType={billingType}
            currency={plan.currency}
          />
        ))}
      </div>

      <div style={styles.footer}>
        <p>
          {t("Need help choosing?")}{' '}
          <span 
            style={styles.footerLink} 
            onClick={() => window.location.href = '/contact'}
          >
            {t("Contact our sales team")}
          </span>
        </p>
      </div>
    </div>
  );
};