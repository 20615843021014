import * as React from 'react';
import { useContext } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MuiCard from '@mui/material/Card';
import { InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TermsAndConditionsDialog } from './TermsAndConditionsDialog';
import AuthContext from '../../utils/AuthContext';
import { useTranslation } from 'react-i18next';
import { ArrowRight, ArrowLeft } from 'lucide-react';

const Card = styled(MuiCard)(({ theme }) => ({
  borderColor: 'transparent',
  display: 'flex',
  backgroundColor: 'transparent',
  overflow: 'auto',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  height: '100%',
  maxHeight: '800px',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
}));


export default function SignUp() {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const [next, setNext] = React.useState(false);
  const [first_name, setFirstName] = React.useState('');
  const [last_name, setLastName] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [companyAddress, setCompanyAddress] = React.useState('');
  const [industry, setIndustry] = React.useState('');
  const [referral, setReferral] = React.useState('');

  const [firstNameError, setFirstNameError] = React.useState(false);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = React.useState('');

  const [lastNameError, setLastNameError] = React.useState(false);
  const [lastNameErrorMessage, setLastNameErrorMessage] = React.useState('');

  const [usernameError, setUsernameError] = React.useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = React.useState('');

  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');

  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');

  const [companyError, setCompanyError] = React.useState(false);
  const [companyErrorMessage, setCompanyErrorMessage] = React.useState('');

  const [companyAddressError, setCompanyAddressError] = React.useState(false);
  const [companyAddressErrorMessage, setCompanyAddressErrorMessage] = React.useState('');

  const [registerError, setRegisterError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const [isChecked, setIsChecked] = React.useState(false);
  const [checkboxError, setCheckboxError] = React.useState(false);
  const [checkboxErrorMessage, setCheckboxErrorMessage] = React.useState(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const { registerUser } = useContext(AuthContext);


  const validatePersonalInputs = () => {

    let isValid = true;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage(t('Please enter a valid email address.'));
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password || password.length < 5) {
      setPasswordError(true);
      setPasswordErrorMessage(t('Password must be at least 6 characters long.'));
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    if (!first_name || first_name.length < 1 ) {
      setFirstNameError(true);
      setFirstNameErrorMessage(t('First Name is required.'));
      isValid = false;
    } else {
      setFirstNameError(false);
      setFirstNameErrorMessage('');
    }
    
    if ( !last_name || last_name.length < 1 ) {
      setLastNameError(true);
      setLastNameErrorMessage(t('Last Name is required.'));
      isValid = false;
    } else {
      setLastNameError(false);
      setLastNameErrorMessage('');
    }
    
    if ( !username || username.length < 4) {
      setUsernameError(true);
      setUsernameErrorMessage(t('Username must be at least 4 characters long.'));
      isValid = false;
    } else {
      setUsernameError(false);
      setUsernameErrorMessage('');
    }

    return isValid;
  };

  const validateCompanyInputs = () => {

    let isValid = true;

    if ( !company || company.length < 1) {
      setCompanyError(true);
      setCompanyErrorMessage(t('Company name is required.'));
      isValid = false;
    } else {
      setCompanyError(false);
      setCompanyErrorMessage('');
    }

    if ( !companyAddress || companyAddress.length < 1) {
      setCompanyAddressError(true);
      setCompanyAddressErrorMessage(t('Company address is required.'));
      isValid = false;
    } else {
      setCompanyAddressError(false);
      setCompanyAddressErrorMessage(t(''));
    }

    if (!isChecked) {
      setCheckboxError(true);
      setCheckboxErrorMessage(t('You must agree to proceed.'));
      isValid = false;
    } else {
      setCheckboxError(false);
      setCheckboxErrorMessage('');
    }

    return isValid;
  };

  const handleSubmit = async(event) => {
    event.preventDefault();

    // Validate inputs
    if (!validateCompanyInputs()) {
      return;
    }
    setLoading(true);
    await registerUser(username, 
                        password, 
                        first_name, 
                        last_name, 
                        email, 
                        company, 
                        companyAddress,
                        industry,
                        referral,
                        setRegisterError);
    setLoading(false);
  };

  const handleNext = async(event) => {
    event.preventDefault();

    // Validate inputs
    if (!validatePersonalInputs()) {
      return;
    }
    setNext(!next)
  }

  const handleDialogOpen = () => {
    setOpenDialog(!openDialog)
  }

  return (
    <Card variant="outlined">
        <Typography
        component="h1"
        fontStyle="bold"
        variant="h6"
        sx={{ width: '100%', fontSize: 'clamp(3rem, 20vw, 2.15rem)' }}
        >
        {t("Register")}
        </Typography>
        { next ? (
          <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
          >
            <FormControl fullWidth error={companyError}>
              <TextField
              autoComplete="company"
              name="company"
              required
              fullWidth
              id="company"
              type="text"
              placeholder={t("Company Name")}
              autoFocus
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              helperText={companyError ? companyErrorMessage : ''}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '50px',
                  borderColor: 'black',
                  border: 1,
                  padding: '0 20px', // Add padding to create space for the label
                },
              }}
              />
            </FormControl> 
            <FormControl fullWidth error={companyAddressError}>
              <TextField
              autoComplete="company address"
              name="company address"
              required
              fullWidth
              id="company_address"
              type="text"
              placeholder={t("Company Address")}
              autoFocus
              value={companyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
              helperText={companyAddressError ? companyAddressErrorMessage : ''}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '50px',
                  borderColor: 'black',
                  border: 1,
                  padding: '0 20px', // Add padding to create space for the label
                },
              }}
              />
            </FormControl> 

            <FormControl
              fullWidth
              sx={{
                position: 'relative', // Ensure relative positioning for label and fieldset adjustments
              }}
            >
              <InputLabel
                id="industry-label"
                sx={{
                  backgroundColor: 'white', // Background to cover the border
                  padding: '0 4px', // Add padding to create space for the label
                  left: '16px', // Position label inside the border
                  '&.Mui-focused': {
                    backgroundColor: 'white',
                    padding: '0 4px',
                  },
                }}
              >
                {t("Your Industry")}
              </InputLabel>
              <Select
                labelId="industry-label"
                id="industry"
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
                sx={{
                  borderRadius: '50px', // Apply rounded corners
                  '& fieldset': {
                    borderRadius: '50px', // Ensure the fieldset has rounded corners
                    transition: 'all 0.3s ease-in-out',
                    '& legend': {
                      width: 0, // Hide legend by default
                    },
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#1976d2', // Border color on focus
                    '& legend': {
                      maxWidth: '100px', // Dynamically reveal the legend
                    },
                  },
                }}
              >
                <MenuItem value="advertising_marketing">{t("Advertising & Marketing")}</MenuItem>
                <MenuItem value="agriculture_food">{t("Agriculture & Food Production")}</MenuItem>
                <MenuItem value="arts_culture">{t("Arts & Culture")}</MenuItem>
                <MenuItem value="automotive">{t("Automotive")}</MenuItem>
                <MenuItem value="craftsmanship">{t("Craftsmanship")}</MenuItem>
                <MenuItem value="design">{t("Design")}</MenuItem>
                <MenuItem value="education">{t("Education")}</MenuItem>
                <MenuItem value="energy_utilities">{t("Energy & Utilities")}</MenuItem>
                <MenuItem value="entertainment_media">{t("Entertainment & Media")}</MenuItem>
                <MenuItem value="finance">{t("Finance")}</MenuItem>
                <MenuItem value="food_beverage">{t("Food & Beverage")}</MenuItem>
                <MenuItem value="healthcare">{t("Healthcare")}</MenuItem>
                <MenuItem value="home_lifestyle">{t("Home & Lifestyle")}</MenuItem>
                <MenuItem value="logistics_transportation">{t("Logistics & Transportation")}</MenuItem>
                <MenuItem value="luxury_retail">{t("Luxury Retail")}</MenuItem>
                <MenuItem value="manufacturing_industry">{t("Manufacturing & Industry")}</MenuItem>
                <MenuItem value="nonprofit_social">{t("Non-Profit & Social Impact")}</MenuItem>
                <MenuItem value="personal_services">{t("Personal Services")}</MenuItem>
                <MenuItem value="real_estate">{t("Real Estate")}</MenuItem>
                <MenuItem value="retail">{t("Retail")}</MenuItem>
                <MenuItem value="science_development">{t("Science & Development")}</MenuItem>
                <MenuItem value="security">{t("Security")}</MenuItem>
                <MenuItem value="sport_recreation">{t("Sport & Recreation")}</MenuItem>
                <MenuItem value="sustainability_environment">{t("Sustainability & Environment")}</MenuItem>
                <MenuItem value="technology">{t("Technology")}</MenuItem>
                <MenuItem value="telecommunications">{t("Telecommunications")}</MenuItem>
                <MenuItem value="tourism">{t("Tourism")}</MenuItem>
                <MenuItem value="uncategorized">{t("Uncategorized")}</MenuItem>
              </Select>
            </FormControl>

              <FormControl
                fullWidth
                sx={{
                  position: 'relative',
                }}
              >
                <InputLabel
                  id="referral-label"
                  sx={{
                    backgroundColor: 'white', // Background to cover the border
                    padding: '0 4px', // Add padding to create space for the label
                    left: '16px', // Position label inside the border
                    '&.Mui-focused': {
                      backgroundColor: 'white',
                      padding: '0 4px',
                    },
                  }}
                >
                  {t("How did you hear about us?")}
                </InputLabel>
                <Select
                  labelId="referral-label"
                  id="referral"
                  value={referral}
                  onChange={(e) => setReferral(e.target.value)}
                  sx={{
                    borderRadius: '50px', // Apply rounded corners
                    '& fieldset': {
                      borderRadius: '50px', // Ensure the fieldset has rounded corners
                      transition: 'all 0.3s ease-in-out',
                      '& legend': {
                        width: 0, // Hide legend by default
                      },
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1976d2', // Border color on focus
                      '& legend': {
                        maxWidth: '100px', // Dynamically reveal the legend
                      },
                    },
                  }}
                >
                  <MenuItem value="friend">{t("Friend")}</MenuItem>
                  <MenuItem value="social_media">{t("Social Media")}</MenuItem>
                  <MenuItem value="advertisement">{t("Advertisement")}</MenuItem>
                  <MenuItem value="search_engine">{t("Search Engine")}</MenuItem>
                  <MenuItem value="referral">{t("Referral")}</MenuItem>
                  <MenuItem value="referral">{t("Other")}</MenuItem>
                </Select>
              </FormControl>

              <FormControl required component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      value=""
                      color="primary"
                      checked={isChecked} // Bind the state to the checkbox
                      onClick={(e) => setIsChecked(e.target.checked)} // Update the state on change
                      sx={{
                        "&.Mui-checked": {
                          color: "#1c4ed8", // Checked color
                        },
                      }}
                    />
                  }
                  label={
                    <Typography onClick={handleDialogOpen} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                      I agree to the Terms and Conditions*
                    </Typography>
                  }
                  name="checkbox"
                />
                {checkboxError && <FormHelperText>{checkboxErrorMessage}</FormHelperText>}
                {openDialog && (
                  <TermsAndConditionsDialog 
                      open={openDialog} 
                      onClose={handleDialogOpen} 
                  />
                )}
              </FormControl>


            <Box
              sx={{ display: 'flex', flexDirection: 'row', gap: 8 }}
            >
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleNext}
                  startIcon={<ArrowLeft style={{size:"small"}}/>}
                  sx={{borderRadius: '50px', backgroundColor: 'white', borderColor: '#1c4ed8', color:'#1c4ed8'}}
              >
                {t('Go back')}
              </Button> 
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{borderRadius: '50px', backgroundColor: '#1c4ed8'}}
              >
                {loading ? <CircularProgress size={24} color="white" /> : t('Sign Up')}
              </Button> 
            </Box>
          </Box>
          ) : ( 
          <>
            <Box
            component="form"
            noValidate
            sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
            >
              <Box
                sx={{ display: 'flex', flexDirection: 'row', gap: 8 }}
              >
                <FormControl fullWidth error={firstNameError}>
                  <TextField
                  autoComplete="First Name"
                  name="first_name"
                  required
                  id="first_name"
                  type="text"
                  placeholder={t("First Name")}
                  autoFocus
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                  helperText={firstNameError ? firstNameErrorMessage : ''}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '50px',
                      borderColor: 'black',
                      border: 1
                    },
                  }}
                  />
                </FormControl>
                <FormControl fullWidth error={lastNameError}>
                  <TextField
                  autoComplete="Last Name"
                  name="last_name"
                  required
                  id="last_name"
                  type="text"
                  placeholder={t("Last Name")}
                  autoFocus
                  value={last_name}
                  onChange={(e) => setLastName(e.target.value)}
                  helperText={lastNameError ? lastNameErrorMessage : ''}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '50px',
                      borderColor: 'black',
                      border: 1
                    },
                  }}
                  />
                </FormControl>
              </Box>
              <FormControl fullWidth error={usernameError}>
                <TextField
                autoComplete="username"
                name="username"
                required
                fullWidth
                id="username"
                type="text"
                placeholder={t("Username")}
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                helperText={usernameError ? usernameErrorMessage : ''}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '50px',
                    borderColor: 'black',
                    border: 1
                  },
                }}
                />
              </FormControl>
              <FormControl fullWidth error={emailError}>
                <TextField
                required
                autoFocus
                id="email"
                type="text"
                placeholder={("Email")}
                name="email"
                autoComplete="email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                helperText={emailError ? emailErrorMessage : ''}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '50px',
                    borderColor: 'black',
                    border: 1
                  },
                }}
                />
              </FormControl>
              <FormControl fullWidth error={passwordError}>
                <TextField
                required
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="new-password"
                autoFocus
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                helperText={passwordError ? passwordErrorMessage : ''}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '50px',
                    borderColor: 'black',
                    border: 1
                  },
                }}
                />
              </FormControl>
              {registerError && <Typography color="error">{registerError}</Typography>} {/* Display register error */}
              <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" defaultChecked 
                    sx={{
                      "&.Mui-checked": {
                          color: "#1c4ed8", // Checked color
                      },
                  }}/>}
                  label={t("I want to receive updates via email.")}
                  name='checkbox'
              />
              <Button
                  onClick={handleNext}
                  fullWidth
                  variant="contained"
                  sx={{borderRadius: '50px', backgroundColor: '#1c4ed8'}}
                  endIcon={<ArrowRight style={{size:"small"}}/>}
              >
                {loading ? <CircularProgress size={24} color="white" /> : t('Next')}
              </Button>
            </Box>
            <Divider>{t("or")}</Divider>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography sx={{ textAlign: 'center' }}>
                  {t("Already have an account?")}{' '}
                  <span>
                  <Link
                      href="/login/"
                      variant="body2"
                      type="button"
                      sx={{ alignSelf: 'center' }}
                  >
                      {t("Sign in")}
                  </Link>
                  </span>
              </Typography>
            </Box>
          </>
        )}
    </Card>
  );
}