import * as React from 'react';
import { useContext } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { GoogleIcon, FacebookIcon } from './CustomIcons';
import AuthContext from '../../utils/AuthContext';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(3),
  gap: theme.spacing(2),
  margin: 'auto',
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));


export default function SignUp() {
  const [first_name, setFirstName] = React.useState('');
  const [last_name, setLastName] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [company, setCompany] = React.useState('');

  const [firstNameError, setFirstNameError] = React.useState(false);
  const [firstNameErrorMessage, setFirstNameErrorMessage] = React.useState('');

  const [lastNameError, setLastNameError] = React.useState(false);
  const [lastNameErrorMessage, setLastNameErrorMessage] = React.useState('');

  const [usernameError, setUsernameError] = React.useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = React.useState('');

  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');

  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');

  const [companyError, setCompanyError] = React.useState(false);
  const [companyErrorMessage, setCompanyErrorMessage] = React.useState('');

  const [registerError, setRegisterError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const { registerUser } = useContext(AuthContext);


  const validateInputs = () => {

    let isValid = true;

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    if (!password || password.length < 6) {
      setPasswordError(true);
      setPasswordErrorMessage('Password must be at least 6 characters long.');
      isValid = false;
    } else {
      setPasswordError(false);
      setPasswordErrorMessage('');
    }

    if (!first_name || first_name.length < 1 ) {
      setFirstNameError(true);
      setFirstNameErrorMessage('First Name is required.');
      isValid = false;
    } else {
      setFirstNameError(false);
      setFirstNameErrorMessage('');
    }
    
    if ( !last_name || last_name.length < 1 ) {
      setLastNameError(true);
      setLastNameErrorMessage('Last Name is required.');
      isValid = false;
    } else {
      setLastNameError(false);
      setLastNameErrorMessage('');
    }
    
    if ( !username || username.length < 1) {
      setUsernameError(true);
      setUsernameErrorMessage('Username is required.');
      isValid = false;
    } else {
      setUsernameError(false);
      setUsernameErrorMessage('');
    }

    if ( !company || company.length < 1) {
      setCompanyError(true);
      setCompanyErrorMessage('Organisation name is required.');
      isValid = false;
    } else {
      setCompanyError(false);
      setCompanyErrorMessage('');
    }

    return isValid;
  };

  const handleSubmit = async(event) => {
    event.preventDefault();

    // Validate inputs
    if (!validateInputs()) {
      return;
    }
    setLoading(true);
    await registerUser(username, password, first_name, last_name, email, company, setRegisterError);
    setLoading(false);
  };

  return (
    <Card variant="outlined">
        <Typography
        component="h1"
        variant="h4"
        sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
        >
        Register
        </Typography>
        <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'row', gap: 8 }}
          >
            <FormControl fullWidth error={firstNameError}>
              <FormLabel htmlFor="first_name">First Name</FormLabel>
              <TextField
              autoComplete="First Name"
              name="first_name"
              required
              id="first_name"
              type="text"
              placeholder="Jon"
              autoFocus
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
              helperText={firstNameError ? firstNameErrorMessage : ''}
              />
            </FormControl>
            <FormControl fullWidth error={lastNameError}>
              <FormLabel htmlFor="last_name">Last Name</FormLabel>
              <TextField
              autoComplete="Last Name"
              name="last_name"
              required
              id="last_name"
              type="text"
              placeholder="Snow"
              autoFocus
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
              helperText={lastNameError ? lastNameErrorMessage : ''}
              />
            </FormControl>
          </Box>
          <FormControl fullWidth error={usernameError}>
            <FormLabel htmlFor="username">Username</FormLabel>
            <TextField
            autoComplete="username"
            name="username"
            required
            fullWidth
            id="username"
            type="text"
            placeholder=""
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            helperText={usernameError ? usernameErrorMessage : ''}
            />
            </FormControl>
          <FormControl fullWidth error={usernameError}>
            <FormLabel htmlFor="company">Company</FormLabel>
            <TextField
            autoComplete="company"
            name="company"
            required
            fullWidth
            id="company"
            type="text"
            placeholder="Organization / Company Name"
            autoFocus
            value={username}
            onChange={(e) => setCompany(e.target.value)}
            helperText={companyError ? companyErrorMessage : ''}
            />
          </FormControl>
          <FormControl fullWidth error={emailError}>
            <FormLabel htmlFor="email">Email</FormLabel>
            <TextField
            required
            autoFocus
            id="email"
            type="text"
            placeholder="your@email.com"
            name="email"
            autoComplete="email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            helperText={emailError ? emailErrorMessage : ''}
            />
          </FormControl>
          <FormControl fullWidth error={passwordError}>
            <FormLabel htmlFor="password">Password</FormLabel>
            <TextField
            required
            name="password"
            placeholder="••••••"
            type="password"
            id="password"
            autoComplete="new-password"
            autoFocus
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            helperText={passwordError ? passwordErrorMessage : ''}
            />
          </FormControl>
          {registerError && <Typography color="error">{registerError}</Typography>} {/* Display register error */}
          <FormControlLabel
              control={<Checkbox value="allowExtraEmails" color="primary" />}
              label="I want to receive updates via email."
              name='checkbox'
          />
          <Button
              type="submit"
              fullWidth
              variant="contained"
          >
            {loading ? <CircularProgress size={24} color="white" /> : 'Sign up'}
          </Button>
          <Typography sx={{ textAlign: 'center' }}>
              Already have an account?{' '}
              <span>
              <Link
                  href="/login/"
                  variant="body2"
                  type="button"
                  sx={{ alignSelf: 'center' }}
              >
                  Sign in
              </Link>
              </span>
          </Typography>
        </Box>
        <Divider>
        <Typography sx={{ color: 'text.secondary' }}>or</Typography>
        </Divider>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Button
            fullWidth
            variant="outlined"
            onClick={() => alert('Sign up with Google')}
            startIcon={<GoogleIcon />}
        >
            Sign up with Google
        </Button>
        <Button
            fullWidth
            variant="outlined"
            onClick={() => alert('Sign up with Facebook')}
            startIcon={<FacebookIcon />}
        >
            Sign up with Facebook
        </Button>
        </Box>
    </Card>
  );
}