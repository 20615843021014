import React from 'react';
import { useContext } from "react";
import { Box, Button, Typography, Grid } from '@mui/material';
import { ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import AuthContext from "../../utils/AuthContext";

export const HeroSection = () => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    
    const handleSignIn = () => {
        if (user) {
            navigate('/dashboard');
        } else {
            navigate('/login'); 
        }
    };

    return (
        <Grid container spacing={5} alignItems="center" sx={{ pt:15 }}>
            <Grid item xs={12} md={6}>
            <motion.div initial={{ opacity: 0, x: -50 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }}>
                <Typography variant="h2" fontWeight="700" color="#004080" gutterBottom>
                Revolutionize Invoice Processing
                </Typography>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                Automate, analyze, and unlock insights with AI-powered technology.
                </Typography>
                <Button
                variant="contained"
                className="button"
                color="primary"
                size="large"
                endIcon={<ArrowRight />}
                onClick={handleSignIn}
                sx={{ mt: 4, px: 4, py: 1.5, fontSize: '1.1rem', borderRadius: '50px' }}
                >
                See It In Action
                </Button>
            </motion.div>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
            <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, delay: 0.2 }}>
                <Box
                component="img"
                src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/girl-shopping-list.svg"
                alt="Shopping Illustration"
                sx={{ width: '100%' }}
                />
            </motion.div>
            </Grid>
        </Grid>
    );
};
