// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%; */
}

.home .button {
  width: 300px;
  background-color: #1c4ed8;
  border-color: #1c4ed8;
  color: white;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-30%);
  }
}

.carousel-container {
  display: flex;
  overflow: hidden;
}

.carousel-content {
  display: flex;
  gap: 7rem;
  animation: scroll 10s linear infinite;
}

/* Ensures smooth animation when reducing screen size */
@media (max-width: 900px) {
  .carousel-content {
    gap: 4rem;
  }
}

`, "",{"version":3,"sources":["webpack://./src/pages/Home/style.css"],"names":[],"mappings":"AAAA;EACE;;;gBAGc;AAChB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,qCAAqC;AACvC;;AAEA,uDAAuD;AACvD;EACE;IACE,SAAS;EACX;AACF","sourcesContent":[".home {\n  /* display: flex;\n  flex-direction: row;\n  justify-content: center;\n  width: 100%; */\n}\n\n.home .button {\n  width: 300px;\n  background-color: #1c4ed8;\n  border-color: #1c4ed8;\n  color: white;\n}\n\n@keyframes scroll {\n  0% {\n    transform: translateX(0);\n  }\n  100% {\n    transform: translateX(-30%);\n  }\n}\n\n.carousel-container {\n  display: flex;\n  overflow: hidden;\n}\n\n.carousel-content {\n  display: flex;\n  gap: 7rem;\n  animation: scroll 10s linear infinite;\n}\n\n/* Ensures smooth animation when reducing screen size */\n@media (max-width: 900px) {\n  .carousel-content {\n    gap: 4rem;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
