import * as React from 'react';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import Verify from './VerifyForm';
import Content from './Content';
import { Navbar } from "../../components/Navbar/Navbar";
import { Divider } from '@mui/material';
import { alpha } from '@mui/material/styles';

export default function SignInSide() {
  return (
    <Box>
      <Navbar sideBarOpen={true} />
      <Stack
        direction="column"
        component="main"
        sx={[
          {
            justifyContent: 'center',
            height: 'calc((1 - var(--template-frame-height, 0)) * 100%)',
            marginTop: 'max(40px - var(--template-frame-height, 0px), 0px)',
            minHeight: '100%',
          },
          (theme) => ({
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              inset: 0,
              backgroundImage:
                'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
              backgroundRepeat: 'no-repeat',
            },
          }),
        ]}
      >
        <Stack
          direction={{ xs: 'column-reverse', md: 'row' }}
          sx={{
            justifyContent: 'center',
            gap: { xs: 6, sm: 12 },
            mx: 'auto',
            backdropFilter: "blur(75px)", // Optional blur effect
          }}
        >
          <Stack
            direction={{ xs: 'column-reverse', md: 'row' }}
            sx={{
              justifyContent: 'center',
              gap: { xs: 6, sm: 12 },
              p: { xs: 2, sm: 4 },
              m: 'auto',
              mt: 10
            }}
          >
            <Content />
            <Verify />
          </Stack>
        </Stack>
        <Divider sx={{mx:18, borderColor: alpha("#1c4ed8", 0.5)}}/>
      </Stack>
    </Box>
  );
}