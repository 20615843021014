import React, { useState } from "react";
import {
  Box,
  Stack
} from "@mui/material";

import { Navbar } from "../../components/Navbar/Navbar";
import SideBar from "../../components/SideBar/SideBar";
import Analytics from "./Analytics";


export const AnalyticsView = () => {
  const [openSideBar, setOpenSideBar] = useState(true);
  const handleDrawerToggle = () => {
    setOpenSideBar(!openSideBar);
  };

  const defaultCurrency = localStorage.getItem("defaultCurrency");
  // Map of currency codes to their symbols
  const currencySymbols = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    PLN: 'zł',
    TRY: '₺',
  };
  // Get currency symbol from map, fallback to currency code if not found
  const currency = currencySymbols[defaultCurrency] || '$';

  return (
    <Box width="100%" bgcolor="white" >
      <SideBar open={openSideBar} handleDrawerToggle={handleDrawerToggle} />
      <Navbar sideBarOpen={openSideBar} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            ml: 50,
            mx: 3,
            pb: 5,
            mt: { xs: 8, md: 15 },
          }}
        >
          <Box sx={{ width: openSideBar ? '90%' : '70%', maxWidth: { sm: '100%', md: '1700px'} }}>
            <Analytics currency={currency} />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};