import React, { useState, useCallback, memo } from 'react';
import { 
    Button, 
    TextField, 
    Grid, 
    Box, 
    Typography, 
    Divider,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { UploadTemplateFrame } from "../../components/UploadFrame/UploadTemplateFrame";
import {AddLink as AddIcon} from '@mui/icons-material';
import { Trash2, Edit2, Plus, ChevronDownIcon as ChevronDown } from 'lucide-react';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import CloseIcon from "@mui/icons-material/Close";
import moment from 'moment';

// Memoized TextField component
const MemoizedTextField = memo(({ value, onChange, ...props }) => (
  <TextField
    value={value}
    onChange={onChange}
    {...props}
  />
));

// Memoized DatePicker component
const MemoizedDatePicker = memo(({ value, onChange, ...props }) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <DatePicker
      value={value}
      onChange={onChange}
      {...props}
    />
  </LocalizationProvider>
));

// Memoized AddressFields component
const AddressFields = memo(({ prefix, title, address, onChange }) => (
  <>
    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
      {title}
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <MemoizedTextField
          name={`${prefix}.houseNumber`}
          label="House Number"
          variant="outlined"
          value={address.houseNumber}
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MemoizedTextField
          name={`${prefix}.road`}
          label="Road"
          variant="outlined"
          value={address.road}
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MemoizedTextField
          name={`${prefix}.city`}
          label="City"
          variant="outlined"
          value={address.city}
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MemoizedTextField
          name={`${prefix}.state`}
          label="State"
          variant="outlined"
          value={address.state}
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MemoizedTextField
          name={`${prefix}.postalCode`}
          label="Postal Code"
          variant="outlined"
          value={address.postalCode}
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MemoizedTextField
          name={`${prefix}.streetAddress`}
          label="Street Address"
          variant="outlined"
          value={address.streetAddress}
          onChange={onChange}
          fullWidth
        />
      </Grid>
    </Grid>
    <Divider sx={{ my: 3 }} />
  </>
));

// Memoized ReadOnlyAddressFields component
const ReadOnlyAddressFields = memo(({ address }) => (
  <Grid container spacing={3}>
    {Object.entries(address).map(([field, value]) => (
      <Grid item xs={12} sm={6} key={field}>
        <TextField
          label={field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}
          value={value}
          fullWidth
          disabled
          sx={{ "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: "rgba(0, 0, 0, 0.4)" } }}
        />
      </Grid>
    ))}
  </Grid>
));

// Memoized OptionalAddressFields component
const OptionalAddressFields = memo(({ prefix, title, address, onChange }) => (
  <Accordion>
    <AccordionSummary
      expandIcon={<ChevronDown />}
      aria-controls={`${prefix}-content`}
      id={`${prefix}-header`}
      sx={{ marginBottom: '5px' }}
    >
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        <Plus size={18} style={{ marginRight: '8px' }} />
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <MemoizedTextField
            name={`${prefix}.houseNumber`}
            label="House Number"
            variant="outlined"
            value={address.houseNumber}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MemoizedTextField
            name={`${prefix}.road`}
            label="Road"
            variant="outlined"
            value={address.road}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MemoizedTextField
            name={`${prefix}.city`}
            label="City"
            variant="outlined"
            value={address.city}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MemoizedTextField
            name={`${prefix}.state`}
            label="State"
            variant="outlined"
            value={address.state}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MemoizedTextField
            name={`${prefix}.postalCode`}
            label="Postal Code"
            variant="outlined"
            value={address.postalCode}
            onChange={onChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MemoizedTextField
            name={`${prefix}.streetAddress`}
            label="Street Address"
            variant="outlined"
            value={address.streetAddress}
            onChange={onChange}
            fullWidth
          />
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>
));

// Memoized ItemsSection component
const ItemsSection = memo(({ items, newItem, onItemChange, onItemDateChange, onAddItem, onDeleteItem, onEditItem, onUpdateItem, editingItem }) => (
  <>
    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
      Line Items
    </Typography>
    
    <Paper sx={{ mb: 2 }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Product Code</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Unit Price</TableCell>
              <TableCell>Date</TableCell>
              <TableCell align="right">Tax</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.itemDescription}</TableCell>
                <TableCell>{item.productCode}</TableCell>
                <TableCell align="right">{item.itemQuantity}</TableCell>
                <TableCell align="right">${parseFloat(item.unitPrice).toFixed(2)}</TableCell>
                <TableCell>{moment(item.itemDate).format('MM/DD/YYYY')}</TableCell>
                <TableCell align="right">${parseFloat(item.tax).toFixed(2)}</TableCell>
                <TableCell align="right">${parseFloat(item.amount).toFixed(2)}</TableCell>
                <TableCell>
                  <IconButton onClick={() => onEditItem(item)} size="small">
                    <Edit2 size={16} />
                  </IconButton>
                  <IconButton onClick={() => onDeleteItem(item.id)} size="small" color="error">
                    <Trash2 size={16} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>

    <Paper sx={{ p: 2, mb: 3 }}>
      <Typography variant="subtitle1" gutterBottom>
        {editingItem ? 'Edit Item' : 'Add New Item'}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <MemoizedTextField
            name="itemDescription"
            label="Description"
            variant="outlined"
            value={newItem.itemDescription}
            onChange={onItemChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <MemoizedTextField
            name="productCode"
            label="Product Code"
            value={newItem.productCode}
            onChange={onItemChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <MemoizedTextField
            name="itemQuantity"
            label="Quantity"
            type="number"
            value={newItem.itemQuantity}
            onChange={onItemChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <MemoizedTextField
            name="unitPrice"
            label="Unit Price"
            type="number"
            value={newItem.unitPrice}
            onChange={onItemChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <MemoizedDatePicker
            label="Item Date"
            value={newItem.itemDate}
            onChange={onItemDateChange}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={1}>
          <MemoizedTextField
            name="tax"
            label="Tax"
            type="number"
            value={newItem.tax}
            onChange={onItemChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={editingItem ? onUpdateItem : onAddItem}
            sx={{
              height: '100%',
              pl: 2,
              pr: 2,
              color: "white",
              backgroundColor: "#1c4ed8",
              borderRadius: "50px",
              "&:hover": { backgroundColor: "#163bb7" },
            }}
          >
            {editingItem ? 'Update' : 'Add'}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  </>
));

export const AddFrame = ({ onClose }) => {
  const [showUploadFrame, setShowUploadFrame] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [sameAsCustomer, setSameAsCustomer] = useState(false);
  const [newItem, setNewItem] = useState({
    itemDescription: '',
    itemQuantity: '',
    unit: '',
    unitPrice: '',
    productCode: '',
    itemDate: moment(),
    tax: '',
    amount: '',
  });

  const [formData, setFormData] = useState({
    invoiceIdNumber: '',
    customerName: '',
    customerId: '',
    invoiceDate: moment(),
    subtotal: '',
    vendorName: '',
    invoiceTotal: '',
    dueDate: moment(),
    purchaseOrder: '',
    totalTax: '',
    previousUnpaidBalance: '',
    amountDue: '',
    serviceStartDate: moment(),
    serviceEndDate: moment(),
    items: [],
    customerAddress: {
      houseNumber: '',
      road: '',
      city: '',
      state: '',
      postalCode: '',
      streetAddress: '',
    },
    billingAddress: {
      houseNumber: '',
      road: '',
      city: '',
      state: '',
      postalCode: '',
      streetAddress: '',
    },
    vendorAddress: {
      houseNumber: '',
      road: '',
      city: '',
      state: '',
      postalCode: '',
      streetAddress: '',
    },
    shippingAddress: {
      houseNumber: '',
      road: '',
      city: '',
      state: '',
      postalCode: '',
      streetAddress: '',
    },
    serviceAddress: {
      houseNumber: '',
      road: '',
      city: '',
      state: '',
      postalCode: '',
      streetAddress: '',
    },
    remittanceAddress: {
      houseNumber: '',
      road: '',
      city: '',
      state: '',
      postalCode: '',
      streetAddress: '',
    },
  });

  const handleUploadClick = useCallback(() => setShowUploadFrame(true), []);

  const handleSubmit = () => {
    console.log(formData);
  }

  const handleSameAsCustomerChange = useCallback((event) => {
    setSameAsCustomer(event.target.checked);
    if (event.target.checked) {
      setFormData(prev => ({
        ...prev,
        billingAddress: { ...prev.customerAddress }
      }));
    }
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    const nameParts = name.split('.');

    setFormData((prevFormData) => {
      if (nameParts.length === 2) {
        const [parent, child] = nameParts;
        return {
          ...prevFormData,
          [parent]: {
            ...prevFormData[parent],
            [child]: value,
          },
        };
      }
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }, []);

  const handleDateChange = useCallback((name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: moment(value),
    }));
  }, []);


  const calculateAmount = useCallback((quantity, unitPrice) => {
    return parseFloat(quantity) * parseFloat(unitPrice);
  }, []);

  const handleItemChange = useCallback((e) => {
    const { name, value } = e.target;
    const parsedValue = name === 'itemQuantity' || name === 'unitPrice' ? parseFloat(value) : value;
  
    setNewItem(prev => {
      const updated = { ...prev, [name]: parsedValue };
      if (name === 'itemQuantity' || name === 'unitPrice') {
        const itemQuantity = name === 'itemQuantity' ? parsedValue : prev.itemQuantity;
        const unitPrice = name === 'unitPrice' ? parsedValue : prev.unitPrice;
        updated.amount = calculateAmount(itemQuantity, unitPrice);
      }
      return updated;
    });
  }, [calculateAmount]);

  const handleItemDateChange = useCallback((value) => {
    setNewItem(prev => ({
      ...prev,
      itemDate: moment(value),
    }));
  }, []);

  const handleAddItem = () => {
    const itemToAdd = {
      ...newItem,
      id: formData.items.length + 1,
    };

    setFormData(prev => ({
      ...prev,
      items: [...prev.items, itemToAdd],
    }));

    setNewItem({
      itemDescription: '',
      itemQuantity: 0,
      unit: '',
      unitPrice: 0,
      productCode: '',
      itemDate: moment(),
      tax: 0,
      amount: 0,
    });
  };

  const handleDeleteItem = (id) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.filter(item => item.id !== id),
    }));
  };

  const handleEditItem = (item) => {
    setEditingItem(item.id);
    setNewItem(item);
  };

  const handleUpdateItem = () => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.map(item => 
        item.id === editingItem ? { ...newItem, id: item.id } : item
      ),
    }));
    setEditingItem(null);
    setNewItem({
      itemDescription: '',
      itemQuantity: 0,
      unitPrice: 0,
      productCode: '',
      itemDate: moment(),
      tax: 0,
      amount: 0,
    });
  };

  const handleClose = () => {
    onClose([]);
  };

  const handleCloseUploadFrame = useCallback(async (invoices) => {
    // Only hide the upload frame after the invoice navigation is completed
    setShowUploadFrame(false);

}, []);


  return (
    <>
      {/* Backdrop to darken the background */}
      <div className="upload-backdrop" >
        <div className="loading-overlay">
          {showUploadFrame && <UploadTemplateFrame onClose={handleCloseUploadFrame} />}
            {/* Close Button */}
            <IconButton
                size="medium"
                sx={{
                position: "absolute",
                top: 18,
                right: 18,
                border: 1,
                borderColor: "#1c4ed8",
                color: "#1c4ed8",
                backgroundColor: "white",
                "&:hover": { backgroundColor: "#f0f4ff" },
                }}
                onClick={handleClose}
            >
                <CloseIcon />
            </IconButton>
            <Box sx={{ p: 4 }}>
              <Box alignItems="center" sx={{ 
                    display: "flex", 
                    justifyContent: "space-between", 
                    mt: 4,
                    mb: 2,
                    }}
                >
                <Typography variant="h4" gutterBottom>
                  Create Invoice
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleUploadClick}
                  sx={{ mr: 1, ml: -25, borderRadius: '10px', color: '#1c4ed8', 
                        backgroundColor: 'white', borderColor: '#1c4ed8' }}
                >
                  Add Template
                </Button>
              </Box>
            {/* Basic Invoice Information */}
            <Typography variant="h6" gutterBottom>
                Basic Information
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                <TextField
                    name="invoiceIdNumber"
                    label="Invoice ID Number"
                    variant="outlined"
                    value={formData.invoiceIdNumber}
                    onChange={handleInputChange}
                    fullWidth
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    name="purchaseOrder"
                    label="Purchase Order"
                    variant="outlined"
                    value={formData.purchaseOrder}
                    onChange={handleInputChange}
                    fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    name="customerName"
                    label="Customer Name"
                    variant="outlined"
                    value={formData.customerName}
                    onChange={handleInputChange}
                    fullWidth
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    name="customerId"
                    label="Customer ID"
                    variant="outlined"
                    value={formData.customerId}
                    onChange={handleInputChange}
                    fullWidth
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                    label="Invoice Date"
                    value={formData.invoiceDate}
                    onChange={(value) => handleDateChange('invoiceDate', value)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        label="Due Date"
                        value={formData.dueDate}
                        onChange={(value) => handleDateChange('dueDate', value)}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Divider sx={{ my: 3 }} />

            {/* Financial Information */}
            <Typography variant="h6" gutterBottom>
                Bill Information
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                <TextField
                    name="subtotal"
                    label="Subtotal"
                    variant="outlined"
                    value={formData.subtotal}
                    onChange={handleInputChange}
                    fullWidth
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    name="vendorName"
                    label="Vendor Name"
                    variant="outlined"
                    value={formData.vendorName}
                    onChange={handleInputChange}
                    fullWidth
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    name="invoiceTotal"
                    label="Invoice Total"
                    variant="outlined"
                    value={formData.invoiceTotal}
                    onChange={handleInputChange}
                    fullWidth
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    name="totalTax"
                    label="Total Tax"
                    variant="outlined"
                    value={formData.totalTax}
                    onChange={handleInputChange}
                    fullWidth
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    name="previousUnpaidBalance"
                    label="Previous Unpaid Balance"
                    variant="outlined"
                    value={formData.previousUnpaidBalance}
                    onChange={handleInputChange}
                    fullWidth
                />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    name="amountDue"
                    label="Amount Due"
                    variant="outlined"
                    value={formData.amountDue}
                    onChange={handleInputChange}
                    fullWidth
                />
                </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />
            <ItemsSection
              items={formData.items}
              newItem={newItem}
              onItemChange={handleItemChange}
              onItemDateChange={handleItemDateChange}
              onAddItem={handleAddItem}
              onDeleteItem={handleDeleteItem}
              onEditItem={handleEditItem}
              onUpdateItem={handleUpdateItem}
              editingItem={editingItem}
            />
            <Divider sx={{ my: 3 }} />

            {/* Address Sections */}
            <AddressFields
              prefix="customerAddress"
              title="Customer Address"
              address={formData.customerAddress}
              onChange={handleInputChange}
            />

            {/* Billing Address Section */}
            <Box sx={{ mb: 2 }}>
                <FormControlLabel
                control={
                    <Checkbox
                    checked={sameAsCustomer}
                    onChange={handleSameAsCustomerChange}
                    name="sameAsCustomer"
                    />
                }
                label="Billing Address same as Customer Address"
                />
            </Box>

            {/* Conditional Billing Address */}
            <Box sx={{ mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                Billing Address
                </Typography>
                {sameAsCustomer ? (
                <ReadOnlyAddressFields address={formData.customerAddress} />
                ) : (
                <AddressFields
                    prefix="billingAddress"
                    title=""
                    address={formData.billingAddress}
                />
                )}
            </Box>

            {/* Optional Addresses */}
            <Box sx={{ mt: 3, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography variant="h6" gutterBottom>
                Additional Addresss
                </Typography>
                <OptionalAddressFields
                prefix="vendorAddress"
                title="Vendor Address"
                address={formData.vendorAddress}
                onChange={handleInputChange}
                />
                <OptionalAddressFields
                prefix="shippingAddress"
                title="Shipping Address"
                address={formData.shippingAddress}
                onChange={handleInputChange}
                />
                <OptionalAddressFields
                prefix="serviceAddress"
                title="Service Address"
                address={formData.serviceAddress}
                onChange={handleInputChange}
                />
                <OptionalAddressFields
                prefix="remittanceAddress"
                title="Remittance Address"
                address={formData.remittanceAddress}
                onChange={handleInputChange}
                />
            </Box>

            <Grid item xs={12}>
                <Box 
                    sx={{ 
                    display: "flex", 
                    justifyContent: "center", 
                    gap: 2, // spacing between buttons
                    mt: 5 
                    }}
                >
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{
                        pl: 4,
                        pr: 4,
                        color: "white",
                        backgroundColor: "#1c4ed8",
                        borderRadius: "50px",
                        "&:hover": { backgroundColor: "#163bb7" },
                    }}
                    >
                    Create Invoice
                    </Button>
                    <Button
                    variant="outlined"
                    color="error"
                    onClick={handleClose} // Assuming 'Cancel' should call handleClose
                    sx={{
                        pl: 4,
                        pr: 4,
                        borderRadius: "50px",
                    }}
                    >
                    Cancel
                    </Button>
                </Box>
                </Grid>
            </Box>
        </div>
        </div>
        <style jsx>{`
        .upload-backdrop {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            z-index: 999;
            }
        .loading-overlay {
            position: absolute;
            overflow: scroll;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 1100px;
            height: 90%;
            padding: 20px;
            background-color: rgba(255, 255, 255, 0.8); /* Optional: add a slight background color */
            align-items: center;
            justify-content: center;
            z-index: 1100; /* Ensure it sits above the upload frame */
            border-radius: 25px; /* Match the border radius of the upload frame */
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
            background-color: #ffffff;
            }
      `}</style>
    </>
  );
};