import React, { useState, useCallback } from 'react';
import { Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";
import upload from "./upload.svg"; // Import the upload SVG
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingScreen } from "../../components/LoadingScreen/LoadingScreen";
import useAxios from "../../utils/useAxios";

export const UploadFrame = ({ onClose }) => {
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const [numFiles, setNumFiles] = useState(0);
  const axios = useAxios();
  // const port = process.env.REACT_APP_PORT || '8080';

  let url;

  if (internalUrl) {
    url = `https://${internalUrl}`;
  } else {
    url = 'http://127.0.0.1:8000';
  }
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false); 

  const handleUpload = async () => {
    if (files.length === 0) return;
    console.log('Upload started');
    setLoading(true); // Set loading to true when upload starts

    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
      console.log(file);
    });

    try {
      const result = await axios.post(`${url}/api/invoices/`, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      console.log('Upload successful');
      onClose(result.data.processed_invoices);
      setFiles([]);
      setNumFiles(0);

    } catch (error) {
      console.error('Upload error:', error);
    } finally {
      setLoading(false); // Reset loading state when upload completes
    }
  };

  const handleDeleteFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    
    const droppedFiles = [...e.dataTransfer.files];
    setFiles(droppedFiles);
    setNumFiles(setFiles.length);
  }, []);

  const handleFileChange = (e) => {
    const selectedFiles = [...e.target.files];
    setFiles(selectedFiles);
    setNumFiles(setFiles.length);
  };

  const handleClose = () => {
    onClose([]);
  };

  return (
    <>
      {/* Backdrop to darken the background */}
      <div className="upload-backdrop" ></div>

      {/* Loading Screen */}
      {loading && (
        <div className="loading-overlay">
          <LoadingScreen numFiles={numFiles}/>
        </div>
      )}

      {/* Upload Frame */}
      <div className="upload-frame">
        <div className="content-wrapper">
          {/* Close Button */}
          <IconButton
            size="medium"
            sx={{
              position: "absolute",
              top: 18,
              right: 18,
              border: 1,
              borderColor: "#1c4ed8",
              color: "#1c4ed8",
              backgroundColor: "white",
              "&:hover": { backgroundColor: "#f0f4ff" },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          {/* Upload Section */}
          <div className="upload-content">
            <div className="upload-header">
              <h2 className="upload-title">Upload Files</h2>
              <p className="upload-subtitle">
                Click to upload or drag and drop files.
              </p>
              <p className="upload-description">
                SVG, PNG, JPG or GIF (MAX. 800x400px)
              </p>
            </div>

            {/* Upload Dropzone */}
            <label
              htmlFor="dropzone-file"
              className="upload-label"
              onDrop={handleDrop}
              style={{ height: '220px', width: '300px', border: '2px dashed #1c4ed8', 
                overflow: 'scroll',
                whiteSpace: 'wrap' }}
            >
              <img src={upload} alt="Upload icon" className="upload-icon" />
              <input 
                id="dropzone-file" 
                type="file" 
                className="hidden"
                onChange={handleFileChange} 
                multiple
              />
              {files.length > 0 && (
                <div className="mt-4 text-sm text-gray-600">
                  {files.map((file, index) => (
                    <div key={index} className="file-row">
                      <span className="truncate">{file.name}</span>
                      <IconButton
                        onClick={() => handleDeleteFile(index)}
                        size="small"
                        sx={{ color: "#ff1744", marginLeft: '10px' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
                </div>
              )}
            </label>

            {/* Upload Button */}
            <div className="button-container">
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "#1c4ed8",
                  padding: "10px 20px",
                  borderRadius: '50px',
                  "&:hover": { backgroundColor: "#163bb7" },
                }}
                onClick={handleUpload}
                variant="contained"
                disabled={files.length === 0 || loading} // Disable button if loading
              >
                Upload
              </Button>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .loading-overlay {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 400px;
          height: 500px;
          padding: 20px;
          background-color: rgba(255, 255, 255, 0.8); /* Optional: add a slight background color */
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1100; /* Ensure it sits above the upload frame */
          border-radius: 8px; /* Match the border radius of the upload frame */
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
          background-color: #ffffff;
        }
      `}</style>
    </>
  );
};





