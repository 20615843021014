import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { 
  Box, 
  Card, 
  CardContent, 
  Typography, 
  Alert, 
  AlertTitle,
  IconButton,
  LinearProgress, 
  Grid,
  Chip,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  TextField,
  MenuItem,
} from '@mui/material';
import { CreditCard, Activity, User, X, Link2, AlertCircle, Edit2, Check, XIcon } from 'lucide-react';
import { Navbar } from "../../components/Navbar/Navbar";
import SideBar from "../../components/SideBar/SideBar";
import useAxios from "../../utils/useAxios";
import { useTranslation } from 'react-i18next';
import XeroLogo from './xero-logo.png';
import QuickBooksLogo from './quickbooks-logo.png';
import SageLogo from './sage-logo.png';
import { styled } from '@mui/material/styles';

// --------------------------
// CUSTOM COMPONENT STYLING
// --------------------------

// Updated TabPanel component styling for smooth transition
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`membership-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, animation: 'fadeIn 0.5s' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

// Modern Styled Tab with smooth color transitions
const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '0.95rem',
  minHeight: 48,
  padding: '12px 24px',
  color: theme.palette.text.secondary,
  transition: 'color 0.3s',
  '&.Mui-selected': {
    color: '#1c4ed8',
    fontWeight: 600,
  },
  '&:hover': {
    color: '#1c4ed8',
    opacity: 1,
  },
}));

// Modern Tabs container with a custom indicator and no bottom border
const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: 'none',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1c4ed8',
    height: 3,
    borderRadius: '3px 3px 0 0',
  },
}));

// --------------------------
// OTHER COMPONENTS (ProfileCard, CompanyCard, etc.)
// --------------------------

const ProfileCard = ({ data, t, setStatus }) => {
  const navigate = useNavigate();

  const handleHelpRequest = () => {
    // navigate to contact page
    navigate('/contact');
  };

  return (
    <Card sx={{ borderRadius: 2, boxShadow: '0px 4px 20px rgba(0,0,0,0.05)' }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <User size={24} style={{ marginRight: 8 }} />
          <Typography variant="h6" color='#1c4ed8'>{t('Profile')}</Typography>
        </Box>
        {[
          { 
            label: t('Name'), 
            value: `${data?.subscription?.user?.first_name} ${data?.subscription?.user?.last_name}`.trim() || t('Not specified'),
          },
          { 
            label: t('Email'), 
            value: data?.subscription?.user?.email 
          },
          { 
            label: t('Invoice Email'), 
            value: data?.subscription?.customer?.invoice_email || t('Not specified') 
          }
        ].map(({ label, value }) => (
          <Box key={label} sx={{ mb: label !== 'Invoice Email' ? 2 : 0 }}>
            <Typography variant="subtitle2" color="text.secondary">{label}</Typography>
            <Typography>{value}</Typography>
          </Box>
        ))}

        <Box 
          sx={{
            mt: 3,
            p: 2,
            backgroundColor: 'action.hover',
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AlertCircle size={20} />
            <Typography variant="subtitle1">{t("Need a help?")}</Typography>
          </Box>
          <Button
            variant="text"
            size="large"
            onClick={handleHelpRequest}
            sx={{ textTransform: 'none' }}
          >
            {t("Contact us")}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

const CompanyCard = ({ data, t, setStatus, fetchMembershipData }) => {
  // URL configuration
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const url = useMemo(() => 
    internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000',
    [internalUrl]
  );
  // Copy the state and handlers from ProfileCard for company fields
  const [editMode, setEditMode] = useState({
    company: false,
    company_address: false,
    default_currency: false,
    default_vat_rate: false
  });
  const [formData, setFormData] = useState({
    company: data?.subscription?.customer?.company_name || '',
    company_address: data?.subscription?.customer?.company_address || '',
    default_currency: data?.subscription?.customer?.default_currency || '',
    default_vat_rate: data?.subscription?.customer?.default_vat_rate || ''
  });
  const axios = useAxios();

  const [fieldErrors, setFieldErrors] = useState({
    company: '',
    company_address: '',
    default_currency: '',
    default_vat_rate: ''
  });

  const handleEdit = (field) => {
    setEditMode(prev => ({ ...prev, [field]: true }));
  };

  const handleCancel = (field) => {
    setEditMode(prev => ({ ...prev, [field]: false }));
    setFormData(prev => ({
      ...prev,
      [field]: data?.subscription?.customer?.[field.toLowerCase()] || ''
    }));
  };

  const handleSave = async (field) => {
    // Clear previous error
    setFieldErrors(prev => ({ ...prev, [field]: '' }));

    // Validate field is not empty
    if (!formData[field].trim()) {
      setFieldErrors(prev => ({ ...prev, [field]: t('This field cannot be empty') }));
      return;
    }

    // Additional validation for VAT rate
    if (field === 'default_vat_rate') {
      const vatRate = parseFloat(formData[field]);
      if (isNaN(vatRate) || vatRate < 0 || vatRate > 100) {
        setFieldErrors(prev => ({ ...prev, [field]: t('VAT rate must be between 0 and 100') }));
        return;
      }
    }

    try {
      const endpoint = `${url}/api/subscription/update-profile/`;
      let payload = {};

      payload = {
        [field.toLowerCase()]: formData[field]
      };

      await axios.post(endpoint, payload);
      setEditMode(prev => ({ ...prev, [field]: false }));
      setStatus({
        severity: 'success',
        message: t('Profile updated successfully')
      });

      // Add this line to refresh the membership data
      await fetchMembershipData();

    } catch (error) {
      console.error(`Failed to update ${field}:`, error);
      setStatus({
        severity: 'error',
        message: t('Failed to update profile. Please try again or contact support.')
      });
    }
  };

  const handleChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const currencyOptions = [
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
    { value: 'GBP', label: 'GBP' },
    { value: 'PLN', label: 'PLN' },
    { value: 'TRY', label: 'TRY' },
  ];

  const getFieldContent = (field, value) => {
    if (editMode[field]) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {field === 'default_currency' ? (
              <TextField
                select
                size="small"
                value={formData[field]}
                onChange={(e) => handleChange(field, e.target.value)}
                error={!!fieldErrors[field]}
                sx={{
                  minWidth: '200px',
                  maxWidth: '400px',
                  width: 'auto',
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#1c4ed8',
                    },
                  },
                }}
              >
                {currencyOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <TextField
                size="small"
                value={formData[field]}
                onChange={(e) => handleChange(field, e.target.value)}
                error={!!fieldErrors[field]}
                sx={{
                  minWidth: '200px',
                  maxWidth: '400px',
                  width: 'auto',
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: '#1c4ed8',
                    },
                  },
                }}
              />
            )}
            <IconButton 
              size="small" 
              onClick={() => handleSave(field)}
              sx={{ 
                color: 'success.main',
                '&:hover': { backgroundColor: 'success.lighter' } 
              }}
            >
              <Check size={18} />
            </IconButton>
            <IconButton 
              size="small" 
              onClick={() => handleCancel(field)}
              sx={{ 
                color: 'error.main',
                '&:hover': { backgroundColor: 'error.lighter' } 
              }}
            >
              <XIcon size={18} />
            </IconButton>
          </Box>
          {fieldErrors[field] && (
            <Typography color="error" variant="caption">
              {fieldErrors[field]}
            </Typography>
          )}
        </Box>
      );
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography>{value}</Typography>
        <IconButton 
          size="small" 
          onClick={() => handleEdit(field)}
          sx={{ 
            padding: '4px',
            '&:hover': { 
              backgroundColor: 'rgba(28, 78, 216, 0.04)' 
            } 
          }}
        >
          <Edit2 size={14} color="#1c4ed8" />
        </IconButton>
      </Box>
    );
  };

  return (
    <Card sx={{ borderRadius: 2, boxShadow: '0px 4px 20px rgba(0,0,0,0.05)' }}>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Activity size={24} style={{ marginRight: 8 }} />
          <Typography variant="h6" color='#1c4ed8'>{t('Company Details')}</Typography>
        </Box>

        {/* Company Information Section */}
        {[
          { 
            label: t('Company'), 
            field: 'company',
            value: data?.subscription?.customer?.company_name || t('Not specified'),
            editable: true
          },
          { 
            label: t('Company Address'), 
            field: 'company_address',
            value: data?.subscription?.customer?.company_address || t('Not specified'),
            editable: true
          },
          { 
            label: t('Default Currency'), 
            field: 'default_currency',
            value: data?.subscription?.customer?.default_currency || t('Not specified'),
            editable: true
          },
          { 
            label: t('Default VAT Rate (%)'), 
            field: 'default_vat_rate',
            value: data?.subscription?.customer?.default_vat_rate || t('Not specified'),
            editable: true
          }
        ].map(({ label, field, value, editable }) => (
          <Box key={label} sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">{label}</Typography>
            {editable ? getFieldContent(field, value) : <Typography>{value}</Typography>}
          </Box>
        ))}

        {/* Usage Statistics Section */}
        <UsageCard data={data} t={t} />
      </CardContent>
    </Card>
  );
};

const UsageCard = ({ data, t }) => {
  const usageStats = data?.subscription?.usage_stats || {};
  const isUnlimited = usageStats.monthly_quota === "Unlimited";

  const getCurrencySymbol = (currency) => {
    switch (currency.toUpperCase()) {
      case "USD": return "$";
      case "EUR": return "€";
      case "GBP": return "£";
      default: return currency.toUpperCase();
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Activity size={24} style={{ marginRight: 8 }} />
          <Typography variant="h6" color='#1c4ed8'>{t('Usage')}</Typography>
        </Box>
        <Box sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="subtitle2" color='#1c4ed8'>{t('Documents Processed')}</Typography>
            <Typography variant="subtitle2">
              {isUnlimited ? 
                `${usageStats.documents_processed} / ∞` : 
                `${usageStats.documents_processed || 0} / ${usageStats.monthly_quota || 0}`
              }
            </Typography>
          </Box>
          {!isUnlimited && (
            <LinearProgress 
              variant="determinate" 
              value={usageStats.usage_percentage || 0}
              color={
                usageStats.usage_percentage > 90 ? 'error' : 
                usageStats.usage_percentage > 75 ? 'warning' : 
                'primary'
              }
            />
          )}
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">
            {t('Remaining this period')}
          </Typography>
          <Typography>
            {isUnlimited ? t('Unlimited') : t('{{count}} documents', { count: usageStats.remaining_invoices || 0 })}
          </Typography>
        </Box>
        {data?.billing_period && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">
              {t('Days left in billing period')}
            </Typography>
            <Typography>
              {t('{{count}} days', { count: data?.subscription?.billing_period.days_remaining })}
            </Typography>
          </Box>
        )}
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">
            {t('Upcoming Bill')}
          </Typography>
          <Typography>
            {data?.subscription?.price_details?.currency
              ? `${getCurrencySymbol(data.subscription.price_details.currency)}${usageStats.upcoming_bill ? (usageStats.upcoming_bill / 100).toFixed(2) : "0.00"} ${data.subscription.price_details.currency.toUpperCase()}`
              : "0.00"}
          </Typography>
        </Box>
    </Box>
  );
};

const ConnectionsCard = ({ data, axios, t }) => {
  // URL configuration
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const url = useMemo(() => 
    internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000',
    [internalUrl]
  );
  const [loading, setLoading] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [xeroIntegrationStatus, setXeroIntegrationStatus] = useState(null);
  const [quickbooksIntegrationStatus, setQuickbooksIntegrationStatus] = useState(null);
  const [sageIntegrationStatus, setSageIntegrationStatus] = useState(null);

  useEffect(() => {
    const now = new Date();

    // Helper function to check integration status
    const checkIntegrationStatus = (integration, expiryDays) => {
      if (integration?.refresh_token && integration?.expires_at) {
        const expiresAt = new Date(integration.expires_at);
        expiresAt.setDate(expiresAt.getDate() + expiryDays);
        return expiresAt > now ? 'connected' : 'disconnected';
      }
      return 'disconnected';
    };

    const subscription = data?.subscription;
    if (subscription) {
      setXeroIntegrationStatus(
        checkIntegrationStatus(subscription.xero_integration, 60)
      );
      setQuickbooksIntegrationStatus(
        checkIntegrationStatus(subscription.quickbooks_integration, 100)
      );
      setSageIntegrationStatus(
        checkIntegrationStatus(subscription.sage_integration, 31)
      );
    }
  }, [data?.subscription]);

  const integrations = [
    {
      name: 'Xero',
      icon: <img src={XeroLogo} alt="Xero" style={{ width: 60, height: 60 }} />,
      status: xeroIntegrationStatus || 'disconnected',
      authUrl: `${url}/api/integration/xero/auth/`
    },
    {
      name: 'QuickBooks',
      icon: <img src={QuickBooksLogo} alt="QuickBooks" style={{ width: 57, height: 57 }} />,
      status: quickbooksIntegrationStatus || 'disconnected',
      authUrl: `${url}/api/integration/quickbooks/auth/`
    },
    {
      name: 'Sage',
      icon: <img src={SageLogo} alt="Sage" style={{ width: 60, height: 60 }} />,
      status: sageIntegrationStatus || 'disconnected',
      authUrl: `${url}/api/integration/sage/auth/`
    }
  ];

  const handleConnect = async (authUrl, integrationName) => {
    setLoading(integrationName);
    try {
      const response = await axios.get(authUrl);
      if (response.data?.authorization_url) {
        localStorage.setItem('session_key', response.data.session_key);
        setIsRedirecting(true);
        window.location.href = response.data.authorization_url;
      }
    } catch (error) {
      console.error("Failed to initiate connection:", error);
      setLoading(null);
      setIsRedirecting(false);
    }
  };

  const handleCustomRequest = () => {
    window.location.href = 'mailto:support@yourdraft.ai?subject=Custom Integration Request';
  };

  return (
    <Card sx={{ width: '100%', borderRadius: 2, boxShadow: '0px 4px 20px rgba(0,0,0,0.05)' }}>
      <CardContent>        
        {integrations.map((integration) => (
          <Box 
            key={integration.name}
            sx={{
              mb: 2,
              p: 2,
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ fontSize: '24px' }}>{integration.icon}</Typography>
              <Box>
                <Typography variant="subtitle1">{integration.name}</Typography>
                <Chip
                  size="small"
                  label={t(integration.status === 'connected' ? 'connected' : 'disconnected')}
                  color={integration.status === 'connected' ? 'success' : 'default'}
                  sx={{ mt: 0.5 }}
                />
              </Box>
            </Box>
            <Button
              variant={integration.status === 'connected' ? 'outlined' : 'contained'}
              size="small"
              onClick={() => handleConnect(integration.authUrl, integration.name)}
              sx={{ 
                textTransform: 'none',
                backgroundColor: integration.status === 'connected' ? 'transparent' : '#1c4ed8',
                minWidth: '120px'
              }}
            >
              {loading === integration.name && (
                <CircularProgress size={20} color="inherit" sx={{ marginRight: 1 }} />
              )}
              {loading === integration.name && isRedirecting ? 'Redirecting...' : 
                integration.status === 'connected' ? t('Reconnect') : t('Connect')}
            </Button>
          </Box>
        ))}

        <Box 
          sx={{
            mt: 3,
            p: 2,
            backgroundColor: 'action.hover',
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AlertCircle size={20} />
            <Typography variant="subtitle1">{t("Need a custom integration?")}</Typography>
          </Box>
          <Button
            variant="text"
            size="large"
            onClick={handleCustomRequest}
            sx={{ textTransform: 'none' }}
          >
            {t("Request")}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

// --------------------------
// MAIN MEMBERSHIP VIEW
// --------------------------
export const MembershipView = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const redirect = new URLSearchParams(location.search).get("redirect", false);
  const [openSideBar, setOpenSideBar] = useState(true);
  const [status, setStatus] = useState(null);
  const [membershipData, setMembershipData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [inactive, setInactive] = useState(false);
  const navigate = useNavigate();
  const axios = useAxios();
  const checkoutProcessed = useRef(false);
  const [callback, setCallback] = useState(null);
  const [code, setCode] = useState(null);
  const [state, setState] = useState(null);
  const [realm_id, setRealmId] = useState(null);
  const sessionId = useMemo(() => 
    new URLSearchParams(location.search).get("session_id"),
    [location.search]
  );
  const tab = new URLSearchParams(location.search).get("tab");
  const requiresAuth = new URLSearchParams(location.search).get("requires_auth");
  
  const [tabValue, setTabValue] = useState(() => {
    if (tab === 'integration') return 3;
    if (tab === 'usage') return 2;
    if (tab === 'membership') return 1;
    return 0;
  });

  useEffect(() => {
    if (requiresAuth) {
      setTabValue(3);
      setStatus({
        severity: 'warning',
        message: `Please reconnect to ${requiresAuth}.`
      });
    }
  }, [requiresAuth]);

  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const url = useMemo(() => 
    internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000',
    [internalUrl]
  );

  useEffect(() => {
    setCallback(new URLSearchParams(location.search).get("callback"));
    setCode(new URLSearchParams(location.search).get("code"));
    setState(new URLSearchParams(location.search).get("state"));
    setRealmId(new URLSearchParams(location.search).get("realmId"));
  }, [location.search]);

  const handleAlertClose = () => {
    setStatus(null);
    navigate(location.pathname, { 
      replace: true,
      state: { checkoutComplete: true }
    });
  };

  const handleChange = async () => {
    setButtonLoading(true);
    try {
      if (inactive) {
        navigate('/pricing');
      } else {
        handleManage();
      }
    } catch (error) {
      console.error("Error in handleChange:", error);
    } 
    setButtonLoading(false);
  }; 

  const handleManage = async () => {
    const customer_stripe_id = membershipData?.subscription?.customer?.customer_stripe_id;  
    try {
      const locale = i18n.language === 'en' ? 'en' : 'pl';
      const response = await axios.post(`${url}/api/checkout/create_portal_session/${customer_stripe_id}/?locale=${locale}`);
      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error('Redirect URL from Stripe not provided');
      }
    } catch (error) {
      console.error("Failed to open customer portal:", error);
      setStatus({
        severity: 'error',
        message: error.response?.data?.message || 'Failed to open customer portal. Contact support team.'
      });
    }
  };

  const handleRedirect = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${url}/api/subscription/membership/`);
      const customer_stripe_id = response.data?.subscription?.customer?.customer_stripe_id;
      
      if (!customer_stripe_id) {
        throw new Error('No customer ID found');
      }
      
      await axios.post(`${url}/api/checkout/update_subscription/${customer_stripe_id}/`);
      await fetchMembershipData();
      navigate(location.pathname, { replace: true });
    } catch (error) {
      console.error("Failed to update subscription:", error);
      setStatus({
        severity: 'error',
        message: error.response?.data?.error || 'Failed to update subscription. Contact support team.'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleInitialLoad = async () => {
      if (redirect) {
        await handleRedirect();
      } else if ((callback === 'xero' || callback === 'quickbooks' || callback === 'sage') && code && state) {
        try {
          const response = await axios.post(`${url}/api/integration/${callback}/callback/`, {
            code: code,
            state: state,
            session_key: localStorage.getItem('session_key'),
            realm_id: realm_id,
          });
          if (response.status === 200) {
            setStatus({
              severity: 'success',
              message: `Successfully connected to ${callback.charAt(0).toUpperCase() + callback.slice(1)}!`
            });
            localStorage.removeItem('session_key');
            setCallback(null);
            setCode(null);
            setState(null);
            setRealmId(null);
            navigate('/membership', { replace: true });
          }
        } catch (error) {
          console.error("Failed to complete integration authentication:", error);
          setStatus({
            severity: 'error',
            message: error.response?.data?.error || `Failed to connect to ${callback.charAt(0).toUpperCase() + callback.slice(1)}. Please try again.`
          });
        }
      }
    };

    handleInitialLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect, callback]);

  const fetchMembershipData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${url}/api/subscription/membership/`);
      
      if (response.status === 206) {
        setInactive(true);
        setMembershipData(response.data);
      } else if (response.data?.subscription?.status !== 'active' && response.data?.subscription?.status !== 'trial'){
        setInactive(true);
        setMembershipData(response.data);
      } else {
        setMembershipData(response.data);
      }
      localStorage.setItem("defaultCurrency", response.data?.subscription?.customer?.default_currency);
    } catch (error) {
      console.error("Error fetching membership data:", error);
      setStatus({
        severity: 'error',
        message: t('Failed to load membership data. Please try again later.')
      });
    } finally {
      setLoading(false);
    }
  };

  const SubscriptionCard = ({ data, t }) => {
    const formatDate = (dateString) => {
      if (!dateString) return 'Not available';
      return new Date(dateString).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });
    };
  
    return (
      <Card sx={{ borderRadius: 2, boxShadow: '0px 4px 20px rgba(0,0,0,0.05)' }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <CreditCard size={24} style={{ marginRight: 8 }} />
            <Typography variant="h6" color='#1c4ed8'>{t('Subscription')}</Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">{t('Current Plan')}</Typography>
            <Typography>{data?.subscription?.plan_name || t('No active plan')}</Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">{t('Status')}</Typography>
            <Chip 
              label={t(data?.subscription?.status ? data?.subscription?.status : t("Inactive"))}
              color={data?.subscription?.status === "active" || data?.subscription?.status === "trial" ? 'success' : 'warning'}
              size="small"
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">{t('Billing Type')}</Typography>
            <Typography>
              { data?.subscription?.status === t("trial") ? t("Free") : data?.subscription?.price_details?.price_display || t('Not specified')}
            </Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">{t('Current Period')}</Typography>
            <Typography>
              {t(`${formatDate(data?.subscription?.billing_period?.current_period_start)} - ${formatDate(data?.subscription?.billing_period?.current_period_end)}`)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 35, justifyContent: 'left' }}>
              <Button onClick={handleChange} variant="contained" color="primary" sx={{ backgroundColor: '#1c4ed8', textTransform: 'none', boxShadow: 'none' }}>
                {buttonLoading ? <CircularProgress size={24} color="inherit" sx={{ marginRight: 1 }} /> : null}
                {inactive ? t("Subscribe Now") : t("Manage My Membership")}
              </Button>
            </Box>
        </CardContent>
      </Card>
    );
  };
  
  useEffect(() => {
    fetchMembershipData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    const finalizeCheckout = async () => {
      if (!sessionId || checkoutProcessed.current) return;
      checkoutProcessed.current = true;
      try {
        await axios.post(`${url}/api/checkout/checkout_finalize/?session_id=${sessionId}`);
        setStatus({ 
          severity: 'success',
          message: t('Subscription successfully activated!')
        });
        fetchMembershipData();
      } catch (error) {
        console.error("Error finalizing checkout:", error);
        setStatus({ 
          severity: 'error',
          message: t('Failed to finalize subscription. Please contact support.')
        });
      }
    };

    finalizeCheckout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    if (sessionId && location.state?.checkoutComplete) {
      navigate(location.pathname, { replace: true });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, location.state]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <LinearProgress sx={{ width: '50%' }} />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', width: '100%', 
      background: "radial-gradient(ellipse 90% 55% at 50% -20%, hsl(210, 100%, 90%), transparent)" 
    }}>
      <SideBar 
        open={openSideBar} 
        handleDrawerToggle={() => setOpenSideBar(!openSideBar)} 
        demo={false}
      />
      <Navbar sideBarOpen={openSideBar} />
      
      <Box component="main" sx={{
        flexGrow: 1,
        mt: 15,
        mb: 30,
        pl: openSideBar ? 10 : 0,
        pr: 20,
      }}>
        {status && (
          <Alert 
            severity={status.severity}
            sx={{ 
              mb: 3,
              borderRadius: 2,
              boxShadow: '0px 4px 20px rgba(0,0,0,0.05)' 
            }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleAlertClose}
              >
                <X size={16} />
              </IconButton>
            }
          >
            <AlertTitle>{status.severity === 'success' ? t('Success') : t('Alert')}</AlertTitle>
            {status.message}
          </Alert>
        )}

        <Card 
          sx={{ 
            width: '100%', 
            mb: 3,
            borderRadius: '16px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          }}
        >
          <Box sx={{ 
            borderBottom: 1, 
            borderColor: 'divider',
            bgcolor: 'rgba(245, 247, 250, 0.8)',
            borderRadius: '16px 16px 0 0',
          }}>
            <StyledTabs 
              value={tabValue} 
              onChange={handleTabChange}
              variant="fullWidth"
              sx={{ minHeight: 48 }}
            >
              <StyledTab 
                icon={<User size={18} />} 
                iconPosition="start" 
                label={t("Profile")} 
              />
              <StyledTab 
                icon={<CreditCard size={18} />} 
                iconPosition="start" 
                label={t("Membership")} 
              />
              <StyledTab 
                icon={<Activity size={18} />} 
                iconPosition="start" 
                label={t("Company")} 
              />
              <StyledTab 
                icon={<Link2 size={18} />} 
                iconPosition="start" 
                label={t("Integrations")} 
              />
            </StyledTabs>
          </Box>

          <Box sx={{ 
            p: 3,
            transition: 'all 0.3s ease',
          }}>
            <TabPanel value={tabValue} index={0}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ProfileCard data={membershipData} t={t} setStatus={setStatus} />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <SubscriptionCard data={membershipData} t={t} />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <CompanyCard 
                    data={membershipData} 
                    t={t} 
                    setStatus={setStatus} 
                    fetchMembershipData={fetchMembershipData}
                  />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ConnectionsCard data={membershipData} axios={axios} t={t}/>
                </Grid>
              </Grid>
            </TabPanel>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};