import { useLocation } from 'react-router-dom';
import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { Box, Button, IconButton, Typography, Grid, Container, List, ListItem, Tabs, Tab } from "@mui/material";
import { CircularProgress, ListItemIcon, ListItemText } from "@mui/material";
import {Download as DownloadIcon} from "@mui/icons-material";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { Navbar } from "../../components/Navbar/Navbar";
import { DocumentContent } from "../../components/DocumentContent/DocumentContent";
import { UploadFrame } from "../../components/UploadFrame/UploadFrame";
import "./style.css";
import { ItemList } from "../../components/ItemList/Itemlist";
import { QueryFrame } from "../../components/QueryFrame/QueryFrame";
import { DownloadFrame } from "../../components/DownloadFrame/DownloadFrame";
import { SaveFrame } from "../../components/SaveFrame/SaveFrame";
import { motion } from "framer-motion";
import SideBar from "../../components/SideBar/SideBar";
import Chatbot from "../../components/ChatbotFrame/Chatbot";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import useAxios from "../../utils/useAxios";
import { useTranslation } from 'react-i18next';


const transformToFields = (data) => {
  return Object.keys(data).map(key => {
    const value = data[key];

    // Check if the value is an object
    if (typeof value === 'object' && value !== null) {
      // Convert the object to a string representation or handle it as needed
      return {
        key: key,
        value: JSON.stringify(value) // You can customize this part based on your needs
      };
    }

    // Otherwise return the key-value pair as is
    return {
      key: key,
      value: value
    };
  });
};


function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}


export const InvoiceDetailView = () => {
  const demo = !localStorage.getItem("authTokens");
  const company = localStorage.getItem('company') || 'Demo Company';
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [invoiceIds, setInvoiceIds] = useState(queryParams.get('invoiceIds')?.split(',') || []);
  const [filePaths, setFilePaths] = useState(queryParams.get('filepaths')?.split(',') || []);
  const [addedByList, setaddedByList] = useState(queryParams.get('addedByList')?.split(',') || []);
  const [addedOnList, setaddedOnList] = useState(queryParams.get('addedOnList')?.split(',') || []);
  const [fileNameList, setFileNameList] = useState(queryParams.get('fileNameList')?.split(',') || []);

  const [currentInvoiceId, setCurrentInvoiceId] = useState(invoiceIds[0]);
  const [addedBy, setAddedBy] = useState(addedByList[0]);
  const [addedOn, setAddedOn] = useState(addedOnList[0]);
  const [fileName, setFileName] = useState(fileNameList[0]);

  const handleDownloadClick = useCallback(() => setShowDownloadFrame(true), []);
  // Create state for current invoiceId
  
  const fileInvoiceMapping = useMemo(() => filePaths.map((filePath, index) => ({
    filePath,
    invoiceId: invoiceIds[index],
    addedBy: addedByList[index],
    addedOn: addedOnList[index],
    fileName: fileNameList[index]

  // eslint-disable-next-line react-hooks/exhaustive-deps
  })), [filePaths, invoiceIds]);


  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedDocument, setSelectedDocument] = useState(filePaths[0]);
  const [showUploadFrame, setShowUploadFrame] = useState(false);
  const [showQueryFrame, setShowQueryFrame] = useState(false);
  const [fields, setFields] = useState([]);
  const [boundingRegions, setBoundingRegions] = useState([]);
  const [initFields, setInitFields] = useState();
  const [modifiedFields, setModifiedFields] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [showDownloadFrame, setShowDownloadFrame] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [showSaveFrame, setShowSaveFrame] = useState(false);
  const handleUploadClick = () => setShowUploadFrame(true);
  const handleQueryFrame = () => setShowQueryFrame(true);
  const axios = useAxios();
  const [scale, setScale] = useState(0.86);
  const [inactive, setInactive] = useState(false);

  // State for panning
  const [isPanning, setIsPanning] = useState(false);
  const [panPosition, setPanPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  // Ref for the document container
  const documentContainerRef = useRef(null);


  // State for active field highlight (when hovered or clicked)
  const [activeFieldHighlight, setActiveFieldHighlight] = useState(null);
  
  // State to hold the PDF container dimensions.
  // eslint-disable-next-line no-unused-vars
  const [pdfSize, setPdfSize] = useState({ width: 0, height: 0 });
  // NEW: State to hold the PDF page viewport (including its viewBox)
  const [pageViewport, setPageViewport] = useState(null);
  
  // Instead of a hardcoded DPI, we will derive an effective DPI.
  // When the PDF page is rendered, we use its viewBox to compute:
  //   effectiveDPI = (pageViewport.width * 72) / pageViewport.viewBox[2]
  // If pageViewport is not yet available, default to scale * 72.
  const effectiveDPI = pageViewport ? (pageViewport.width * 72) / pageViewport.viewBox[2] : (scale * 72);

  // Use the document container ref (already defined) to measure PDF size.
  useEffect(() => {
    if (documentContainerRef.current) {
      const { offsetWidth, offsetHeight } = documentContainerRef.current;
      setPdfSize({ width: offsetWidth, height: offsetHeight });
    }
  }, [scale, selectedDocument]); // Recalculate when zoom or document changes.

  // NEW: Callback when the PDF page loads.
  // We capture the page viewport (which includes its viewBox) so we can derive the DPI.
  const handlePageLoadSuccess = useCallback((page) => {
    const viewport = page.getViewport({ scale });
    setPageViewport(viewport);
  }, [scale]);

  // Updated defaultHighlights calculation using pageViewport dimensions
  const defaultHighlights = useMemo(() => {
    if (!boundingRegions || boundingRegions.length === 0 || !pageViewport) return [];
    
    return boundingRegions.map(region => {
      let position = { x: 0, y: 0, width: 0, height: 0 };
      if (region && Array.isArray(region.coordinates)) {
        const xs = region.coordinates.map(coord => (coord[0] * effectiveDPI) / pageViewport.width * 100);
        const ys = region.coordinates.map(coord => (coord[1] * effectiveDPI) / pageViewport.height * 100);
        const minX = Math.min(...xs);
        const minY = Math.min(...ys);
        const maxX = Math.max(...xs);
        const maxY = Math.max(...ys);
        position = {
          x: minX,
          y: minY,
          width: maxX - minX,
          height: maxY - minY,
        };
      }
      return { key: region.field, index: 1, position };
    });
  }, [boundingRegions, pageViewport, effectiveDPI]);

  const throttleDelay = 10; // milliseconds
  const lastHighlightTime = useRef(0);

  const handleFieldHighlight = useCallback((field) => {
    // Throttle to avoid too many state updates.
    const now = Date.now();
    if (now - lastHighlightTime.current < throttleDelay) return;
    lastHighlightTime.current = now;

    if (!field) {
      setActiveFieldHighlight(null);
      return;
    }

    const lookupKey = field.pdfKey ? field.pdfKey.toLowerCase() : field.key.toLowerCase();
    
    // Attempt to find the corresponding bounding region.
    const region = boundingRegions.find(r => r.field.toLowerCase() === lookupKey);

    if (!region || !Array.isArray(region.coordinates) || region.coordinates.length === 0 || !pageViewport) {
      setActiveFieldHighlight(null);
      return;
    }
    
    // Compute position values using the PDF viewport.
    const xs = region.coordinates.map(coord => (coord[0] * effectiveDPI) / pageViewport.width * 100);
    const ys = region.coordinates.map(coord => (coord[1] * effectiveDPI) / pageViewport.height * 100);
    const minX = Math.min(...xs);
    const minY = Math.min(...ys);
    const maxX = Math.max(...xs);
    const maxY = Math.max(...ys);
    const position = {
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
    };

    // Only update if the new value is different.
    setActiveFieldHighlight(prev => {
      if (prev && prev.key === lookupKey) return prev;
      return {
        key: lookupKey,
        label: t(field.key),
        index: field.index || 1,
        position,
      };
    });

  }, [boundingRegions, pageViewport, effectiveDPI, t]);

  useEffect(() => {
    if (scale < 0.87) {
      setPanPosition({ x: 0, y: 0 });
    }
  }, [scale]);

  // Panning functions
  const handleMouseDown = (e) => {
    // Only enable panning if zoomed in
    if (scale > 0.85) {
      setIsPanning(true);
      setStartPosition({ 
        x: e.clientX, 
        y: e.clientY 
      });
    }
  };
  
  const handleMouseMove = (e) => {
    if (isPanning && documentContainerRef.current) {
      // Calculate the movement delta
      const deltaX = e.clientX - startPosition.x;
      const deltaY = e.clientY - startPosition.y;
  
      // Update pan position
      setPanPosition(prev => ({
        x: prev.x + deltaX,
        y: prev.y + deltaY
      }));
  
      // Update start position for next move
      setStartPosition({
        x: e.clientX,
        y: e.clientY
      });
    }
  };
  
  const handleMouseUp = () => {
    setIsPanning(false);
  };

  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.05, 2)); // Max scale 3
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.05, 0.7)); // Min scale 0.5
  };

  const url = useMemo(() => {
    return internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';
  }, [internalUrl]);

  const handleCloseQueryFrame = async() => {
    await fetchItems();
    setShowQueryFrame(false);
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseDownloadFrame = useCallback(() => {
    setShowDownloadFrame(false);
  }, []);

  const fetchItems = useCallback(async () => {
    if (!currentInvoiceId) return;
    try {
      setLoading(true);
      if (demo) {
        const response = await fetch(`${url}/api/invoices/${currentInvoiceId}/items`);
        
        if (!response.ok) {
          throw new Error(`Failed to fetch. Status: ${response.status}`);
        }
    
        const data = await response.json();
        
        if (data.message === "Success") {
          setInitFields(data.data)
          const { bounding_regions, items_bounding_regions, ...dataWithoutBoundingRegions } = data.data;
          const transformedFields = transformToFields(dataWithoutBoundingRegions);
          setFields(transformedFields);

          // Fix: Properly handle bounding_regions object structure
          const boundingRegionsArray = bounding_regions ? 
            Object.entries(bounding_regions).map(([key, value]) => ({
              field: key,
              coordinates: value
            })) : [];

          // Fix: Properly handle items_bounding_regions object structure
          const itemsBoundingRegionsArray = items_bounding_regions ? 
            Object.entries(items_bounding_regions).flatMap(([itemKey, fields]) =>
              Object.entries(fields).map(([fieldKey, value]) => ({
                field: `${itemKey}.${fieldKey}`,
                coordinates: value
              }))
            ) : [];

          setBoundingRegions([...boundingRegionsArray, ...itemsBoundingRegionsArray]);
        } else {
          console.error("Unexpected response message:", data.message);
        }
      } else {
        const response = await axios.get(`${url}/api/invoices/${currentInvoiceId}/items`);
      if (response.data.message === "Success") {
        setInitFields(response.data.data)
        const { bounding_regions, items_bounding_regions, ...dataWithoutBoundingRegions } = response.data.data;
        const transformedFields = transformToFields(dataWithoutBoundingRegions);
        setFields(transformedFields);

        // Fix: Properly handle bounding_regions object structure
        const boundingRegionsArray = bounding_regions ? 
        Object.entries(bounding_regions).map(([key, value]) => ({
          field: key,
          coordinates: value
        })) : [];

        // Fix: Properly handle items_bounding_regions object structure
        const itemsBoundingRegionsArray = items_bounding_regions ? 
          Object.entries(items_bounding_regions).flatMap(([itemKey, fields]) =>
            Object.entries(fields).map(([fieldKey, value]) => ({
              field: `${itemKey}.${fieldKey}`,
              coordinates: value
            }))
          ) : [];

        setBoundingRegions([...boundingRegionsArray, ...itemsBoundingRegionsArray]);
      } else {
        console.error("Unexpected response message:", response.data.message);
      }
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
      setFields([]); // Reset fields on error
    } finally {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInvoiceId, demo, url]);
  
  
  // Triggered when user wants to navigate away (e.g., to dashboard)
  const handleGoDashboard = async () => {

    if (changesMade) {
      try {
        axios.post(`${url}/api/invoices/${currentInvoiceId}/`,
          { status: "IN_REVIEW" },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
      } catch (statusError) {
        console.error('Error updating status:', statusError);
      }
      setShowSaveFrame(true); // Show SaveFrame if there are changes
      setSaveAction('save'); // Indicate this is just a save action
    } else {
      navigate('/dashboard'); // If no changes, go directly to the dashboard
    }
  };

  const handleClose = () => {
    setShowSaveFrame(false);
  };
  
  // When SaveFrame is closed (without saving), discard changes and navigate
  const handleCloseSaveFrame = () => {
    setShowSaveFrame(false);
    navigate('/dashboard');
  };
  
  // When SaveFrame is confirmed and changes are saved, proceed to navigate
  const handleSaveAndApprove = useCallback(() => {
    setShowSaveFrame(false);
    // Remove the status update from here - only navigate
    navigate('/dashboard');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const handleApprove = () => {
    if (changesMade) {
      setShowSaveFrame(true); // If changes are made, show SaveFrame first
      // Pass a flag to indicate this is an approval action
      setSaveAction('approve');
    } else {
      updateStatus(currentInvoiceId) // If no changes, proceed with approval directly
        .then(() => {
          navigate('/dashboard');
        })
        .catch(error => {
          console.error('Navigation error:', error);
        });
    }
  };
  
  useEffect(() => {
    if (demo) {
      checkDemoModeLimit();
    }
    checkSubscriptionStatus();
    fetchItems();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchItems, currentInvoiceId, handleSaveAndApprove]);

  // Modify updateStatus to ensure it returns a response
  const updateStatus = async (invoiceId) => {
    const status = { "status": "APPROVED" };
    try {
      if (demo) {
        const response = await fetch(`${url}/api/invoices/${invoiceId}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(status)
        });
    
        if (!response.ok) {
          throw new Error('Failed to update status:', response.statusText);
        }
        return response;
      } else {
        const response = await axios.post(`${url}/api/invoices/${invoiceId}/`, 
          status,
          {
            headers: {
              'Content-Type': 'application/json'
            },
          }
        );
        return response;
      }
    } catch (error) {
      console.error('Update error:', error);
      throw error;
    }
  };

  const handleRerun = async () => {
    setLoading(true);
    try {
      if (demo) {
        const response = await fetch(`${url}/api/invoices/${currentInvoiceId}/`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ invoice_id: currentInvoiceId }),
        });
    
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message);
        }
        
        await fetchItems();
      } else {
        await axios.put(`${url}/api/invoices/${currentInvoiceId}/`, 
          { invoice_id: currentInvoiceId },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        try {
          await fetchItems();
        } catch (fetchError) {
          console.error("Error fetching items after rerun:", fetchError);
        }
      }
    } catch (error) {
      console.error("Error rerunning invoice:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDocumentChange = (newFilePath) => {
    if (selectedDocument === newFilePath) return;
    else {
    const mapping = fileInvoiceMapping.find(item => item.filePath === newFilePath);
    if (mapping) {
      setFields([]);
      setCurrentInvoiceId(mapping.invoiceId);
      setSelectedDocument(newFilePath);
      setPageNumber(1);
      setAddedBy(mapping.addedBy);
      setAddedOn(mapping.addedOn);
      setFileName(mapping.fileName);
    }
    }
  };

  const goToPreviousPage = () => {
    setPageNumber(prevPage => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber(prevPage => Math.min(prevPage + 1, numPages));
  };

  const [openSideBar, setOpenSideBar] = useState(true);
  const handleDrawerToggle = () => {
    setOpenSideBar(!openSideBar);
  };

  const handleCloseUploadFrame = async (invoices) => {
    if (invoices.length === 0) {
      setShowUploadFrame(false);
      return;
    }

    // Add new invoice IDs
    setInvoiceIds((prevIds) => [
      ...prevIds,
      ...invoices.map((invoice) => String(invoice.id)),
    ]);
    
  
    // Add new file paths
    setFilePaths((prevPaths) => [
      ...prevPaths,
      ...invoices.map((invoice) => `${url}${invoice.file}`),
    ]);

    setaddedByList((prevBys) => [
      ...prevBys,
      ...invoices.map((invoice) => invoice.uploaded_by || null),
    ]);    

    setaddedOnList((prevOns) => [
      ...prevOns,
      ...invoices.map((invoice) => String(invoice.uploaded_at)),
    ]);

    setFileNameList((prevNames) => [
      ...prevNames,
      ...invoices.map((invoice) => invoice.file_name),
    ]);
  
    // Only hide the upload frame after the invoice navigation is completed
    setShowUploadFrame(false);
  };

  const checkSubscriptionStatus = async () => {
    const response = await axios.get(`${url}/api/subscription/status/`);
    
    if (response.status === 202)
      return;

    if (response.data.status !== 'active' && response.data.status !== 'trial') {
      setInactive(true);
    }
  };

  const checkDemoModeLimit = () => {
    fetch(`${url}/api/throttle-status/`, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        if (data.throttled === true) {
          setInactive(true);
        }
      });
  };
  
  // Add new state for tracking save action type
  const [saveAction, setSaveAction] = useState(null);

  return (
    <Box width="100%" bgcolor="white" 
    sx={{
      backgroundImage:
        'radial-gradient(ellipse 80% 80% at 50% -20%, hsl(210, 100%, 90%), transparent)',
      '&.dark': {
        background: 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
      },
    }}>
      <>
        <SideBar open={openSideBar} handleDrawerToggle={handleDrawerToggle} demo={true} />
        <Navbar sideBarOpen={openSideBar} />
        {showUploadFrame && <UploadFrame onClose={handleCloseUploadFrame} />}
        {showSaveFrame && <SaveFrame 
                            onDiscard={handleCloseSaveFrame}
                            onSave={handleSaveAndApprove}
                            onClose={handleClose}
                            fields={initFields}
                            modifiedFields={modifiedFields}
                            url={url}
                            invoiceId={currentInvoiceId}
                            isApproveAction={saveAction === 'approve'} // Pass this new prop
                          />}
        {showQueryFrame && (
          <QueryFrame 
            onClose={handleCloseQueryFrame}
            url={url}
            invoiceId={currentInvoiceId}
          />
        )}

        <Container maxWidth="500px" sx={{mt: 12 }}>
          <Grid container display="flex" justifyContent="center">
            <Grid item xs={1} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <IconButton 
                color="primary" 
                onClick={handleGoDashboard}
                sx={{ color: '#1c4ed8', border: '1px solid #f5f5f5' }}
              >
                <ArrowBackIcon />
              </IconButton>
              <List sx={{
                overflow: 'auto',
                height: 'auto',
                maxHeight: '75vh',
                backgroundColor: 'white',
                borderRadius: '20px',
                color: '#1c4ed8',
                border: 0.1,
              }}>
                {filePaths.map((doc, index) => (
                  <ListItem 
                    button 
                    selected={selectedDocument === doc}
                    key={index} 
                    onClick={() => handleDocumentChange(doc)}
                    sx={{
                      backgroundColor: selectedDocument === doc && filePaths.length > 1 ? '#f5f5f5' : 'white',
                      color: '#1c4ed8',
                      borderRadius: '20px',
                      justifyContent: 'center', 
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ flexDirection: 'column', alignItems: 'center', display: 'flex', width: '10vh', cursor: 'pointer' }}>
                      <Box 
                        sx={{
                          width: '10vh', 
                          height: '13vh', 
                          display: 'flex', 
                          backgroundColor: 'white',
                          borderColor: '#1c4ed8',
                          border: 0.4,
                          alignItems: 'center', 
                        }}
                      >
                        <DocumentContent 
                          path={doc}
                          pageNumber={1}
                          scale={0.12}
                          onDocumentLoadSuccess={onDocumentLoadSuccess}
                          onPageLoadSuccess={handlePageLoadSuccess}
                          t={t}
                        />
                      </Box> 
                      <Typography sx={{
                        fontSize: '10px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        maxWidth: '90px',
                      }}>
                        {fileNameList[index]}
                      </Typography>
                    </Box>
                  </ListItem>
                ))}
              </List>
              <IconButton onClick={handleUploadClick} disabled={inactive} sx={{opacity: inactive ? 0.5 : 1}}>
                <AddIcon sx={{color: '#1c4ed8'}}/>
              </IconButton>
            </Grid>

            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <Box
                sx={{
                  width: '98%',
                  height: '90vh',
                  flexDirection: 'column', // Stack PDF and buttons vertically
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden', // Prevents overflow
                }}
              >
                {/* Header field */}
                <Box sx={{ display: 'flex', width: '95%', justifyContent: 'space-between', alignItems: 'center', 
                  mt: 1
                 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'left' }}>
                    <Button
                      variant="outlined" 
                      size="small" 
                      startIcon={<AddBoxOutlinedIcon sx={{color:'#1c4ed8'}} />}
                      disabled={inactive}
                      sx={{ marginLeft: 1, borderColor: '#1c4ed8', color: 'black', borderRadius: '50px',
                        backgroundColor: 'white',
                        '&:hover': {
                          backgroundColor: '#1c4ed8',
                          color: 'white',
                        },
                        opacity: inactive ? 0.5 : 1,
                      }}
                      onClick={handleQueryFrame}
                    >
                      {t("Add custom field")}
                    </Button>
                    <Button
                      variant="outlined" 
                      size="small" 
                      startIcon={<FastForwardOutlinedIcon sx={{color:'#1c4ed8'}} />}
                      disabled={inactive}
                      sx={{ borderColor: '#1c4ed8',marginLeft: 1, color: 'black', backgroundColor: 'white', borderRadius: '50px',
                        '&:hover': {
                          backgroundColor: '#1c4ed8',
                          color: 'white',
                        },
                        opacity: inactive ? 0.5 : 1,
                      }}
                      onClick={() => handleRerun()}
                    >
                      {t("Rerun")}
                    </Button>
                  </Box>
                  
                  <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                    {/* Zoom Controls */}
                    <Box display="flex" justifyContent="center" alignItems="center" mr={5}>
                      <IconButton onClick={handleZoomOut} color="primary">
                        <ZoomOutIcon sx={{color:'#1c4ed8'}}/>
                      </IconButton>
                      <span>{`${Math.round(scale * 100)}%`}</span>
                      <IconButton onClick={handleZoomIn} color="primary">
                        <ZoomInIcon sx={{color:'#1c4ed8'}} />
                      </IconButton>
                    </Box>
                    <Button variant="contained" startIcon={<DoneOutlinedIcon sx={{color:'white'}} />} size="small" 
                    sx={{borderRadius: '50px', color:"white", backgroundColor: '#1c4ed8'}} 
                    onClick={handleApprove}>
                        {t("Approve")}
                    </Button>
                    <Button
                      variant="outlined" 
                      onClick={handleDownloadClick}
                      size="small" 
                      sx={{ 
                        marginLeft: 1,
                        marginRight: 1,
                        borderColor: '#1c4ed8', 
                        color: 'black', 
                        backgroundColor: 'white',
                        width: '32px',
                        minWidth: '32px',
                        padding: '4px',
                        '& .MuiButton-startIcon': {  // Remove default icon spacing
                          margin: 0,
                          padding: 0,
                        },
                        '&:hover': {
                          backgroundColor: '#1c4ed8',
                          color: 'white',
                        },
                      }}
                    >
                      <DownloadIcon sx={{ fontSize: 20, color: '#1c4ed8', '&:hover': {
                          color: 'white',
                        },}} />
                    </Button>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', width: '95%', justifyContent: 'center', alignItems: 'center', mb: -0.5
                 }}>
                  <IconButton
                    variant="contained"
                    onClick={goToPreviousPage}
                    disabled={pageNumber <= 1}
                    sx={{ borderColor: '#1c4ed8', color: '#1c4ed8',
                      '&:hover': {
                        backgroundColor: '#1c4ed8',
                        color: 'white',
                      },
                    }}
                  >
                    <ChevronLeftIcon/>
                  </IconButton>
                  <Typography variant="body1" sx={{ justifyContent: 'center', minWidth: 43 }}>
                    {t('pageInfo', { pageNumber, numPages })}
                  </Typography>
                  <IconButton
                    variant="contained"
                    onClick={goToNextPage}
                    disabled={pageNumber >= numPages}
                    sx={{ borderColor: '#1c4ed8', color: '#1c4ed8',
                      '&:hover': {
                        backgroundColor: '#1c4ed8',
                        color: 'white',
                      },
                    }}
                  >
                    <ChevronRightIcon />
                  </IconButton>

                </Box>
                {/* Document Viewer Box */}
                <Box 
                  ref={documentContainerRef}
                  sx={{ 
                    width: '93%', 
                    height: '77vh', 
                    display: 'flex', 
                    backgroundColor: '#a7aebd', 
                    borderRadius: '25px', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    overflow: scale > 0.86 ? 'auto' : 'hidden', // Change from 'auto' to 'hidden'
                    cursor: scale > 0.85 ? (isPanning ? 'grabbing' : 'grab') : 'default'
                  }}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                >
                  <div
                    style={{
                      transform: `scale(${scale}) translate(${panPosition.x}px, ${panPosition.y}px)`,
                      transformOrigin: 'center',
                      transition: 'transform 0.1s ease',
                      width: 'auto',
                      height: 'auto'
                    }}
                  >
                    <DocumentContent 
                      path={selectedDocument}
                      pageNumber={pageNumber}
                      scale={scale}
                      onDocumentLoadSuccess={onDocumentLoadSuccess}
                      onPageLoadSuccess={handlePageLoadSuccess}
                      defaultHighlights={defaultHighlights}
                      activeHighlight={activeFieldHighlight}
                      t={t}
                    />
                  </div>
                </Box>
              </Box>
            </Grid>
    
            <Grid item xs={openSideBar ? 4 : 3} sx={{ display: 'flex', flexDirection: 'column' }}>
              {/* Tabs */}
              <Box sx={{ borderColor: 'divider'}} width='100%'>
                <Box sx={{ mb: 0.5}}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <Typography variant="h5" sx={{color:'black', mt: 1}}>
                        {fileName || t('No Invoice Name')}
                      </Typography>
                    </Box>
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center',
                        gap: 12, // Adds consistent spacing between items
                      }}
                    >
                      <Typography variant="subtitle2" sx={{ color: 'black' }}>
                        {t("Added by")}: 
                        <span style={{ color: '#1c4ed8', display: 'block' }}>
                          {addedBy || 'Demo user'}
                        </span>
                      </Typography>
                      
                      <Typography variant="subtitle2" sx={{ color: 'black' }}>
                        {t("Company")}: 
                        <span style={{ color: '#1c4ed8', display: 'block' }}>
                          {company || 'N/A'}
                        </span>
                      </Typography>
                      
                      <Typography variant="subtitle2" sx={{ color: 'black' }}>
                        {t("Added on")}: 
                        <span style={{ color: '#1c4ed8', display: 'block' }}>
                          {new Date(addedOn).toLocaleDateString()}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                <Tabs 
                  value={tabValue} 
                  onChange={handleTabChange} 
                  aria-label="invoice tabs" 
                  variant="fullWidth"
                  sx={{ 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    backgroundColor: '#f7f7f7', 
                    borderRadius: '20px',
                    height: '5%',
                    '& .MuiTab-root.Mui-selected': {
                      backgroundColor: '#ffffff', // White background for the selected tab
                    }
                  }}
                >
                  <Tab 
                    label={t("Details")} 
                    icon={<ArticleOutlinedIcon />} 
                    iconPosition='start' 
                    sx={{ borderRadius: '50px' }} 
                  />
                  <Tab 
                    label={t("Ask Me Anything")} 
                    icon={<ChatBubbleOutlineOutlinedIcon />} 
                    iconPosition='start' 
                    sx={{ borderRadius: '50px' }} 
                  />
                </Tabs>
              </Box>

              {/* Fields Tab Content */}
              <TabPanel value={tabValue} index={0}>
                <Box 
                  sx={{ 
                    overflowY: 'auto',
                    backgroundColor: 'white',
                    maxHeight: '565px',
                    width: '100%',
           
                    mt: -2,
                    borderRadius: '20px',
                  }}
                >
                  {loading ? (
                    <motion.div
                      initial={{ opacity: 1, scale: 1 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.9 }}
                      transition={{ duration: 0.5 }}
                      style={{
                        position: "fixed",
                        top: "30%",
                        left: "62%",
                        width: "450px",
                        height: "630px",
                        backgroundColor: "#fff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000,
                      }}
                    >
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <List style={{ marginTop: "16px" }}>
                          <ListItem>
                            <ListItemIcon>
                              <CircularProgress size={20} style={{ color: "#1c4ed8" }} />
                            </ListItemIcon>
                            <ListItemText 
                              primary={t("Loading items..")} 
                              style={{ fontWeight: "bold", color: "#1c4ed8", marginTop: "16px" }}
                            />
                          </ListItem>
                        </List>
                      </Box>
                    </motion.div>
                  ) : (
                    <ItemList 
                      fields={fields} 
                      setChangesMade={setChangesMade}
                      setModifiedFields={setModifiedFields}
                      onFieldHighlight={handleFieldHighlight}
                    />
                  )}
                </Box>
              </TabPanel>

              {/* AI Assistant Tab Content */}
              <TabPanel value={tabValue} index={1}>
                <Box 
                  sx={{ 
                    overflowY: 'auto',
                    backgroundColor: 'white',
                    maxHeight: '600px',
                    width: '100%',
                    mt: -2,
                    borderRadius: '20px',
                    opacity: inactive ? 0.5 : 1,
                  }}
                >
                  <Chatbot invoiceId={currentInvoiceId} disabled={inactive}/>
                </Box>
              </TabPanel>
            </Grid>
          </Grid>
        </Container>
        {showDownloadFrame && <DownloadFrame 
                                  onClose={handleCloseDownloadFrame} 
                                  checkedInvoiceIds={[currentInvoiceId]}
                                  url={url} />}
      </>
    </Box>
  );
};



