import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

export const DocumentContent = ({
  path,
  onDocumentLoadSuccess,
  onPageLoadSuccess,
  pageNumber,
  scale,
  defaultHighlights = [],
  activeHighlight = null,
  t = () => {}
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <Document file={path} onLoadSuccess={onDocumentLoadSuccess}>
        <Page 
          pageNumber={pageNumber}
          onLoadSuccess={onPageLoadSuccess}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          scale={scale}
        />
      </Document>
      
      {/* Render default slight highlights for all fields */}
      {/* {defaultHighlights.map((highlight) => (
        <div
          key={`${highlight.key}-${highlight.index}`}
          style={{
            position: 'absolute',
            left: `${highlight.position.x}%`,
            top: `${highlight.position.y}%`,
            width: `${highlight.position.width}%`,
            height: `${highlight.position.height}%`,
            border: '1.5px dashed rgba(255, 0, 0, 0.5)',  // slight red highlight style
            borderRadius: '4px',
            pointerEvents: 'none',
          }}
        />
      ))} */}
      
      {/* Render active (more prominent) highlight on top if available */}
      {activeHighlight && (
        <div
          key={`${activeHighlight.key}-${activeHighlight.index}-active`}
          style={{
            position: 'absolute',
            left: `${activeHighlight.position.x}%`,
            top: `${activeHighlight.position.y}%`,
            width: `${activeHighlight.position.width}%`,
            height: `${activeHighlight.position.height}%`,
            border: `2px solid #2563eb`,  // stronger highlight style
            borderRadius: '4px',
            pointerEvents: 'none',
            zIndex: 2,
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: '100%',
              left: '0',
              marginBottom: '4px',
              backgroundColor: '#2563eb',
              color: 'white',
              padding: '2px 8px',
              borderRadius: '4px',
              fontSize: '15px',
              whiteSpace: 'nowrap',
            }}
          >
            {activeHighlight.label ? `${t(activeHighlight.label)}` : `${t(activeHighlight.key)}`}
          </div>
        </div>
      )}
    </div>
  );
};
