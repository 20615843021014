import React, { useState } from "react";
import {
  Box,
  Stack
} from "@mui/material";

import { Navbar } from "../../components/Navbar/Navbar";
import SideBar from "../../components/SideBar/SideBar";
import Analytics from "./Analytics";


export const AnalyticsViewAuth = () => {
  const [openSideBar, setOpenSideBar] = useState(true);
  const handleDrawerToggle = () => {
    setOpenSideBar(!openSideBar);
  };

  return (
    <Box width="100%" bgcolor="white" >
      <SideBar open={openSideBar} handleDrawerToggle={handleDrawerToggle} demo={false} />
      <Navbar sideBarOpen={openSideBar} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            ml: 50,
            mx: 3,
            pb: 5,
            mt: { xs: 8, md: 15 },
          }}
        >
          <Box sx={{ width: openSideBar ? '90%' : '70%', maxWidth: { sm: '100%', md: '1700px'} }}>
            <Analytics />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};