import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded';
import ContactPageRoundedIcon from '@mui/icons-material/ContactPageRounded';


export default function MenuContent({ demo }) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const mainListItems = [
    { text: 'Home', icon: <HomeRoundedIcon />, link: '/' },
    { text: 'My Dashboard', icon: <DashboardCustomizeRoundedIcon />, link: demo ? '/dashboard-demo' : '/dashboard' },
    { text: 'Analytics', icon: <AnalyticsRoundedIcon />, link: demo ? '/analytics-demo' : '/analytics' },
    { text: 'My Membership', icon: <ManageAccountsRoundedIcon />, link: '/membership' },
    { text: 'Contact Us', icon: <ContactPageRoundedIcon />, link: '/contact' },
  ];
  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between', mt: 13 }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <Link to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemButton 
                selected={selectedIndex === index} 
                onClick={() => handleListItemClick(index)}
              >
                <ListItemIcon sx={{ justifyContent: 'left', color: '#1c4ed8' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
