import React, { useEffect, useMemo } from "react";
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import InvoiceStatusChart from './InvoiceStatusChart';
import InvoiceTimelineChart from './InvoiceTimelineChart';
import InvoiceMetricsCards from './InvoiceMetricsCards';
import InvoiceItemAnalysis from './InvoiceItemAnalysis';
import InvoiceCategoryAnalysis from './InvoiceCategoryAnalysis';
import InvoiceVendorAnalysis from './InvoiceVendorAnalysis';
import InvoiceCustomerAnalysis from './InvoiceCustomerAnalysis';
import useAxios from "../../utils/useAxios";


export default function InvoiceAnalyticsDashboard({ currency }) {
  const internalUrl = process.env.REACT_APP_API_BASE_URL;
  const demo = !localStorage.getItem("authTokens");
  const [analyticsData, setAnalyticsData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const axios = useAxios();

  const dummyData = {
    id: 4,
    total_amount: "670927.32",
    total_tax: "6067.00",
    total_invoices: 150,
    status_distribution: {
        approved: 65,
        in_review: 45,
        pending_review: 40
    },
    category_distribution: [
        { count: 50, category: "Technology" },
        { count: 40, category: "Professional Services" },
        { count: 30, category: "Office Supplies" },
        { count: 20, category: "Marketing" },
        { count: 10, category: "Travel" }
    ],
    weekly_trends: Array.from({ length: 10 }, (_, i) => {
        const date = new Date();
        date.setDate(date.getDate() - (i * 7)); // Go back i weeks from today
        return {
            week: date.toISOString().slice(0, 10), // Format as YYYY-MM-DD
            count: Math.floor(Math.random() * 40 + 20)
        };
    }).reverse(), // Reverse to show oldest to newest
    daily_trends: Array.from({ length: 31 }, (_, i) => ({
        day: `2024-12-${(i + 1).toString().padStart(2, '0')}`,
        count: Math.floor(Math.random() * 10 + 1),
        amount: Math.floor(Math.random() * 10000 + 1000)
    })),
    top_vendors: [
        ["CONTOSO", 25],
        ["MICROSOFT", 20],
        ["ADVENTURE WORKS", 15],
        ["CONTOSO LTD.", 12],
        ["APPLE INC", 10]
    ],
    top_customers: [
        ["MICROSOFT", 30],
        ["SOUTHRIDGE VIDEO", 25],
        ["CONTOSO", 20],
        ["MICROSOFT CORPORATION", 15],
        ["WINGTIP TOYS", 10]
    ],
    top_addresses: [
        { city: "NEW YORK", count: 35 },
        { city: "REDMOND", count: 28 },
        { city: "CUPERTINO", count: 22 },
        { city: "ATLANTA", count: 18 },
        { city: "CHICAGO", count: 15 }
    ],
    top_items_by_quantity: [
        ["Office Supplies", 150],
        ["Software Licenses", 120],
        ["Consulting Services", 100],
        ["Hardware Equipment", 80],
        ["Marketing Materials", 50]
    ],
    top_items_by_amount: [
        ["Software Licenses", 250000.00],
        ["Consulting Services", 180000.00],
        ["Hardware Equipment", 120000.00],
        ["Marketing Campaign", 80000.00],
        ["Office Supplies", 40927.32]
    ]
  };

  // Memoized URL
  const url = useMemo(() => {
    return internalUrl ? `https://${internalUrl}` : 'http://127.0.0.1:8000';
  }, [internalUrl]);

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        if (demo) {
            // use dummy data
            setAnalyticsData(dummyData);
        } else {
            const response = await axios.get(`${url}/api/invoices/analytics`);
            setAnalyticsData(response.data.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAnalyticsData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demo, url]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  if (!analyticsData) {
    return null;
  }

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1500px' } }}>
      {/* Overview Section */}
      <Typography component="h2" variant="h5" sx={{ mb: 2 }}>Overview</Typography>
      <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
        {/* Metrics Cards */}
        <Grid item xs={12} sm={6} lg={3}>
          <InvoiceMetricsCards data={analyticsData} currency={currency} />
        </Grid>
        {/* Vendor and Customer Analysis */}
        <Grid container spacing={2} xs={12} sm={6} lg={3}>
          <Grid container spacing={2}>
            <InvoiceVendorAnalysis data={analyticsData.top_vendors} />
            <InvoiceCustomerAnalysis data={analyticsData.top_customers} />
          </Grid>
        </Grid>
      </Grid>

      {/* Chart Section */}
      <Grid container spacing={2} xs={12} sm={6} lg={3}>
        <Grid container spacing={2}>
          <InvoiceStatusChart data={analyticsData.status_distribution} />
          <InvoiceTimelineChart data={analyticsData.weekly_trends} />
        </Grid>
      </Grid>

      
      <Grid container spacing={2} xs={12} sm={6} lg={3} mt={2}>
        <Grid container spacing={2}>
            <InvoiceCategoryAnalysis data={analyticsData.category_distribution} />
        </Grid>
        <InvoiceItemAnalysis 
            title="Top Cities"
            currency={''}
            color="#8884d8"
            data={analyticsData.top_addresses.map(item => ({
                name: item.city || '',
                value: item.count
            }))}
            />
      </Grid>

      {/* Item Analysis */}
      <Grid container spacing={2} xs={12} sm={6} lg={3} mt={2}>
        <Grid container spacing={2}>
            <InvoiceItemAnalysis 
                title="Top Items by Quantity"
                color='#333333'
                currency={''}
                data={analyticsData.top_items_by_quantity.map(([name, amount]) => ({
                    name: name || 'Unnamed Item',
                    value: amount
                }))}
                />
            <InvoiceItemAnalysis 
            title="Top Items by Amount"
            color='#333333'
            data={analyticsData.top_items_by_amount.map(([name, amount]) => ({
                name: name || 'Unnamed Item',
                value: amount
            }))}
            currency={currency}
            />
        </Grid>
      </Grid>
    </Box>
  );
}