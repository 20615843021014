import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { motion } from "framer-motion";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const LoadingScreen = ({numFiles}) => {
    const [currentStep, setCurrentStep] = useState(0);

    // Adjust time per step based on number of files
    const getIntervalTime = useCallback(() => {
        const baseTime = 700; // base time for 1 file (700ms)
        return baseTime + (numFiles * numFiles  * 300); // Increase time exp per additional file
    }, [numFiles]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentStep(prev => (prev < 2 ? prev + 1 : 2)); // 2 represents the last step
        }, getIntervalTime()); // Use dynamic interval time based on number of files

        return () => clearInterval(interval);
    }, [numFiles, getIntervalTime]);

    const steps = [
        { label: "Uploading your file", completed: currentStep > 0 },
        { label: "Processing your file", completed: currentStep > 1 },
        { label: "Finalizing the process", completed: currentStep > 2 }
    ];

    return (
        <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.5 }}
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
            }}
        >
            <Box display="flex" flexDirection="column" alignItems="center">
                {/* Loading Steps */}
                <List style={{ marginTop: "16px" }}>
                    {steps.map((step, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                {step.completed ? (
                                    <CheckCircleIcon style={{ color: "green" }} />
                                ) : (
                                    <CircularProgress size={20} style={{ color: "#1c4ed8" }} />
                                )}
                            </ListItemIcon>
                            <ListItemText primary={step.label} style={{ fontWeight: "bold", color: "#1c4ed8", marginTop: "16px" }}/>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </motion.div>
    );
};


