import React, { useState } from 'react';
import { Box, Container, Typography, TextField, Button } from '@mui/material';
import { Navbar } from '../../components/Navbar/Navbar';
import { useTranslation } from 'react-i18next';
import SideBar from "../../components/SideBar/SideBar";
import { Divider } from '@mui/material';
import { alpha } from '@mui/material/styles';
import "./style.css";

export const ContactUs = () => {
    const { t } = useTranslation();
    const [openSideBar, setOpenSideBar] = useState(true);
    const [formData, setFormData] = React.useState({
        email: '',
        subject: '',
        message: ''
    });
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        
        try {
            const response = await fetch('https://api.yourdraft.ai/support', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...formData,
                    to: 'support@yourdraft.ai'
                })
            });
            
            if (response.ok) {
                alert(t('Message sent successfully!'));
                setFormData({ email: '', subject: '', message: '' });
            } else {
                throw new Error('Failed to send message');
            }
        } catch (error) {
            alert(t('Failed to send message. Please try again later.'));
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDrawerToggle = () => {
        setOpenSideBar(!openSideBar);
    };

    return (
        <Box 
            width="100%" 
            sx={{
                backgroundImage: "url('https://flowbite.s3.amazonaws.com/blocks/e-commerce/girl-shopping-list.svg')", 
                backgroundSize: 'small', 
                backgroundPosition: 'right',
                backgroundRepeat: 'no-repeat',
                bgcolor: "rgba(255, 255, 255, 0.85)", // Slight white overlay for readability
                backdropFilter: "blur(25px)", // Optional blur effect
                zIndex: -1,
            }}
        >  
            <SideBar open={openSideBar} handleDrawerToggle={handleDrawerToggle} demo={true} />
            <Navbar sideBarOpen={openSideBar} />
            <Container maxWidth="md" sx={{ 
                pt: 5,
                ml: openSideBar ? 33.5 : 'auto',
                transition: 'margin-left 0.2s',
                mb: 6
            }}>
                <Box sx={{ textAlign: 'center', mb: 5, mt: 10 }} >
                    <Typography variant="h3" gutterBottom>
                        {t('Contact Us!')}
                    </Typography>
                    <Typography variant="h5" color="textSecondary" sx={{ mb: 2 }}>
                        {t('Please contact us and one of the founders will reach out with demo session!')}
                    </Typography>
                    <Typography variant="h6" color="textSecondary" sx={{ mb: 4 }}>
                        {t('Let us know about any feedback.')}
                    </Typography>
                </Box>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ mb: 4 }} bgcolor="white">
                        <TextField
                            fullWidth
                            label={t('Your email')}
                            variant="outlined"
                            type="email"
                            required
                            size='small'
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box sx={{ mb: 4 }} bgcolor="white">
                        <TextField
                            fullWidth
                            label={t('Subject')}
                            variant="outlined"
                            size='small'
                            required
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box sx={{ mb: 4 }} bgcolor="white">
                        <TextField
                            fullWidth
                            label={t('Your message')}
                            variant="outlined"
                            multiline
                            rows={6}
                            required
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                        />
                    </Box>
                    <Box textAlign="center">
                        <Button
                            variant="contained"
                            className="button"
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                            sx={{ mr: 2, borderRadius: '50px' }}
                        >
                            {isSubmitting ? t('Sending...') : t('Send Message')}
                        </Button>
                    </Box>
                </form>
            </Container>
            <Divider sx={{mx:18, borderColor: alpha("#1c4ed8", 0.5), mb: -2}}/>
        </Box>
    );
};


